import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { Icon } from "@opendash/icons";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { ColumnProps } from "antd/lib/table";

export const ProjectReportingComponent = createComponent(
  function ProjectReportingComponent() {
    return (
      <AdminLayout>
        <ParseSetView
          className="OD3_ProjectReport"
          view={
            {
              type: "table",
              customCols: [
                {
                  title: "Von",
                  key: "Von",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>{dayjs(row?.get("Von")).format("DD.MM.YYYY")}</div>
                    );
                  },
                } as ColumnProps<any>,
                {
                  title: "Bis",
                  key: "Bis",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>{dayjs(row?.get("Bis")).format("DD.MM.YYYY")}</div>
                    );
                  },
                } as ColumnProps<any>,

                {
                  title: "Beschreibung",
                  key: "Beschreibung",
                  dataIndex: 0,
                  render: (v, row) => {
                    return <div>{row?.get("Beschreibung")}</div>;
                  },
                } as ColumnProps<any>,

                {
                  title: "Anhang",
                  key: "Anhang",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>
                        <Icon
                          style={{ fontSize: "20px" }}
                          icon="fa:file-pdf"
                          onClick={() => {
                            window.open(row.get("Anhang")._url, "_blank");
                          }}
                        ></Icon>
                      </div>
                    );
                  },
                } as ColumnProps<any>,
              ],
              fields: ["Projekt", "Mitarbeiter"],
            } as TableViewType
          }
        />
      </AdminLayout>
    );
  }
);
