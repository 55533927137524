import React from "react";
import {
  Modal,
  Button,
  Select,
  InputNumber,
  Form,
  message,
  Radio,
  Space,
  Row,
  Col,
  Input,
  Divider,
  Upload,
} from "antd";
import { Icon } from "@opendash/icons";

const { Option } = Select;
const { Dragger } = Upload;

interface LicenseModalProps {
  modal: boolean;
  setModalVisibility: any;
  customerData: any;
  productData: any;
  addLicense: any;
}

export const LicenseModal: React.FC<LicenseModalProps> = ({
  modal,
  setModalVisibility,
  customerData,
  productData,
  addLicense,
}) => {
  const [form] = Form.useForm();
  const [selectedProducts, setSelectedProducts] = React.useState<string[]>([]);
  const [uploads, setUploads] = React.useState<any[]>([]);

  const handleAddUpload = () => {
    setUploads([
      ...uploads,
      { key: Date.now(), file: null, label: "", description: "" },
    ]);
  };

  const handleRemoveUpload = (key: number) => {
    setUploads(uploads.filter((upload) => upload.key !== key));
  };

  const handleProductChange = (value: string[]) => {
    setSelectedProducts(value);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        addLicense(values);
        setModalVisibility(false);
        form.resetFields();
        setSelectedProducts([]);
        setUploads([]);
      })
      .catch((error) => {
        message.error("Bitte füllen Sie alle erforderlichen Felder aus.");
      });
  };

  return (
    <Modal
      title="Neue Lizenz / Vertrag anlegen"
      open={modal}
      onOk={handleOk}
      okText="Anlagen"
      onCancel={() => {
        form.resetFields();
        setModalVisibility(false);
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Kunde"
          name="customerId"
          rules={[
            { required: true, message: "Bitte wählen Sie einen Kunden aus." },
          ]}
        >
          <Select
            placeholder="Kunden auswählen"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              //@ts-ignore
              (option?.children as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {customerData.map((customer: any) => (
              <Option key={customer.id} value={customer.id}>
                {customer.get("Name")}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Produkte"
          name="products"
          rules={[
            {
              required: true,
              message: "Bitte wählen Sie mindestens ein Produkt aus.",
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Produkte auswählen"
            onChange={handleProductChange}
          >
            {productData.map((product: any) => (
              <Option key={product.id} value={product.id}>
                {product.get("Label")}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {selectedProducts.map((productId) => {
          const product = productData.find((p: any) => p.id === productId);

          return (
            <div
              key={productId}
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                padding: "5px",
                marginBottom: "12px",
              }}
            >
              <strong>{product?.get("Label")}</strong>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Preis (€)"
                    name={["productsData", productId, "price"]}
                    style={{ width: "100%", marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "Bitte geben Sie einen Preis ein.",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      placeholder="Preis"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Rabatt (%)"
                    name={["productsData", productId, "discount"]}
                    style={{ width: "100%", marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "Bitte geben Sie einen Rabatt ein.",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      placeholder="Rabatt"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ height: "10px", width: "100%" }}></div>

              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Intervall"
                    name={["productsData", productId, "interval"]}
                    style={{ width: "100%", marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "Bitte wählen Sie ein Intervall aus.",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="Jährlich">Jährlich</Radio>
                      <Radio value="Halbjährlich">Halbjährlich</Radio>
                      <Radio value="Quartalsweise">Quartalsweise</Radio>
                      <Radio value="Monatlich">Monatlich</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Kommentar"
                    name={["productsData", productId, "comment"]}
                    style={{ width: "100%", marginBottom: 0 }}
                  >
                    <Input.TextArea
                      rows={1}
                      placeholder="Kommentar"
                      style={{ resize: "none" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          );
        })}

        <div style={{ marginBottom: "12px" }}>
          Dateiuploads
          {uploads.map((upload, index) => (
            <div
              key={index}
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                padding: "8px",
                marginBottom: "12px",
              }}
            >
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%", marginTop: "8px" }}
              >
                {/* Datei, Label und Beschreibung in einer Zeile */}

                {/* Datei */}
                <Form.Item
                  name={["uploads", index, "file"]}
                  rules={[
                    {
                      required: true,
                      message: "Bitte wählen Sie eine Datei aus.",
                    },
                  ]}
                  style={{ flex: 2, marginBottom: 0 }}
                >
                  <Dragger
                    beforeUpload={() => false}
                    maxCount={1}
                    showUploadList={{ showPreviewIcon: false }}
                    style={{ width: "100%" }}
                  >
                    <Button icon={<Icon icon="fa:upload" />} />
                  </Dragger>
                </Form.Item>
                <Space style={{ display: "flex", gap: "8px", width: "100%" }}>
                  <Form.Item
                    name={["uploads", index, "label"]} // Eindeutiger Schlüssel für "label"
                    rules={[
                      {
                        required: true,
                        message: "Bitte geben Sie ein Label ein.",
                      },
                    ]}
                    style={{ flex: 3, marginBottom: 0 }}
                  >
                    <Input placeholder="Label" />
                  </Form.Item>

                  <Form.Item
                    name={["uploads", index, "description"]} // Eindeutiger Schlüssel für "description"
                    style={{ flex: 5, marginBottom: 0 }}
                  >
                    <Input placeholder="Beschreibung" />
                  </Form.Item>

                  {/* Entfernen-Button */}
                  <Button
                    type="text"
                    danger
                    onClick={() => handleRemoveUpload(upload.key)}
                    icon={<Icon icon="fa:trash" />}
                  />
                </Space>
              </Space>
            </div>
          ))}
          {/* Button zum Hinzufügen */}
          <Button
            type="dashed"
            onClick={handleAddUpload}
            icon={<Icon icon="fa:plus" />}
            style={{ width: "100%" }}
          >
            {uploads.length > 0
              ? "Weiteren Upload hinzufügen"
              : "Upload hinzufügen"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
