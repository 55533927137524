import Parse from "parse";
import { AppFactory, AppPluginInterface } from "@opendash/core";
import { $parse } from "@opendash/plugin-parse";
import { CustomerAdminComponent } from "./components/AdminPages/CustomerAdminComponent";
import { CustomerLicenseComponent } from "./components/CustomerLicenseComponent";
import { ExpensesComponent } from "./components/ExpensesComponent";
import {ProductAdminComponent} from "./components/AdminPages/ProductAdminComponent";


export interface Options {
  }

export class CustomerPlugin implements AppPluginInterface {
    name: string = "CustomerPlugin";

    private options: Partial<Options> = {
      
    };

  constructor(options?: Partial<Options>) {
    this.options = options || {};
  }

    async onFactory(factory: AppFactory) {
        factory.registerRoute({
          path: "/customer/license",
          props: this.options,
          component: async () => ({ default: CustomerLicenseComponent }),
          permission: "customer:view",
        });

        factory.registerRoute({
          path: "/customer/expenses",
          props: this.options,
          component: async () => ({ default: ExpensesComponent }),
          permission: "customer:admin",
        });

        factory.registerStaticNavigationGroup({
          label: "Kunden und Partner",
          order: 15,
          id: "customer/overview",
        });

        factory.registerStaticNavigationItem({
          id: "customer/licensesb",
          group: "customer/overview",
          place: "sidebar",
          order: 10,
          label: "Lizenzen und Verträge",
          icon: "fa:file-contract",
          link: "/customer/license",
          routeCondition: "/customer/**",
          activeCondition: "/customer/license",
          permission: "customer:view",
        });

        factory.registerStaticNavigationItem({
          id: "customer/expensesb",
          group: "customer/overview",
          place: "sidebar",
          order: 20,
          label: "Aufwände und Rechnung",
          icon: "fa:clock",
          link: "/customer/expenses",
          routeCondition: "/customer/**",
          activeCondition: "/customer/expenses",
          permission: "customer:admin",
        });

        factory.registerStaticNavigationItem({
          id: "customer/licensefp",
          place: "frontpage",
          group: "customerfp",
          order: 90,
          color: "#676767",
          label: "Kundenenprojekte",
          icon: "fa:building",
          link: "/customer/license",
          routeCondition: "**",
          activeCondition: "/customer/license",
          permission: "customer:view",
        });

        factory.registerRoute({
          path: "/admin/parse/customer",
          props: this.options,
          component: async () => ({ default: CustomerAdminComponent }),
          permission: "customer:admin",
        });

        factory.registerRoute({
          path: "/admin/parse/products",
          props: this.options,
          component: async () => ({ default: ProductAdminComponent }),
          permission: "customer:admin",
        });


        factory.registerStaticNavigationGroup({
          label: "Kunden und Partner",
          order: 15,
          id: "customer/entities",
        });


        factory.registerStaticNavigationItem({
          id: "admin/customeradmin",
          group: "customer/entities",
          place: "sidebar",
          order: 10,
          label: "Kunden",
          icon: "fa:building",
          link: "/admin/parse/customer",
          routeCondition: "/admin/**",
          activeCondition: "/admin/parse/customer",
          permission: "customer:admin",
        });

        factory.registerStaticNavigationItem({
          id: "admin/productsadmin",
          group: "customer/entities",
          place: "sidebar",
          order: 20,
          label: "Produkte",
          icon: "fa:box",
          link: "/admin/parse/products",
          routeCondition: "/admin/**",
          activeCondition: "/admin/parse/products",
          permission: "customer:admin",
        });

        $parse.ui.setClassConfig({
          className: "OD3_Customer",
          disableACLEditing: true,
          titleFields: ["Name"],
          displayFields: ["Kundennummer", "Name", "Strasse", "Postleitzahl", "Ort", "Land", "ignore"],
          createFields: ["Name", "Strasse", "Postleitzahl", "Ort", "Land", "ignore"],
          editFields: ["Name", "Strasse", "Postleitzahl", "Ort", "Land", "ignore"]
        });

        $parse.ui.setClassConfig({
          className: "OD3_Customer_Invoice",
          disableACLEditing: true,
          titleFields: ["invoiceNumber"],
          displayFields: [],
          createFields: [],
          editFields: [],
          customFilter:(query: Parse.Query) => {
                                          query.descending("invoiceDate");
                                        },
        });

        $parse.ui.setClassConfig({
          className: "OD3_Products",
          disableACLEditing: true,
          titleFields: ["Label"],
          displayFields: ["Label", "Description", "Color"],
          createFields: ["Label", "Description", "Color"],
          editFields: ["Label", "Description", "Color"]
        });
        

        // Translations:
        factory.registerTranslationResolver(
          "en",
          "researchproject",
          async () => await import("./translations/en.json")
        );
    
        factory.registerTranslationResolver(
          "de",
          "researchproject",
          async () => await import("./translations/de.json")
        );
      }
    }