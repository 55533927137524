import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { Icon } from "@opendash/icons";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { ColumnProps } from "antd/lib/table";

export const ProjectInvoiceComponent = createComponent(
  function ProjectInvoiceComponent() {
    return (
      <AdminLayout>
        <ParseSetView
          className="OD3_Invoice"
          view={
            {
              type: "table",
              customCols: [
                {
                  title: "Rechnungsdatum",
                  key: "InvoiceDate",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>
                        {dayjs(row?.get("InvoiceDate")).format("DD.MM.YYYY")}
                      </div>
                    );
                  },
                } as ColumnProps<any>,
                {
                  title: "Beschreibung",
                  key: "Description",
                  dataIndex: 0,
                  render: (v, row) => {
                    return <div>{row?.get("Description")}</div>;
                  },
                } as ColumnProps<any>,

                {
                  title: "Nettobetrag",
                  key: "Netto",
                  dataIndex: 0,
                  render: (v, row) => {
                    return <div>{row?.get("Netto")} €</div>;
                  },
                } as ColumnProps<any>,

                {
                  title: "Bruttobetrag",
                  key: "Brutto",
                  dataIndex: 0,
                  render: (v, row) => {
                    return <div>{row?.get("Brutto")} €</div>;
                  },
                } as ColumnProps<any>,
                {
                  title: "Abschreibungsdauer",
                  key: "Duration",
                  dataIndex: 0,
                  render: (v, row) => {
                    return <div>{row?.get("Duration")}</div>;
                  },
                } as ColumnProps<any>,
                {
                  title: "Rechnungsdatei",
                  key: "Invoice",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>
                        <Icon
                          style={{ fontSize: "20px" }}
                          icon="fa:file-pdf"
                          onClick={() => {
                            window.open(row.get("Invoice")._url, "_blank");
                          }}
                        ></Icon>
                      </div>
                    );
                  },
                } as ColumnProps<any>,
              ],
              fields: ["Position", "Name", "Project", "Supplier"],
            } as TableViewType
          }
        />
      </AdminLayout>
    );
  }
);
