import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { ColumnProps } from "antd/lib/table";

export const ProjectComponent = createComponent(function ProjectComponent() {
  return (
    <AdminLayout>
      <ParseSetView
        className="OD3_Projects"
        view={
          {
            type: "table",
            customCols: [
              {
                title: "Projektstart",
                key: "Datums",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    <div>{dayjs(row?.get("Start")).format("DD.MM.YYYY")}</div>
                  );
                },
              } as ColumnProps<any>,
              {
                title: "Projektende",
                key: "Datums2",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    <div>{dayjs(row?.get("End")).format("DD.MM.YYYY")}</div>
                  );
                },
              } as ColumnProps<any>,
              {
                title: "Fördersumme",
                key: "TotalMoney",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    <div>
                      {row?.get("TotalMoney").toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      €
                    </div>
                  );
                },
              } as ColumnProps<any>,
            ],
            fields: [
              "Name",
              "Forderart",
              "Jahresstunden",
              "Forderquote",
              "Overhead",
            ],
          } as TableViewType
        }
      />
    </AdminLayout>
  );
});
