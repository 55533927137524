import React from "react";
import dayjs from "dayjs";

interface TimelineProps {
  start: Date;
  end: Date;
  today: Date;
}

export const Timeline: React.FC<TimelineProps> = ({ start, end, today }) => {
  const [todayWidth, setTodayWidth] = React.useState<string>("0%");

  React.useEffect(() => {
    initTimeLine();
  }, [start, end, today]);

  const initTimeLine = () => {
    const startDate: any = start;
    const endDate: any = end;

    const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
    const daysPassed = Math.ceil(
      ((today as any) - startDate) / (1000 * 60 * 60 * 24)
    );

    const todayWidthPercentage = (daysPassed / totalDays) * 100;
    setTodayWidth(`${todayWidthPercentage}%`);
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "70px",
        overflow: "hidden",
      }}
    >
      {/* Startdatum */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: 0,
          transform: "translate(-50%, -50%)",
          width: "5px",
          height: "25px",
          backgroundColor: "#aaa",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "73%",
          left: "0",
          transform: "translateY(-50%)",
          textAlign: "center",
        }}
      >
        <br />
        <span>{dayjs(start).format("DD.MM.YYYY")}</span>
      </div>

      {/* Enddatum */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "100%",
          transform: "translate(-50%, -50%)",
          width: "5px",
          height: "25px",
          backgroundColor: "#aaa",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "73%",
          right: "0",
          transform: "translateY(-50%)",
          textAlign: "center",
        }}
      >
        <br />
        <span>{dayjs(end).format("DD.MM.YYYY")}</span>
      </div>

      {/* Zeitstrahl */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "0",
          transform: "translateY(-50%)",
          width: "100%",
          height: "2px",
          backgroundColor: "#aaa",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "25%",
          transform: "translate(-50%, -50%)",
          width: "2px",
          height: "15px",
          backgroundColor: "#aaa",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "2px",
          height: "20px",
          backgroundColor: "#aaa",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "75%",
          transform: "translate(-50%, -50%)",
          width: "2px",
          height: "15px",
          backgroundColor: "#aaa",
        }}
      ></div>

      {/* Aktueller Tag */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: todayWidth,
          transform: "translate(-50%, -50%)",
          width: "5px",
          height: "30px",
          backgroundColor: "#2db7f5",
        }}
      ></div>

      {/* Heutiges Datum */}
      <div
        style={{
          position: "absolute",
          top: "-11%",
          left: todayWidth,
          transform: "translate(-50%, 50%)",
          textAlign: "center",
        }}
      >
        <span>Heute</span>
      </div>
    </div>
  );
};
