import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import type { Dayjs } from "dayjs";
import { Row, Col, Select } from "antd";

interface HeaderSelectProps {
  selectedYear: number;
  selectedMonth: number;
  setSelectedYear: (year: number) => void;
  setSelectedMonth: (month: number) => void;
}

export const HeaderSelect: React.FC<HeaderSelectProps> = ({
  selectedYear,
  selectedMonth,
  setSelectedYear,
  setSelectedMonth,
}) => {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const value: Dayjs = dayjs().year(selectedYear).month(selectedMonth);
  const month: number = value.month();
  const year: number = value.year();

  const months: string[] = [];
  for (let i = 0; i < 12; i++) {
    months.push(dayjs().month(i).format("MMMM"));
  }

  const getMonthOptions = (): JSX.Element[] => {
    const options: JSX.Element[] = [];
    months.forEach((month, index) => {
      options.push(
        <Select.Option key={index} value={index} className="year-item">
          {month}
        </Select.Option>
      );
    });
    return options;
  };

  const getYearOptions = (): JSX.Element[] => {
    const currentYear: number = dayjs().year();
    const options: JSX.Element[] = [];
    for (let i = currentYear - 1; i < currentYear + 2; i += 1) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>
      );
    }
    return options;
  };

  return (
    <div
      style={{
        zIndex: 990,
        padding: "15px",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      <Row gutter={24}>
        <Col
          xs={{ span: 24 }}
          sm={24}
          md={24}
          lg={19}
          style={{
            margin: "0px",
            padding: "0px",
          }}
        ></Col>
        <Col
          xs={{ span: 24 }}
          sm={24}
          md={24}
          lg={5}
          style={{
            margin: "0px",
            padding: "0px",
          }}
        >
          {isMobile && (
            <Row
              gutter={24}
              style={{
                textAlign: "center",
                width: "100%",
                margin: "0px",
                padding: "0px",
              }}
            >
              <Col
                xs={{ span: 24 }}
                sm={24}
                md={24}
                lg={12}
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                  zIndex: 990,
                }}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                    margin: "0px",
                    padding: "0px",
                    zIndex: 990,
                  }}
                  className="my-year-select"
                  onChange={(newYear: string) => {
                    const now: Dayjs = value
                      .clone()
                      .year(parseInt(newYear, 10));
                    //onChange(now);
                    setSelectedYear(parseInt(newYear, 10));
                  }}
                  value={String(year)}
                >
                  {getYearOptions()}
                </Select>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={24}
                md={24}
                lg={12}
                style={{
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                  zIndex: 990,
                }}
              >
                <div style={{ width: "100%", height: "10px" }}></div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={24}
                md={24}
                lg={12}
                style={{
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                  zIndex: 990,
                }}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                    margin: "0px",
                    padding: "0px",
                    zIndex: 990,
                  }}
                  value={String(months[month])}
                  onChange={(newMonth: string) => {
                    const now: Dayjs = value
                      .clone()
                      .month(parseInt(newMonth, 10));
                    //onChange(now);
                    setSelectedMonth(parseInt(newMonth, 10));
                  }}
                >
                  {getMonthOptions()}
                </Select>
              </Col>
            </Row>
          )}
          {!isMobile && (
            <Row gutter={24} style={{ textAlign: "center" }}>
              <Col
                xs={{ span: 24 }}
                sm={24}
                md={24}
                lg={12}
                style={{ zIndex: 990 }}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  className="my-year-select"
                  onChange={(newYear: string) => {
                    const now: Dayjs = value
                      .clone()
                      .year(parseInt(newYear, 10));
                    //onChange(now);
                    setSelectedYear(parseInt(newYear, 10));
                  }}
                  value={String(year)}
                >
                  {getYearOptions()}
                </Select>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={24}
                md={24}
                lg={12}
                style={{ zIndex: 990 }}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  value={String(months[month])}
                  onChange={(newMonth: string) => {
                    const now: Dayjs = value
                      .clone()
                      .month(parseInt(newMonth, 10));
                    //onChange(now);
                    setSelectedMonth(parseInt(newMonth, 10));
                  }}
                >
                  {getMonthOptions()}
                </Select>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};
