import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { $parse } from "@opendash/plugin-parse";
import { AdminToolbar } from "@opendash/ui";
import { AdminLayout } from "@opendash/core";
import {
  Table,
  Select,
  Button,
  Popconfirm,
  Card,
  Space,
  message,
  Spin,
} from "antd";
import { Icon } from "@opendash/icons";
import { createComponent } from "@opendash/state";
import type { TableColumnsType } from "antd";
const { Option } = Select;
const { Meta } = Card;

export const VacationCheckComponent = createComponent(
  function VacationCheckComponentComponent() {
    const [tableData, setTableData] = React.useState<any>([]);
    const [parseData, setParseData] = React.useState<any>([]);
    const [parseDataForThisYear, setParseDataForThisYear] = React.useState<any>(
      []
    );
    const [userData, setUserData] = React.useState<any>([]);
    const [userMeta, setUserMeta] = React.useState<any>([]);
    const [freeDays, setFreeDays] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [totalVacationDays, setTotalVacationDays] = React.useState<Object>(
      {}
    );

    React.useEffect(() => {
      initUserData();
      initUserMeta();
      getFreeDays();
      getVacationDays();
      getParseData();
    }, []);

    React.useEffect(() => {
      getVacationDays();
    }, [userData]);

    React.useEffect(() => {
      getParseData();
    }, [totalVacationDays, userData]);

    React.useEffect(() => {
      formatTableData();
    }, [parseData]);

    const initUserData = async () => {
      try {
        const query = new Parse.Query("_User").limit(99999999);
        query.equalTo("VacationWithoutApplication", false);
        const result = await query.find();
        setUserData(result);
      } catch (error) {
        console.error("Fehler beim Abrufen der Parse-User-Daten:", error);
      }
    };

    const initUserMeta = async () => {
      try {
        const query = new Parse.Query("OD3_Contract").limit(99999999);
        query.include("User");
        const result = await query.find();
        setUserMeta(result);
      } catch (error) {
        console.error("Fehler beim Abrufen der Parse-User-Daten:", error);
      }
    };

    const getVacationDays = async () => {
      let totalVacationDaysPuffer: any = {};
      for (const element of userData) {
        const query = new Parse.Query("OD3_Contract").limit(99999999);
        query.equalTo("User", element);
        const results = await query.find();
        const filteredObjects = await results.filter((obj: any) => {
          const startDate = obj.get("Start");
          const endDate = obj.get("End");
          const today = new Date();
          const yearStart = new Date(today.getFullYear(), 0, 1);
          const yearStartWithOffset = new Date(
            yearStart.getTime() - 6 * 60 * 60 * 1000
          );
          const yearEnd = new Date(today.getFullYear(), 11, 31, 23, 59, 59);
          const yearEndWithOffsett = new Date(
            yearEnd.getTime() + 6 * 60 * 60 * 1000
          );

          return (
            yearStartWithOffset <= startDate && yearEndWithOffsett >= endDate
          );
        });

        let totalDaysAllContracts = 0;
        results.forEach((element: any) => {
          totalDaysAllContracts += element.get("VacationDays");
        });

        let totalUsedVacationDays = 0;
        const parseDataForUser = parseDataForThisYear.filter(
          (obj: any) => obj.get("User").id === element.id
        );

        if (parseDataForUser && parseDataForUser.length > 0) {
          parseDataForUser.forEach((element: any) => {
            if (element.get("Status") != 1) return;
            const currentYear = new Date().getFullYear();
            if (element.get("End").getFullYear() != currentYear)
              totalUsedVacationDays += element.get("Days");
          });
        }

        let totalLeftVacationDays =
          totalDaysAllContracts - totalUsedVacationDays;

        totalVacationDaysPuffer[element.id] = totalLeftVacationDays;

        /*
        let totalDays = 0;
        filteredObjects.forEach((element: any) => {
          totalDays += element.get("VacationDays");
        });
        totalVacationDaysPuffer[element.id] = totalDays;
        */
      }
      setTotalVacationDays(totalVacationDaysPuffer);
    };

    const vacationDaysOfUser = (User: { id: string }) => {
      return totalVacationDays[User.id as keyof typeof totalVacationDays] || 0;
    };

    const getParseData = async () => {
      try {
        const query = new Parse.Query("OD3_TimeVacation").limit(99999999);
        query.descending("createdAt");
        const result = await query.find();
        if (result && result.length > 0) {
          setParseData(result);
        }

        const queryNew = new Parse.Query("OD3_TimeVacation").limit(99999999);
        queryNew.descending("End");
        const startOfYear = new Date(new Date().getFullYear(), 0, 1);
        queryNew.greaterThanOrEqualTo("End", startOfYear);
        const resultNew = await queryNew.find();
        if (resultNew && resultNew.length > 0) {
          setParseDataForThisYear(resultNew);
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Parse-Daten:", error);
      }
    };

    const getFreeDays = async () => {
      try {
        const query = new Parse.Query("OD3_TimeTrackingBlocked").limit(
          99999999
        );
        const result = await query.find();
        setFreeDays(result);
      } catch (error) {
        console.error("Fehler beim Abrufen der freien Tage:", error);
      }
    };

    const calcUsedVacation = (id: string) => {
      let usedVacationDays = 0;
      if (parseData.length > 0) {
        let foundObject = parseData.filter(
          (obj: any) => obj.get("User").id === id
        );
        if (foundObject && foundObject.length > 0) {
          foundObject.forEach((element: any) => {
            if (element.get("Status") === 1) {
              usedVacationDays += element.get("Days");
            }
          });
        }
      }
      return usedVacationDays;
    };

    const editForm = async (form: any, e: number) => {
      if (e === 1) {
        // Add all Relations
        setLoading(true);
        try {
          const UserClass = Parse.Object.extend("_User");
          const userObject = new UserClass({ id: form.userid });
          await userObject.fetch();
          const query = new Parse.Query("OD3_Contract").limit(99999999);
          query.equalTo("User", userObject);
          let results = await query.find();
          const filteredObjects = results.find((obj) => {
            const startDate = obj.get("Start");
            const endDate = obj.get("End");
            return (
              (form.start >= startDate && form.end <= endDate) ||
              form.start.toDateString() === startDate.toDateString() ||
              form.end.toDateString() === endDate.toDateString()
            );
          });

          for (
            let date = form.start;
            date <= form.end;
            date.setDate(date.getDate() + 1)
          ) {
            if (date.getDay() != 0 && date.getDay() != 6) {
              //Delete Feiertage
              let foundObject = freeDays.find(
                (obj: any) =>
                  dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
                  dayjs(date).format("DD.MM.YYYY")
              );
              if (!foundObject) {
                const timeTrackingObject = new Parse.Object("OD3_TimeTracking");
                timeTrackingObject.set(
                  "Date",
                  new Date(dayjs(date).startOf("day").add(6, "hour").toDate())
                );
                timeTrackingObject.set(
                  "WorkingHours",
                  filteredObjects?.get("HoursADay")
                );
                timeTrackingObject.set("Comment", "Urlaubsantrag");
                timeTrackingObject.set("Sick", false);
                timeTrackingObject.set("Vacation", true);
                timeTrackingObject.set("Project", 0);
                timeTrackingObject.set("User", userObject);

                const acl = new Parse.ACL();
                const currentUserACL = userObject;
                if (currentUserACL) {
                  acl.setReadAccess(currentUserACL, true);
                  acl.setWriteAccess(currentUserACL, true);
                }
                acl.setRoleReadAccess(
                  "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
                  true
                );
                acl.setRoleWriteAccess(
                  "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
                  true
                );
                timeTrackingObject.setACL(acl);
                await timeTrackingObject.save().then(async (timetracking) => {
                  const VacationClass = Parse.Object.extend("OD3_TimeVacation");
                  const toSave = new VacationClass({ id: form.key });
                  try {
                    await toSave.fetch();
                    await toSave.save().then(async (e: any) => {
                      var relation = e.relation("TimeTracks");
                      relation.add(timetracking);
                      await e.save();
                    });
                  } catch (error) {
                    console.error(
                      "Fehler beim Editieren des Eintrags1:",
                      error
                    );
                    return false;
                  }
                });
              }
            }
          }
        } catch (error) {
          console.error(
            "Fehler beim Anlagen der Einträge der Einträge:",
            error
          );

          message.error(
            "Fehler beim Ändern des Urlaubsantrags! Urlaub ausserhalb der Vertragslaufzeit"
          );
          setLoading(false);
          return false;
        }

        try {
          const VacationClass = Parse.Object.extend("OD3_TimeVacation");
          const toSave = new VacationClass({ id: form.key });
          try {
            await toSave.fetch();
            toSave.set("Status", e);
            toSave.set("checked", Parse.User.current());
            await toSave.save().then(async (x: any) => {
              await x.save();
              await getParseData();
              setLoading(false);
            });
          } catch (error) {
            console.error("Fehler beim Editieren des Eintrags1:", error);
            message.error(
              "Fehler beim Ändern des Urlaubsantrags! Urlaub ausserhalb der Vertragslaufzeit"
            );
            setLoading(false);
            return false;
          }
        } catch (error) {
          console.error("Fehler beim Editieren des Eintrags2:", error);
          message.error(
            "Fehler beim Ändern des Urlaubsantrags! Urlaub ausserhalb der Vertragslaufzeit"
          );
          setLoading(false);
          return false;
        }
      } else {
        //Delete all Relations
        setLoading(true);
        try {
          const VacationClass = Parse.Object.extend("OD3_TimeVacation");
          const toSave = new VacationClass({ id: form.key });
          try {
            await toSave.fetch();
            await toSave.save().then(async (x: any) => {
              var relation = x.relation("TimeTracks");
              relation
                .query()
                .find()
                .then(async (results: any) => {
                  results.forEach(async (element: any) => {
                    await element.destroy();
                  });
                  await x.save();
                });
            });
          } catch (error) {
            console.error("Fehler beim Editieren des Eintrags1:", error);
            message.error(
              "Fehler beim Ändern des Urlaubsantrags! Urlaub ausserhalb der Vertragslaufzeit"
            );
            setLoading(false);
            return false;
          }
        } catch (error) {
          console.error("Fehler beim Editieren des Eintrags2:", error);
          message.error(
            "Fehler beim Ändern des Urlaubsantrags! Urlaub ausserhalb der Vertragslaufzeit"
          );
          setLoading(false);
          return false;
        }
        try {
          const VacationClass = Parse.Object.extend("OD3_TimeVacation");
          const toSave = new VacationClass({ id: form.key });
          try {
            await toSave.fetch();
            toSave.set("Status", e);
            toSave.set("checked", Parse.User.current());
            await toSave.save().then(async (x: any) => {
              await x.save();
              await getParseData();
              setLoading(false);
            });
          } catch (error) {
            console.error("Fehler beim Editieren des Eintrags1:", error);
            message.error(
              "Fehler beim Ändern des Urlaubsantrags! Urlaub ausserhalb der Vertragslaufzeit"
            );
            setLoading(false);
            return false;
          }
        } catch (error) {
          console.error("Fehler beim Editieren des Eintrags2:", error);
          message.error(
            "Fehler beim Ändern des Urlaubsantrags! Urlaub ausserhalb der Vertragslaufzeit"
          );
          setLoading(false);
          return false;
        }
      }
      message.success("Urlaubsantrag wurde erfolgreich geändert!");
      return true;
    };

    const deleteForm = async (form: any) => {
      setLoading(true);
      try {
        const VacationClass = Parse.Object.extend("OD3_TimeVacation");
        const toSave = new VacationClass({ id: form.key });
        try {
          await toSave.fetch();
          await toSave.save().then(async (x: any) => {
            var relation = x.relation("TimeTracks");
            relation
              .query()
              .find()
              .then(async (results: any) => {
                results.forEach(async (element: any) => {
                  await element.destroy();
                });
                await x.save();
                getParseData();
              });
          });
        } catch (error) {
          console.error("Fehler beim Editieren des Eintrags1:", error);
          return false;
        }
      } catch (error) {
        console.error("Fehler beim Editieren des Eintrags2:", error);
        return false;
      }

      try {
        const VacationClass = Parse.Object.extend("OD3_TimeVacation");
        let toDelete = await VacationClass.createWithoutData(form.key).fetch();
        await toDelete.destroy();
        await getParseData();
        setLoading(false);
      } catch (error) {
        console.error("Fehler beim Löschen des Eintrags:", error);
      }
    };

    const formatTableData = () => {
      if (parseData.length > 0) {
        let tableData: any[] = [];
        parseData.forEach((element: any) => {
          let data = {
            key: element.id,
            userid: element.get("User").id,
            name: element.get("User").get("name"),
            type: element.get("Type"),
            start: element.get("Start"),
            end: element.get("End"),
            days: element.get("Days"),
            rest:
              element.get("User").get("VacationDays") -
              calcUsedVacation(element.get("User").id),
            status: element.get("Status"),
          };
          tableData.push(data);
        });
        setTableData(tableData);
      }
    };

    interface DataType {
      key: React.Key;
      userid: React.Key;
      name: string;
      type: string;
      start: Date;
      end: Date;
      days: number;
      status: number;
    }

    const columns: TableColumnsType<DataType> = [
      {
        title: "Mitarbeiter",
        dataIndex: "name",
        align: "center",
      },
      {
        title: "Typ",
        dataIndex: "type",
        align: "center",
      },
      {
        title: "Start",
        dataIndex: "start",
        align: "center",
        render: (_: any, record: any) => {
          return <>{dayjs(record.start).format("DD.MM.YYYY")}</>;
        },
      },
      {
        title: "Ende",
        dataIndex: "end",
        align: "center",
        render: (_: any, record: any) => {
          return <>{dayjs(record.end).format("DD.MM.YYYY")}</>;
        },
      },
      {
        title: "Urlaubstage",
        dataIndex: "days",
        align: "center",
      },
      {
        title: "Status",
        dataIndex: "status",
        align: "center",
        render: (_: any, record: any) => {
          return (
            <Select
              value={record.status}
              onChange={(e: number) => {
                editForm(record, e);
              }}
            >
              <Select.Option value={0}>Beantragt</Select.Option>
              <Select.Option value={1}>Genehmigt</Select.Option>
              <Select.Option value={2}>Abgelehnt</Select.Option>
            </Select>
          );
        },
      },
      {
        title: "",
        dataIndex: "delete",
        align: "center",
        render: (_: any, record: any) => {
          return (
            <Popconfirm
              title="Antrag löschen?"
              onConfirm={(e) => {
                deleteForm(record);
              }}
              onCancel={(e) => {}}
              okText="Löschen"
              cancelText="Abbrechen"
            >
              <Button icon={<Icon icon="fa:trash" key="delete" />} danger />
            </Popconfirm>
          );
        },
      },
    ];

    const gridStyle: React.CSSProperties = {
      width: "33.33333%",
      textAlign: "center",
    };

    return (
      <AdminLayout>
        <AdminToolbar
          title="Urlaubsverwaltung"
          description="Urlaubsanträge bearbeiten und genehmigen."
          search={""}
        />
        <div
          className="od-page-main"
          style={{
            width: "100%",
          }}
        >
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Card title="">
              {userData.map((user: any, index: number) => (
                <Card.Grid style={gridStyle}>
                  <Meta
                    title={user.get("name")}
                    description={
                      <div>
                        Gesamter Urlaubsanspruch: {vacationDaysOfUser(user)}
                        <br />
                        Verbleibender Urlaub:{" "}
                        {vacationDaysOfUser(user) - calcUsedVacation(user.id)}
                      </div>
                    }
                  />
                </Card.Grid>
              ))}
            </Card>
            {loading && (
              <div
                className="od-page-main"
                style={{
                  width: "100%",
                  height: "30vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin />
              </div>
            )}
            {!loading && (
              <Table columns={columns} dataSource={tableData} size="small" />
            )}
          </Space>
        </div>
      </AdminLayout>
    );
  }
);
