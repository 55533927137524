import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { Icon } from "@opendash/icons";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { ColumnProps } from "antd/lib/table";

export const ProjectTripComponent = createComponent(
  function ProjectTripComponent() {
    return (
      <AdminLayout>
        <ParseSetView
          className="OD3_Trip"
          view={
            {
              type: "table",
              customCols: [
                {
                  title: "Startdatum",
                  key: "Start",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>{dayjs(row?.get("Start")).format("DD.MM.YYYY")}</div>
                    );
                  },
                } as ColumnProps<any>,
                {
                  title: "Enddatum",
                  key: "End",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>{dayjs(row?.get("End")).format("DD.MM.YYYY")}</div>
                    );
                  },
                } as ColumnProps<any>,

                {
                  title: "Beschreibung",
                  key: "Description",
                  dataIndex: 0,
                  render: (v, row) => {
                    return <div>{row?.get("Description")}</div>;
                  },
                } as ColumnProps<any>,

                {
                  title: "Kosten",
                  key: "Costs",
                  dataIndex: 0,
                  render: (v, row) => {
                    return <div>{row?.get("Costs")} €</div>;
                  },
                } as ColumnProps<any>,

                {
                  title: "Belege",
                  key: "Documents",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>
                        <Icon
                          style={{ fontSize: "20px" }}
                          icon="fa:file-pdf"
                          onClick={() => {
                            window.open(row.get("Documents")._url, "_blank");
                          }}
                        ></Icon>
                      </div>
                    );
                  },
                } as ColumnProps<any>,
              ],
              fields: ["Employee", "Project"],
            } as TableViewType
          }
        />
      </AdminLayout>
    );
  }
);
