import React from "react";
import Parse from "parse";
import { Input } from "antd";
import { CustomParseFieldProps } from "@opendash/plugin-parse";

const { TextArea } = Input;

export const TextMulti = (
  props: CustomParseFieldProps & {
    nameField?: string;
    descriptionField?: string;
  }
) => {
  const { value, setValue, fieldname, parseObject } = props;
  const [contracts, setContracts] = React.useState<Parse.Object[]>([]);

  React.useEffect(() => {}, []);

  return (
    <TextArea
      style={{ width: "100%" }}
      rows={4}
      value={value}
      placeholder="Beschreibung für den Zeitraum eingeben..."
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
};
