import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { Modal, DatePicker, Alert, Space, message } from "antd";

const { RangePicker } = DatePicker;

interface VacationModalProps {
  modal: boolean;
  setModalVisibility: any;
  addVacationApplication: any;
  anzahlWerktagen: any;
}

export const VacationModal: React.FC<VacationModalProps> = ({
  modal,
  setModalVisibility,
  addVacationApplication,
  anzahlWerktagen,
}) => {
  const [startEndDates, setStartEndDates] = React.useState<
    [dayjs.Dayjs, dayjs.Dayjs]
  >([dayjs(), dayjs().add(1, "day")]);

  return (
    <Modal
      title={"Neuen Urlaubsantrag"}
      open={modal}
      onOk={() => {
        addVacationApplication(
          startEndDates[0].toDate(),
          startEndDates[1].toDate(),
          "Erholungsurlaub",
          startEndDates[0],
          startEndDates[1]
        )
          ? setModalVisibility(false)
          : message.error("Es dürfen keine Zeiteinträge in den Tagen sein!");
      }}
      okText="Beantragen"
      onCancel={() => {
        setModalVisibility(false);
      }}
    >
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Alert
          message={
            "Entspricht " +
            anzahlWerktagen(startEndDates[0], startEndDates[1]) +
            " Urlaubstage"
          }
          type="info"
        />
        <RangePicker
          value={startEndDates}
          onChange={([start, end]) => {
            setStartEndDates([start || dayjs(), end || dayjs().add(1, "day")]);
          }}
          style={{ width: "100%" }}
          format="DD.MM.YYYY"
        />
      </Space>
    </Modal>
  );
};
