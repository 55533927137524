import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { DataTypePersonal, DataTypeInvest, DataTypeTrip } from "./Types";
import { observer } from "mobx-react-lite";
import { AdminToolbar } from "@opendash/ui";
import { Space, Spin, Select, Typography, Collapse } from "antd";
import { AdminLayout } from "@opendash/core";
import { Timeline } from "./Pieces/Timeline";
import { ProjectPersonal } from "./Pieces/ProjectPersonal";
import { ProjectInvest } from "./Pieces/ProjectInvest";
import { ProjectTrip } from "./Pieces/ProjectTrip";
import { Options } from "../Plugin";
import { ProjectRequest } from "./Pieces/ProjectRequest";
const { Title } = Typography;
const { Panel } = Collapse;

export const ProjectRequestComponent = observer((props: Partial<Options>) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [overAllCosts, setOverAllCosts] = React.useState<number[]>([]);
  const [currentProject, setCurrentProject] = React.useState<Parse.Object>();
  const [projectData, setProjectData] = React.useState<Parse.Object[]>([]);
  const [userMeta, setUserMeta] = React.useState<Parse.Object[]>([]);
  const [projectMeta, setProjectMeta] = React.useState<Parse.Object[]>([]);
  const [projectWorker, setProjectWorker] = React.useState<Parse.Object[]>([]);
  const [monthsInQuarter, setMonthsInQuarter] = React.useState<String[][]>([]);
  const [generatedQuarters, setGeneratedQuarters] = React.useState<Date[]>([]);
  const [personalKosten, setPersonalkosten] = React.useState<any[]>([]);
  const [otherKosten, setOtherkosten] = React.useState<any[]>([]);
  const [personalKostenNumber, setPersonalkostenNumber] = React.useState<
    number[]
  >([]);
  const [otherKostenNumber, setOtherkostenNumber] = React.useState<number[]>(
    []
  );
  const [materialKosten, setMaterialkosten] = React.useState<any[]>([]);
  const [investKosten, setInvestkosten] = React.useState<any[]>([]);
  const [rdKosten, setRDkosten] = React.useState<any[]>([]);
  const [tripKosten, setTripkosten] = React.useState<any[]>([]);
  const [selectedQuarter, setSelectedQuarter] = React.useState<number>(-1);
  const [personalKostenTable, setPersonalkostenTable] = React.useState<
    DataTypePersonal[]
  >([]);
  const [materialKostenTable, setMaterialkostenTable] = React.useState<
    DataTypeInvest[]
  >([]);
  const [investKostenTable, setInvestkostenTable] = React.useState<
    DataTypeInvest[]
  >([]);
  const [rdKostenTable, setRDkostenTable] = React.useState<DataTypeInvest[]>(
    []
  );
  const [otherKostenTable, setOtherkostenTable] = React.useState<
    DataTypePersonal[]
  >([]);
  const [tripKostenTable, setTripkostenTable] = React.useState<DataTypeTrip[]>(
    []
  );

  React.useEffect(() => {
    initProjectData();
  }, []);

  React.useEffect(() => {
    initProjectData();
  }, [currentProject]);

  React.useEffect(() => {
    initUserMeta(generatedQuarters, selectedQuarter);
    calcAllCosts();
  }, [selectedQuarter]);

  React.useEffect(() => {
    initProjectWorker();
  }, [userMeta, projectMeta]);

  React.useEffect(() => {
    calculateQuarterPerPerson();
  }, [projectWorker]);

  React.useEffect(() => {
    calcOverAllCosts();
  }, [
    personalKosten,
    otherKosten,
    rdKosten,
    materialKosten,
    tripKosten,
    investKosten,
  ]);

  React.useEffect(() => {
    calcAllCosts();
  }, [personalKosten, otherKosten]);

  React.useEffect(() => {
    if (
      generatedQuarters.length > 0 &&
      selectedQuarter > -1 &&
      generatedQuarters[selectedQuarter]?.getMonth() != undefined &&
      monthsInQuarter.length > 0
    ) {
      setLoading(false);
    }
  }, [generatedQuarters]);

  const calcAllCosts = () => {
    personalKostenTableData();
    otherKostenTableData();
    investKostenTableData(generatedQuarters[selectedQuarter + 1]);
    materialKostenTableData(
      generatedQuarters[selectedQuarter],
      generatedQuarters[selectedQuarter + 1]
    );
    rdKostenTableData(
      generatedQuarters[selectedQuarter],
      generatedQuarters[selectedQuarter + 1]
    );
    travelKostenTableData(
      generatedQuarters[selectedQuarter],
      generatedQuarters[selectedQuarter + 1]
    );
  };

  const calcOverAllCosts = () => {
    let oldOverAllCosts = 0;
    let newOverAllCosts = 0;

    oldOverAllCosts =
      materialKosten[1] +
      investKosten[1] +
      rdKosten[1] +
      tripKosten[1] +
      personalKostenNumber[1] +
      otherKostenNumber[1];
    newOverAllCosts =
      materialKosten[0] +
      investKosten[0] +
      rdKosten[0] +
      tripKosten[0] +
      personalKostenNumber[0] +
      otherKostenNumber[0];

    setOverAllCosts([newOverAllCosts, oldOverAllCosts]);
  };

  const initProjectData = async () => {
    try {
      let currentProjectVar: Parse.Object =
        currentProject || new Parse.Object();
      const query = new Parse.Query("OD3_Projects").limit(99999999);
      const result = await query.find();
      setProjectData(result);
      if (!currentProject && result.length > 0) {
        currentProjectVar = result[0];
      }

      const quarters = [];
      const monthsInQuarterListList: String[][] = [];
      let acutalQuarter = -1;
      let currentQuarter = new Date(
        currentProjectVar.get("Start").getFullYear(),
        Math.floor(currentProjectVar.get("Start").getMonth() / 3) * 3,
        1
      );
      while (currentQuarter < currentProjectVar.get("End")) {
        let currentQuarterBuffer = new Date(
          JSON.parse(JSON.stringify(currentQuarter))
        );
        let monthsInQuarterList: String[] = [];
        monthsInQuarterList.push(dayjs(currentQuarterBuffer).format("MM.YYYY"));
        monthsInQuarterList.push(
          dayjs(
            currentQuarterBuffer.setMonth(currentQuarterBuffer.getMonth() + 1)
          ).format("MM.YYYY")
        );
        monthsInQuarterList.push(
          dayjs(
            currentQuarterBuffer.setMonth(currentQuarterBuffer.getMonth() + 1)
          ).format("MM.YYYY")
        );
        quarters.push(new Date(currentQuarter));
        currentQuarter.setMonth(currentQuarter.getMonth() + 3);
        monthsInQuarterListList.push(monthsInQuarterList);
      }

      const today = new Date();

      for (let i = 0; i < quarters.length; i++) {
        const quarterStart = quarters[i];
        const quarterEnd = new Date(quarterStart);
        quarterEnd.setMonth(quarterEnd.getMonth() + 3);

        if (today >= quarterStart && today < quarterEnd) {
          acutalQuarter = i;
          break;
        }
      }

      if (currentProject != currentProjectVar) {
        setCurrentProject(currentProjectVar);
      }
      setGeneratedQuarters(quarters);
      setMonthsInQuarter(monthsInQuarterListList);

      if (acutalQuarter != -1) {
        setSelectedQuarter(acutalQuarter);
        initUserMeta(quarters, acutalQuarter);
      } else {
        setSelectedQuarter(quarters.length - 1);
        initUserMeta(quarters, quarters.length - 1);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-User-Daten:", error);
    }
  };

  const initUserMeta = async (
    quarterList: any[],
    selectedQuarterVar: number
  ) => {
    const query = new Parse.Query("OD3_Contract").limit(99999999);
    query.include("User");
    let results = await query.find();

    //ProjectMeta
    const queryProjectMeta = new Parse.Query("OD3_ProjectContract").limit(
      99999999
    );
    queryProjectMeta.include("Vertrag");
    queryProjectMeta.include("Projekt");
    let resultsProject = await queryProjectMeta.find();
    setProjectMeta(resultsProject);

    const filteredObjects = results.filter((obj: any) => {
      const startDate = dayjs(obj.get("Start")).format("MM.YYYY");
      const endDate = dayjs(obj.get("End")).format("MM.YYYY");
      const today = dayjs(quarterList[selectedQuarterVar]).format("MM.YYYY");
      return (
        today == startDate ||
        today == endDate ||
        (startDate < today && endDate > today)
      );
    });

    let userSeen: any = [];
    let userDouble: any = [];
    let sindgleContracts: any = [];
    let doubleContracts: any = [];
    filteredObjects.forEach((obj: any, index: number) => {
      if (!userSeen.includes(obj.get("User").id)) {
        userSeen.push(obj.get("User").id);
        sindgleContracts.push(obj);
      } else {
        userDouble.push(obj.get("User").id);
        doubleContracts.push(
          sindgleContracts[userSeen.indexOf(obj.get("User").id)]
        );
        doubleContracts.push(obj);
      }
    });

    let betterIndex = doubleContracts.findIndex((contract: any) => {
      const closestObject = findClosestObject(
        doubleContracts,
        quarterList[selectedQuarterVar]
      );
      return contract === closestObject;
    });
    try {
      const filteredObjectsSingle = filteredObjects.filter(
        (obj) => obj.id !== doubleContracts[betterIndex].id
      );

      setUserMeta(filteredObjectsSingle);
    } catch (error) {
      setUserMeta(filteredObjects);
    }
  };

  const findClosestObject = (objects: any[], date: Date) => {
    if (date) {
      const calculateDifference = (time1: Date, time2: Date) => {
        return Math.abs(time1.getTime() - time2.getTime());
      };

      let closestObject = null;
      let minDifference = Infinity;

      objects.forEach((obj) => {
        const startTime = obj.get("Start");
        const endTime = obj.get("End");

        const startDifference = calculateDifference(startTime, date);

        const endDifference = calculateDifference(endTime, date);

        if (startDifference < minDifference) {
          minDifference = startDifference;
          closestObject = obj;
        }
        if (endDifference < minDifference) {
          minDifference = endDifference;
          closestObject = obj;
        }
      });

      return closestObject;
    } else {
      return {};
    }
  };

  const initProjectWorker = () => {
    let projectWorkerList: Parse.Object[] = [];
    userMeta.forEach((meta: Parse.Object) => {
      let getProjectID = projectMeta?.find((project: Parse.Object) => {
        return project?.get("Vertrag").id === meta.id;
      });
      if (getProjectID?.get("Projekt").id === currentProject?.id) {
        projectWorkerList.push(meta.get("User"));
      }
    });
    setProjectWorker(projectWorkerList);
  };

  const getTimeTrackingOfUserInQuarter = async (
    worker: Parse.Object,
    quarter: Date,
    nextquarter: Date
  ) => {
    const query = new Parse.Query("OD3_TimeTracking").limit(99999999);
    query.equalTo("User", worker);
    query.greaterThanOrEqualTo("Date", quarter);
    query.lessThan("Date", nextquarter);
    let results = await query.find();
    return results;
  };

  const getTimeTrackingOfUserBeforeQuarterInYear = async (
    worker: Parse.Object,
    quarter: Date
  ) => {
    const query = new Parse.Query("OD3_TimeTracking").limit(99999999);
    query.equalTo("User", worker);
    query.greaterThanOrEqualTo("Date", dayjs(quarter).startOf("year").toDate());
    query.lessThan("Date", quarter);
    let results = await query.find();
    return results;
  };

  const calculateQuarterPerPerson = async () => {
    const personenKostenList: any = [];
    const otherKostenList: any = [];

    let oldCosts = 0;
    let newCosts = 0;
    let oldCostsOther = 0;
    let newCostsOther = 0;

    for (const worker of projectWorker) {
      //Calculate Yearly Costs
      let yearlyCosts = 0;
      let specials = false;
      let aquivalent = 0;
      let overhead = true;
      let ignore = false;
      userMeta.forEach((meta: Parse.Object) => {
        if (meta.get("User").id === worker.id) {
          let getProjectID = projectMeta?.find((project: Parse.Object) => {
            return project?.get("Vertrag").id === meta.id;
          });

          yearlyCosts +=
            Math.round(getProjectID?.get("Monatsgehalt") * 12 * 100) / 100;
          if (meta.get("Status") != "Vollzeit") {
            specials = true;
            aquivalent = 40 / (meta.get("HoursADay") * 5);
          }
          if (meta.get("Status") === "Aushilfe ohne Overhead") {
            overhead = false;
          }
          if (meta.get("Status") === "Werksstudent") {
            ignore = true;
            overhead = false;
          }
        }
      });
      if (!ignore) {
        //Calculate Cost Rate of Project
        let yearlyHours = currentProject?.get("Jahresstunden");
        if (specials) yearlyHours = yearlyHours / aquivalent;
        let costRate = Math.round((yearlyCosts / yearlyHours) * 100) / 100;

        //Calculate Costs per Quarter
        let timeTrackings = await getTimeTrackingOfUserInQuarter(
          worker,
          generatedQuarters[selectedQuarter],
          generatedQuarters[selectedQuarter + 1]
        );
        let workingHoursInQuarter = 0;
        timeTrackings.forEach((timeTracking: Parse.Object) => {
          workingHoursInQuarter +=
            timeTracking.get("WorkingHours") *
            (timeTracking.get("Project") / 100);
        });

        //get TimeTracking of User before Quarter in Year
        let timeTrackingsOld = await getTimeTrackingOfUserBeforeQuarterInYear(
          worker,
          generatedQuarters[selectedQuarter]
        );
        let workingHoursInOldQuarter = 0;
        timeTrackingsOld.forEach((timeTracking: Parse.Object) => {
          workingHoursInOldQuarter +=
            timeTracking.get("WorkingHours") *
            (timeTracking.get("Project") / 100);
        });

        const monthlyTotals: any = {};

        // Durchlaufen der Daten und Berechnung der Summe für jeden Monat
        timeTrackings.forEach((item) => {
          const month = item.get("Date").getMonth();
          const key = `${month + 1}.${item.get("Date").getFullYear()}`; // Monat.Jahr als Schlüssel
          const product =
            item.get("WorkingHours") * (item.get("Project") / 100);

          // Hinzufügen zum entsprechenden Monat in den Ergebnissen
          if (!monthlyTotals[key]) {
            monthlyTotals[key] = product;
          } else {
            monthlyTotals[key] += product;
          }
        });

        let personenKosten = {};
        if (!overhead) {
          personenKosten = {
            name: worker.get("name"),
            overhead: overhead,
            yearCosts: yearlyCosts,
            yearHours: yearlyHours,
            costRate: costRate,
            workingHoursMonth: monthlyTotals,
            workingHoursInOldQuarter: workingHoursInOldQuarter,
            workingHoursInQuarter: workingHoursInQuarter,
            costsInQuarter: workingHoursInQuarter * costRate,
            costsInQuarterWithOverhead: workingHoursInQuarter * costRate,
            totalCostsUntilQuarter:
              (workingHoursInQuarter + workingHoursInOldQuarter) * costRate,
          };
          newCosts +=
            (workingHoursInQuarter + workingHoursInOldQuarter) * costRate;
          oldCosts += workingHoursInOldQuarter * costRate;
        } else {
          personenKosten = {
            name: worker.get("name"),
            overhead: overhead,
            yearCosts: yearlyCosts,
            yearHours: yearlyHours,
            costRate: costRate,
            workingHoursMonth: monthlyTotals,
            workingHoursInOldQuarter: workingHoursInOldQuarter,
            workingHoursInQuarter: workingHoursInQuarter,
            costsInQuarter: workingHoursInQuarter * costRate,
            costsInQuarterWithOverhead:
              workingHoursInQuarter *
              costRate *
              (1 + currentProject?.get("Overhead") / 100),
            totalCostsUntilQuarter:
              (workingHoursInQuarter + workingHoursInOldQuarter) *
              costRate *
              (1 + currentProject?.get("Overhead") / 100),
          };
          newCosts +=
            (workingHoursInQuarter + workingHoursInOldQuarter) *
            costRate *
            (1 + currentProject?.get("Overhead") / 100);
          oldCosts +=
            workingHoursInOldQuarter *
            costRate *
            (1 + currentProject?.get("Overhead") / 100);
        }
        personenKostenList.push(personenKosten);
      } else {
        //Calculate Cost Rate of Project
        let yearlyHours = currentProject?.get("Jahresstunden");
        if (specials) yearlyHours = yearlyHours / aquivalent;
        let costRate = Math.round((yearlyCosts / yearlyHours) * 100) / 100;

        //Calculate Costs per Quarter
        let timeTrackings = await getTimeTrackingOfUserInQuarter(
          worker,
          generatedQuarters[selectedQuarter],
          generatedQuarters[selectedQuarter + 1]
        );
        let workingHoursInQuarter = 0;
        timeTrackings.forEach((timeTracking: Parse.Object) => {
          workingHoursInQuarter +=
            timeTracking.get("WorkingHours") *
            (timeTracking.get("Project") / 100);
        });

        //get TimeTracking of User before Quarter in Year
        let timeTrackingsOld = await getTimeTrackingOfUserBeforeQuarterInYear(
          worker,
          generatedQuarters[selectedQuarter]
        );
        let workingHoursInOldQuarter = 0;
        timeTrackingsOld.forEach((timeTracking: Parse.Object) => {
          workingHoursInOldQuarter +=
            timeTracking.get("WorkingHours") *
            (timeTracking.get("Project") / 100);
        });

        const monthlyTotals: any = {};

        // Durchlaufen der Daten und Berechnung der Summe für jeden Monat
        timeTrackings.forEach((item) => {
          const month = item.get("Date").getMonth();
          const key = `${month + 1}.${item.get("Date").getFullYear()}`; // Monat.Jahr als Schlüssel
          const product =
            item.get("WorkingHours") * (item.get("Project") / 100);

          // Hinzufügen zum entsprechenden Monat in den Ergebnissen
          if (!monthlyTotals[key]) {
            monthlyTotals[key] = product;
          } else {
            monthlyTotals[key] += product;
          }
        });

        let otherKosten = {};
        if (!overhead) {
          otherKosten = {
            name: worker.get("name"),
            overhead: overhead,
            yearCosts: yearlyCosts,
            yearHours: yearlyHours,
            costRate: costRate,
            workingHoursMonth: monthlyTotals,
            workingHoursInOldQuarter: workingHoursInOldQuarter,
            workingHoursInQuarter: workingHoursInQuarter,
            costsInQuarter: workingHoursInQuarter * costRate,
            costsInQuarterWithOverhead: workingHoursInQuarter * costRate,
            totalCostsUntilQuarter:
              (workingHoursInQuarter + workingHoursInOldQuarter) * costRate,
          };
          newCostsOther +=
            (workingHoursInQuarter + workingHoursInOldQuarter) * costRate;
          oldCostsOther += workingHoursInOldQuarter * costRate;
        } else {
          otherKosten = {
            name: worker.get("name"),
            overhead: overhead,
            yearCosts: yearlyCosts,
            yearHours: yearlyHours,
            costRate: costRate,
            workingHoursMonth: monthlyTotals,
            workingHoursInOldQuarter: workingHoursInOldQuarter,
            workingHoursInQuarter: workingHoursInQuarter,
            costsInQuarter: workingHoursInQuarter * costRate,
            costsInQuarterWithOverhead:
              workingHoursInQuarter *
              costRate *
              (1 + currentProject?.get("Overhead") / 100),
            totalCostsUntilQuarter:
              (workingHoursInQuarter + workingHoursInOldQuarter) *
              costRate *
              (1 + currentProject?.get("Overhead") / 100),
          };
          newCostsOther +=
            (workingHoursInQuarter + workingHoursInOldQuarter) *
            costRate *
            (1 + currentProject?.get("Overhead") / 100);
          oldCostsOther +=
            workingHoursInOldQuarter *
            costRate *
            (1 + currentProject?.get("Overhead") / 100);
        }
        otherKostenList.push(otherKosten);
      }
    }
    setPersonalkosten(personenKostenList);
    setOtherkosten(otherKostenList);
    setPersonalkostenNumber([newCosts, oldCosts]);
    setOtherkostenNumber([newCostsOther, oldCostsOther]);
  };

  const personalKostenTableData = async () => {
    const data: DataTypePersonal[] = [];
    if (selectedQuarter != -1) {
      personalKosten.forEach((item) => {
        const month1 =
          item.workingHoursMonth[
            `${
              monthsInQuarter[selectedQuarter][0].startsWith("0")
                ? monthsInQuarter[selectedQuarter][0].slice(1)
                : monthsInQuarter[selectedQuarter][0]
            }`
          ] || 0;
        const month2 =
          item.workingHoursMonth[
            `${
              monthsInQuarter[selectedQuarter][1].startsWith("0")
                ? monthsInQuarter[selectedQuarter][1].slice(1)
                : monthsInQuarter[selectedQuarter][1]
            }`
          ] || 0;
        const month3 =
          item.workingHoursMonth[
            `${
              monthsInQuarter[selectedQuarter][2].startsWith("0")
                ? monthsInQuarter[selectedQuarter][2].slice(1)
                : monthsInQuarter[selectedQuarter][2]
            }`
          ] || 0;
        const sum = item.workingHoursInQuarter;
        data.push({
          key: item.name + Math.random() + Math.random() + "",
          name: item.name,
          month1:
            month1.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          month2:
            month2.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          month3:
            month3.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          sum:
            sum.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          yearcosts:
            item.yearCosts.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
          yearhours: item.yearHours.toLocaleString("de-DE", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }),
          costRate:
            item.costRate.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €/Std.",
          costsInQuarter:
            item.costsInQuarter.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
          costsInQuarterWithOverhead:
            item.costsInQuarterWithOverhead.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
        });
      });
    }
    setPersonalkostenTable(data);
  };

  const otherKostenTableData = async () => {
    const data: DataTypePersonal[] = [];

    if (selectedQuarter != -1) {
      otherKosten.forEach((item) => {
        const month1 =
          item.workingHoursMonth[
            `${
              monthsInQuarter[selectedQuarter][0].startsWith("0")
                ? monthsInQuarter[selectedQuarter][0].slice(1)
                : monthsInQuarter[selectedQuarter][0]
            }`
          ] || 0;
        const month2 =
          item.workingHoursMonth[
            `${
              monthsInQuarter[selectedQuarter][1].startsWith("0")
                ? monthsInQuarter[selectedQuarter][1].slice(1)
                : monthsInQuarter[selectedQuarter][1]
            }`
          ] || 0;
        const month3 =
          item.workingHoursMonth[
            `${
              monthsInQuarter[selectedQuarter][2].startsWith("0")
                ? monthsInQuarter[selectedQuarter][2].slice(1)
                : monthsInQuarter[selectedQuarter][2]
            }`
          ] || 0;
        const sum = item.workingHoursInQuarter;
        data.push({
          key: item.name,
          name: item.name,
          month1:
            month1.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          month2:
            month2.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          month3:
            month3.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          sum:
            sum.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }) + " Stunden",
          yearcosts:
            item.yearCosts.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
          yearhours: item.yearHours.toLocaleString("de-DE", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }),
          costRate:
            item.costRate.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €/Std.",
          costsInQuarter:
            item.costsInQuarter.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
          costsInQuarterWithOverhead:
            item.costsInQuarterWithOverhead.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
        });
      });
    }
    setOtherkostenTable(data);
  };

  const materialKostenTableData = async (quarter: Date, nextquarter: Date) => {
    const data: DataTypeInvest[] = [];
    const query = new Parse.Query("OD3_Invoice").limit(99999999);
    const dayjsDate = dayjs(quarter);
    const startOfYear = dayjsDate.startOf("year").toDate();
    const endOfYear = dayjsDate.endOf("year").toDate();
    query.equalTo("Project", currentProject);
    query.equalTo("Position", "0813");
    query.greaterThanOrEqualTo("InvoiceDate", startOfYear);
    query.lessThan(
      "InvoiceDate",
      nextquarter > endOfYear ? endOfYear : nextquarter
    );
    let results = await query.find();
    let oldMatCosts = 0;
    let newMatCosts = 0;
    results.forEach((item: Parse.Object) => {
      if (item.get("InvoiceDate") < quarter) {
        oldMatCosts += item.get("Netto");
        newMatCosts += item.get("Netto");
      } else {
        newMatCosts += item.get("Netto");
        data.push({
          key: item.id,
          name: item.get("Name"),
          description: item.get("Description"),
          netto: item.get("Netto"),
          invoicedate: item.get("InvoiceDate"),
          invoice: item.get("Invoice")?.url() || "",
          duration: "0",
          monthcosts: 0,
        });
      }
    });
    setMaterialkostenTable(data);
    setMaterialkosten([newMatCosts, oldMatCosts]);
  };

  const investKostenTableData = async (quarter: Date) => {
    let oldInvestCosts = 0;
    let newInvestCosts = 0;
    const data: DataTypeInvest[] = [];
    const query = new Parse.Query("OD3_Invoice").limit(99999999);
    query.equalTo("Project", currentProject);
    query.equalTo("Position", "0847");
    query.lessThanOrEqualTo("InvoiceDate", quarter);
    let results = await query.find();

    results.forEach((item: Parse.Object) => {
      let datePuffer = dayjs(item.get("InvoiceDate"))
        .endOf("month")
        .add(item.get("Duration") - 1, "month");

      const start = dayjs(item.get("InvoiceDate")).startOf("month");
      const end = datePuffer;
      let count = 0;

      monthsInQuarter[selectedQuarter].forEach((monthString) => {
        const [month, year] = monthString.split(".");
        const monthDate = dayjs(`${year}-${month}-01`);

        if (
          (monthDate.isSame(start, "month") ||
            monthDate.isAfter(start, "month")) &&
          (monthDate.isSame(end, "month") || monthDate.isBefore(end, "month"))
        ) {
          count++;
        }
      });

      if (count > 0) {
        const [month, year] = monthsInQuarter[selectedQuarter][0].split(".");
        const startOfMonth = dayjs(`${year}-${month}-01`);
        const current = startOfMonth;
        let oldCount = 0;

        for (let month = 1; month < current.month() + 1; month++) {
          const monthDate = dayjs(
            `${current.year()}-${String(month).padStart(2, "0")}-01`
          );
          if (
            (monthDate.isSame(start, "month") ||
              monthDate.isAfter(start, "month")) &&
            (monthDate.isSame(end, "month") || monthDate.isBefore(end, "month"))
          ) {
            oldCount++;
          }
        }
        oldInvestCosts =
          (Math.round((item.get("Netto") / item.get("Duration")) * 100) / 100) *
          oldCount;

        newInvestCosts =
          oldInvestCosts +
          (Math.round((item.get("Netto") / item.get("Duration")) * 100) / 100) *
            count;

        data.push({
          key: item.id,
          name: item.get("Name"),
          description: item.get("Description"),
          netto: item.get("Netto"),
          invoicedate: item.get("InvoiceDate"),
          duration: item.get("Duration"),
          monthcosts:
            Math.round((item.get("Netto") / item.get("Duration")) * 100) / 100,
          invoice: item.get("Invoice")?.url() || "",
        });
      }
    });
    setInvestkostenTable(data);
    setInvestkosten([newInvestCosts, oldInvestCosts]);
  };

  const rdKostenTableData = async (quarter: Date, nextquarter: Date) => {
    const data: DataTypeInvest[] = [];
    const query = new Parse.Query("OD3_Invoice").limit(99999999);
    const dayjsDate = dayjs(quarter);
    const startOfYear = dayjsDate.startOf("year").toDate();
    const endOfYear = dayjsDate.endOf("year").toDate();
    query.equalTo("Project", currentProject);
    query.equalTo("Position", "0823");
    query.greaterThanOrEqualTo("InvoiceDate", startOfYear);
    query.lessThan(
      "InvoiceDate",
      nextquarter > endOfYear ? endOfYear : nextquarter
    );
    let results = await query.find();
    let oldMatCosts = 0;
    let newMatCosts = 0;
    results.forEach((item: Parse.Object) => {
      if (item.get("InvoiceDate") < quarter) {
        oldMatCosts += item.get("Netto");
        newMatCosts += item.get("Netto");
      } else {
        newMatCosts += item.get("Netto");
        data.push({
          key: item.id,
          name: item.get("Name"),
          description: item.get("Description"),
          netto: item.get("Netto"),
          invoicedate: item.get("InvoiceDate"),
          invoice: item.get("Invoice")?.url() || "",
          duration: "0",
          monthcosts: 0,
        });
      }
    });
    setRDkostenTable(data);
    setRDkosten([newMatCosts, oldMatCosts]);
  };

  const travelKostenTableData = async (quarter: Date, nextquarter: Date) => {
    const data: DataTypeTrip[] = [];
    const query = new Parse.Query("OD3_Trip").limit(99999999);
    const dayjsDate = dayjs(quarter);
    const startOfYear = dayjsDate.startOf("year").toDate();
    const endOfYear = dayjsDate.endOf("year").toDate();
    query.equalTo("Project", currentProject);
    query.greaterThanOrEqualTo("End", startOfYear);
    query.lessThan("End", nextquarter > endOfYear ? endOfYear : nextquarter);
    let results = await query.find();
    let oldTravelCosts = 0;
    let newTravelCosts = 0;
    results.forEach((item: Parse.Object) => {
      if (item.get("End") < quarter) {
        oldTravelCosts += item.get("Costs");
        newTravelCosts += item.get("Costs");
      } else {
        newTravelCosts += item.get("Costs");
        data.push({
          key: item.id,
          start: item.get("Start"),
          end: item.get("End"),
          employee: item.get("Employee").get("name"),
          description: item.get("Description"),
          costs: item.get("Costs"),
          documents: item.get("Documents")?.url() || "",
        });
      }
    });
    setTripkostenTable(data);
    setTripkosten([newTravelCosts, oldTravelCosts]);
  };

  return (
    <AdminLayout>
      <AdminToolbar
        title="Mittelabruf"
        description="Planung und Berechnung für den Mittelabruf von Forschungsprojekten."
        search={""}
        actions={[
          <Select
            key="1"
            value={selectedQuarter}
            onChange={(e) => {
              setSelectedQuarter(e);
            }}
          >
            {generatedQuarters.map((quarter, index) => (
              <Select.Option key={index} value={index}>
                {`Q${
                  Math.floor(quarter.getMonth() / 3) + 1
                }/${quarter?.getFullYear()}`}
              </Select.Option>
            ))}
          </Select>,
          <Select
            key="2"
            value={currentProject?.get("Name")}
            style={{ width: 200 }}
            onChange={(e: any) => {
              setCurrentProject(projectData[e]);
            }}
          >
            {projectData.map((project: any, index: number) => (
              <Select.Option key={project.id} value={index}>
                {project.get("Name")}
              </Select.Option>
            ))}
          </Select>,
        ]}
      />

      {loading && (
        <div
          className="od-page-main"
          style={{
            width: "100%",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      )}
      {!loading && (
        <div
          className="od-page-main"
          style={{
            width: "100%",
          }}
        >
          <Space direction="vertical" size="large" style={{ display: "flex" }}>
            <Space
              direction="vertical"
              size="small"
              style={{ display: "flex" }}
            >
              <Title level={4} style={{ textAlign: "center" }}>
                Projektzeitleiste
              </Title>

              <Timeline
                start={currentProject?.get("Start")}
                end={currentProject?.get("End")}
                today={new Date()}
              />
            </Space>
            <br />
            <Title level={4}>Mittelabruf</Title>
            <div
              style={{
                width: "100%",
                display: "flex",
                paddingLeft: "25px",
                paddingRight: "25px",
                justifyContent: "center",
              }}
            >
              <ProjectRequest
                personalKosten={personalKosten}
                otherKosten={otherKosten}
                materialKosten={materialKosten}
                tripKosten={tripKosten}
                rdKosten={rdKosten}
                investKosten={investKosten}
                quarterList={generatedQuarters[selectedQuarter]}
                currentProject={currentProject}
                monthsInQuarter={monthsInQuarter}
                selectedQuarter={selectedQuarter}
                overAllCosts={overAllCosts}
              />
            </div>
            <br />
            <Title level={4}>Details</Title>

            <Collapse defaultActiveKey={["1"]} onChange={() => {}}>
              <Panel header={<Title level={5}>0813 Material</Title>} key="22">
                <ProjectInvest
                  dataSource={materialKostenTable}
                  invest={false}
                />
              </Panel>
              <Panel
                header={<Title level={5}>0823 FE-Fremdleistung</Title>}
                key="2"
              >
                <ProjectInvest dataSource={rdKostenTable} invest={false} />
              </Panel>
              <Panel
                header={<Title level={5}>0837 Personalkosten</Title>}
                key="1"
              >
                <ProjectPersonal
                  dataSource={personalKostenTable}
                  currentQuarter={monthsInQuarter[selectedQuarter]}
                />
              </Panel>
              <Panel
                header={<Title level={5}>0838 Reisekosten</Title>}
                key="32"
              >
                <ProjectTrip dataSource={tripKostenTable} />
              </Panel>
              <Panel
                header={<Title level={5}>0847 Abschreibungen</Title>}
                key="3"
              >
                <ProjectInvest dataSource={investKostenTable} invest={true} />
              </Panel>
              <Panel
                header={<Title level={5}>0850 Sonstige Kosten</Title>}
                key="5"
              >
                <ProjectPersonal
                  dataSource={otherKostenTable}
                  currentQuarter={monthsInQuarter[selectedQuarter]}
                />
              </Panel>
            </Collapse>
          </Space>
        </div>
      )}
    </AdminLayout>
  );
});
