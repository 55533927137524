import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { ColumnProps } from "antd/lib/table";

export const MoneyCallComponent = createComponent(
  function MoneyCallComponent() {
    return (
      <AdminLayout>
        <ParseSetView
          className="OD3_ProjectCalls"
          view={
            {
              type: "table",
              customCols: [
                {
                  title: "Anforderungsdatum",
                  key: "Anforderungsdatum",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>
                        {dayjs(row?.get("Anforderungsdatum")).format(
                          "DD.MM.YYYY"
                        )}
                      </div>
                    );
                  },
                } as ColumnProps<any>,
                {
                  title: "Abrufsumme",
                  key: "Abrufsumme",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>
                        {row?.get("Abrufssumme").toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        €
                      </div>
                    );
                  },
                } as ColumnProps<any>,
              ],
              fields: ["Projekt", "Abrufart"],
            } as TableViewType
          }
        />
        <br />
        <br />
        <ParseSetView
          className="OD3_Project_Documents"
          view={
            {
              type: "table",
              fields: ["Project", "Document", "createdAt"],
            } as TableViewType
          }
        />
      </AdminLayout>
    );
  }
);
