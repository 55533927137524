import React from "react";
import Parse from "parse";
import dayjs from "dayjs";
import { Select } from "antd";
import { CustomParseFieldProps } from "@opendash/plugin-parse";

export const SelectContract = (
  props: CustomParseFieldProps & {
    nameField?: string;
    descriptionField?: string;
  }
) => {
  const { value, setValue, fieldname, parseObject } = props;
  const [contracts, setContracts] = React.useState<Parse.Object[]>([]);

  React.useEffect(() => {
    initContracts();
  }, []);

  const initContracts = async () => {
    const query = new Parse.Query("OD3_Contract").limit(99999999);
    query.include("User");
    let results = await query.find();
    setContracts(results);
  };

  return (
    <Select
      value={value?.id}
      onChange={(e) => {
        const target = contracts.find((obj) => obj.id === e);
        setValue(target);
      }}
    >
      {contracts.map((contract) => (
        <Select.Option key={contract.id} value={contract.id}>
          {contract.get("User").get("name")} {" ("}{" "}
          {dayjs(contract.get("Start")).format("DD.MM.YYYY")} {" - "}{" "}
          {dayjs(contract.get("End")).format("DD.MM.YYYY")} {")"}
        </Select.Option>
      ))}
    </Select>
  );
};
