import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import type { CollapseProps } from "antd";
import { Collapse, Card, Tag } from "antd";

interface CustomerResultProps {
  selectedYear: number;
  selectedMonth: number;
  currentUser: Parse.User;
}

export const CustomerResult: React.FC<CustomerResultProps> = ({
  selectedYear,
  selectedMonth,
  currentUser,
}) => {
  const [collapseItems, setCollapseItems] = React.useState<
    CollapseProps["items"]
  >([]);
  const [customerTrackingData, setCustomerTrackingData] = React.useState<any>(
    []
  );

  React.useEffect(() => {
    getCustomerTimeTrackingDataForUser();
  }, [selectedYear, selectedMonth, currentUser]);

  const getCustomerTimeTrackingDataForUser = async () => {
    try {
      const query = new Parse.Query("OD3_Customer_TimeTrack").limit(99999999);
      query.include("Customer");
      query.include("TimeTracking");
      const result = await query.find();
      setCustomerTrackingData(result);
      getTimeTrackingDataForUser(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der TimeTracking-Daten:", error);
    }
  };

  const getTimeTrackingDataForUser = async (customerData: any[]) => {
    try {
      const query = new Parse.Query("OD3_TimeTracking").limit(99999999);
      query.descending("Date");

      const startDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
        .startOf("month")
        .toDate();
      const endDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
        .endOf("month")
        .toDate();

      // Filter auf den Monat und das Jahr setzen
      query.greaterThanOrEqualTo("Date", startDate);
      query.lessThan("Date", endDate);
      const result = await query.find();
      let foundObject: any = result.filter(
        (obj: any) => obj.get("User").id === currentUser?.id
      );
      if (foundObject && foundObject.length > 0) {
        const groupedResult = foundObject.reduce((acc: any, current: any) => {
          const entries = customerData.filter(
            (obj: any) => obj.get("TimeTracking").id === current.id
          );

          entries.forEach((entry: any) => {
            const customerID = entry.get("Customer").id;
            const customerName = entry.get("Customer").get("Name");

            const existingCustomer = acc.find(
              (item: any) => item.id === customerID
            );

            if (existingCustomer) {
              existingCustomer.entries.push({
                worktime: entry.get("Worktime"),
                comment: entry.get("Comment"),
                billable: entry.get("Billable"),
                invoiced: entry.get("Invoiced"),
              });
            } else {
              acc.push({
                id: customerID,
                customer: customerName,
                entries: [
                  {
                    worktime: entry.get("Worktime"),
                    comment: entry.get("Comment"),
                    billable: entry.get("Billable"),
                    invoiced: entry.get("Invoiced"),
                  },
                ],
              });
            }
          });

          return acc;
        }, []);

        let index = 1;
        let items: CollapseProps["items"] = [];
        groupedResult.forEach((element: any) => {
          console.log(element);
          const customerName = element.customer;
          let children = [];
          let gesamt = 0;
          let abrechenbar = 0;
          element.entries.forEach((entry: any) => {
            const calcHoursADay = entry.worktime;
            const hours = Math.floor(calcHoursADay / 60);
            const minutes = calcHoursADay % 60;
            const formattedWorktime = `${hours}:${minutes
              .toString()
              .padStart(2, "0")} Stunden`;
            if (entry.billable) {
              abrechenbar += parseFloat(entry.worktime);
            }
            gesamt += parseFloat(entry.worktime);
            children.push(
              <p>
                <Card bordered={true} style={{}} bodyStyle={{ padding: 5 }}>
                  <Card.Meta
                    title={
                      <>
                        {formattedWorktime}{" "}
                        <Tag color={entry.billable ? "#7fbb47" : "red"}>
                          {entry.billable ? "Abrechenbar" : "Nicht abrechenbar"}
                        </Tag>
                        {entry.invoiced && (
                          <Tag color="#4385c2">Abgerechnet</Tag>
                        )}
                      </>
                    }
                    description={entry.comment}
                  />
                </Card>
              </p>
            );
          });
          const calcHoursADay = abrechenbar;
          const hours = Math.floor(calcHoursADay / 60);
          const minutes = calcHoursADay % 60;
          const formattedWorktime = `${hours}:${minutes
            .toString()
            .padStart(2, "0")} Stunden`;

          const calcHoursADayGesamt = gesamt;
          const hoursGesamt = Math.floor(calcHoursADayGesamt / 60);
          const minutesGesamt = calcHoursADayGesamt % 60;
          const formattedWorktimeGesamt = `${hoursGesamt}:${minutesGesamt
            .toString()
            .padStart(2, "0")} Stunden`;

          let label = (
            <>
              {customerName}
              <br />
              <Tag color="gray">{"Gesamt: " + formattedWorktimeGesamt}</Tag>
              {calcHoursADay > 0 && (
                <Tag color="#7fbb47">{"Abrechenbar: " + formattedWorktime}</Tag>
              )}
            </>
          );

          items.push({
            key: index + "",
            label: label,
            children: children,
          });

          index++;
        });
        /*
              
            
          */

        setCollapseItems(items);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der TimeTracking-Daten:", error);
    }
  };

  return (
    <>
      <Collapse
        items={collapseItems}
        defaultActiveKey={Array.from({ length: 10 }, (_, i) =>
          (i + 1).toString()
        )}
      />
    </>
  );
};
