import React from "react";
import dayjs from "dayjs";
import { Alert, Space } from "antd";

interface AlertViewProps {
  contract: boolean;
  isMobile: boolean;
  isOvertime: boolean;
  totalWorked: any;
  shouldWorked: any;
  totalProjectWorked: any;
  totalCustomerWorked: any;
  maxProjectWorked: any;
  selectedMonth: any;
  selectedYear: any;
  daysEntered: any;
  anzahlWerktagen: any;
}

export const AlertView: React.FC<AlertViewProps> = ({
  contract,
  isMobile,
  isOvertime,
  totalWorked,
  shouldWorked,
  totalProjectWorked,
  totalCustomerWorked,
  maxProjectWorked,
  selectedMonth,
  selectedYear,
  daysEntered,
  anzahlWerktagen,
}) => {
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {contract && isMobile && (
        <Alert
          style={{ textAlign: "center" }}
          message={
            "Gesamt: " +
            totalWorked +
            "/" +
            shouldWorked +
            " Std. (Projekt: " +
            Math.round(totalProjectWorked * 100) / 100 +
            " Std. | Kunde: " +
            Math.round(totalCustomerWorked * 100) / 100 +
            " Std.)"
          }
          type={
            totalProjectWorked > maxProjectWorked
              ? "error"
              : totalWorked > shouldWorked
              ? "error"
              : "success"
          }
        />
      )}
      {contract && totalWorked == 0 && (
        <Alert
          style={{ textAlign: "center" }}
          message="Es wurden diesen Monat noch keine Einträge gemacht."
          type="info"
        />
      )}
      {contract &&
        (dayjs().month() !== selectedMonth ||
          dayjs().year() !== selectedYear) &&
        anzahlWerktagen(selectedMonth + 1, selectedYear) > daysEntered &&
        totalWorked > 0 && (
          <Alert
            style={{ textAlign: "center" }}
            message="Monat noch nicht vollständig. Bitte tragen Sie die restlichen Tage ein."
            type="info"
          />
        )}
      {contract && totalWorked > shouldWorked && !isOvertime && (
        <Alert
          style={{ textAlign: "center" }}
          message="Es wurden zuviele Stunden aufgeschrieben, bitte über Gleitzeit zeitnah ausgleichen!"
          type="error"
        />
      )}
      {contract && totalProjectWorked > maxProjectWorked && !isOvertime && (
        <Alert
          style={{ textAlign: "center" }}
          message={
            "Es wurden mehr Projektstunden eingetragen als diesen Monat geplant sind. Bitte über Gleitzeit zeitnah ausgleichen (Max: " +
            maxProjectWorked +
            " Stunden)."
          }
          type="error"
        />
      )}
      {!contract && (
        <Alert
          style={{ textAlign: "center" }}
          message={
            "Es ist kein Vertrag für diesen Monat vorhanden! Es können keine Stunden eingetragen werden!"
          }
          type="error"
        />
      )}
    </Space>
  );
};
