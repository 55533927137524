import React from "react";
import { Select } from "antd";
import { CustomParseFieldProps } from "@opendash/plugin-parse";

export const SelectPosition = (
  props: CustomParseFieldProps & {
    nameField?: string;
    descriptionField?: string;
  }
) => {
  const { value, setValue, fieldname, parseObject } = props;

  return (
    <Select
      value={value}
      onChange={(e) => {
        setValue(e);
      }}
    >
      <Select.Option key={1} value={"0813"}>
        0813 Material
      </Select.Option>
      <Select.Option key={2} value={"0823"}>
        0823 FE-Fremdleistung
      </Select.Option>
      <Select.Option key={3} value={"0847"}>
        0847 Abschreibungen
      </Select.Option>
    </Select>
  );
};
