import React from "react";
import { Icon } from "@opendash/icons";
import {
  Row,
  Select,
  Col,
  Modal,
  Input,
  Tooltip,
  Descriptions,
  Checkbox,
  Popconfirm,
  Statistic,
} from "antd";

interface EntryModalProps {
  props: any;
  currentUser: any;
  modalTitle: string;
  modalVisible: boolean;
  vacationChecked: any;
  handleVacationChange: any;
  hoursWorked: any;
  handlePercentageChange: any;
  percentage: any;
  handleCommentChange: any;
  comment: any;
  sickLeaveChecked: any;
  handleSickLeaveChange: any;
  informationText: string;
  handleModalOk: any;
  handleModalCancel: any;
  getOwnList: any;
  checkDeleteStatus: any;
  deleteCurrentEntry: any;
  setModalVisible: any;
  getAllList: any;
  setHoursWorked: any;
}

export const EntryModal: React.FC<EntryModalProps> = ({
  props,
  currentUser,
  modalTitle,
  modalVisible,
  vacationChecked,
  handleVacationChange,
  hoursWorked,
  handlePercentageChange,
  percentage,
  handleCommentChange,
  comment,
  sickLeaveChecked,
  handleSickLeaveChange,
  informationText,
  handleModalOk,
  handleModalCancel,
  getOwnList,
  checkDeleteStatus,
  deleteCurrentEntry,
  setModalVisible,
  getAllList,
  setHoursWorked,
}) => {
  return (
    <Modal
      title={modalTitle}
      open={modalVisible}
      onOk={!getOwnList ? handleModalOk : () => {}}
      okText="Speichern"
      okButtonProps={{
        style: { display: !getOwnList ? "" : "none" },
      }}
      onCancel={handleModalCancel}
    >
      {getOwnList && (
        <div
          style={{
            marginBottom: 20,
            padding: 16,
            backgroundColor: "#f9f9f9",
            borderRadius: 4,
            border: "1px solid #e8e8e8",
            fontSize: 12,
            color: "#333",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginBottom: 8 }}>
            {!getOwnList.get("Sick") && !getOwnList.get("Vacation") && (
              <Statistic
                decimalSeparator=","
                groupSeparator="."
                title={getOwnList.get("User").get("name")}
                value={getOwnList.get("WorkingHours") + "  Stunden"}
                precision={2}
              />
            )}
            {getOwnList.get("Sick") && (
              <Statistic
                decimalSeparator=","
                groupSeparator="."
                title={getOwnList.get("User").get("name")}
                value={"Krankheit"}
                precision={2}
              />
            )}
            {getOwnList.get("Vacation") && (
              <Statistic
                decimalSeparator=","
                groupSeparator="."
                title={getOwnList.get("User").get("name")}
                value={"Urlaub"}
                precision={2}
              />
            )}
            {getOwnList.get("Comment") && (
              <p>Kommentar: {getOwnList.get("Comment")}</p>
            )}
          </div>
          {checkDeleteStatus(getOwnList) && (
            <Popconfirm
              title="Wirklich löschen?"
              onConfirm={(e) => {
                deleteCurrentEntry(getOwnList);
                setModalVisible(false);
              }}
              onCancel={(e) => {}}
              okText="Löschen"
              cancelText="Abbrechen"
            >
              <div
                style={{ marginLeft: 8, color: "red", fontSize: "24px" }}
                onClick={() => {}}
              >
                <Icon icon="fa:trash" />
              </div>
            </Popconfirm>
          )}
        </div>
      )}
      {getAllList.length > 0 &&
        getAllList.map((element: any, index: number) => (
          <div
            key={index}
            style={{
              marginBottom: 20,
              padding: 16,
              backgroundColor: "#fefefe",
              borderRadius: 4,
              border: "1px dashed #a1a1a1",
              fontSize: 12,
              color: "#333",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ marginBottom: 8 }}>
              {!element.get("Sick") && !element.get("Vacation") && (
                <Statistic
                  decimalSeparator=","
                  groupSeparator="."
                  title={element.get("User")?.get("name")}
                  value={element.get("WorkingHours") + "  Stunden"}
                  precision={2}
                />
              )}
              {element.get("Sick") && (
                <Statistic
                  decimalSeparator=","
                  groupSeparator="."
                  title={element.get("User")?.get("name")}
                  value={"Krankheit"}
                  precision={2}
                />
              )}
              {element.get("Vacation") && (
                <Statistic
                  decimalSeparator=","
                  groupSeparator="."
                  title={element.get("User")?.get("name")}
                  value={"Urlaub"}
                  precision={2}
                />
              )}
              {element.get("Comment") && (
                <p>Kommentar: {element.get("Comment")}</p>
              )}
            </p>
            {checkDeleteStatus(element) && (
              <Popconfirm
                title="Wirklich löschen?"
                onConfirm={(e) => {
                  deleteCurrentEntry(element);
                  setModalVisible(false);
                }}
                onCancel={(e) => {}}
                okText="Löschen"
                cancelText="Abbrechen"
              >
                <div
                  style={{
                    marginLeft: 8,
                    color: "red",
                    fontSize: "24px",
                  }}
                  onClick={() => {}}
                >
                  <Icon icon="fa:trash" />
                </div>
              </Popconfirm>
            )}
          </div>
        ))}
      {!getOwnList && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Descriptions column={1} bordered>
              <Descriptions.Item label="Arbeitszeit">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    value={hoursWorked}
                    onChange={(e) => {
                      try {
                        let value = e.target.value;

                        setHoursWorked(value);
                      } catch (e) {
                        console.error(e);
                      }
                    }}
                    style={{ width: "25%" }}
                  />
                  <span style={{ marginLeft: 8, marginTop: "16px" }}>
                    <p>Stunden</p>
                  </span>
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="Kommentar">
                <Input.TextArea
                  value={comment}
                  onChange={handleCommentChange}
                  placeholder="Kommentar"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  style={{ width: "100%" }}
                />
              </Descriptions.Item>
              {props.researchprojectPlugin && (
                <Descriptions.Item label="Projektanteil">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Select
                      style={{ flex: 1 }}
                      value={percentage}
                      onChange={handlePercentageChange}
                    >
                      {[...Array(11).keys()].map((value) => (
                        <Select.Option key={value * 10} value={value * 10}>
                          {value * 10}%
                        </Select.Option>
                      ))}
                    </Select>
                    <Tooltip title="Der Anteil der Arbeitszeit der auf einem Projekt gearbeitet wurde. Bitte geben Sie 0% an, wenn die ganztägig auf Dienstreise für einen Kunden waren oder ganztätig für einen Kunden tätig waren.">
                      <span style={{ marginLeft: 8 }}>
                        <Icon icon="fa:info" />
                      </span>
                    </Tooltip>
                  </div>
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Sonstiges">
                {currentUser.get("VacationWithoutApplication") && (
                  <Checkbox
                    style={{ width: "100%" }}
                    checked={vacationChecked}
                    onChange={handleVacationChange}
                  >
                    Urlaub
                  </Checkbox>
                )}
                <Checkbox
                  style={{ width: "100%" }}
                  checked={sickLeaveChecked}
                  onChange={handleSickLeaveChange}
                >
                  Krankheit
                </Checkbox>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      )}{" "}
      {!getOwnList && (
        <div>
          <p
            style={{
              marginBottom: 8,
              marginTop: 20,
              padding: 16,
              textAlign: "justify",
              backgroundColor: "rgba(0, 0, 0, 0.02)",
              borderRadius: 4,
              border: "1px solid #e8e8e8",
              fontSize: 11,
              color: "#333",
            }}
          >
            {informationText}
          </p>
        </div>
      )}
    </Modal>
  );
};
