import React from "react";
import Parse from "parse";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { CustomParseFieldProps } from "@opendash/plugin-parse";

const dateFormat = "DD.MM.YYYY";

export const SelectDate = (
  props: CustomParseFieldProps & {
    nameField?: string;
    descriptionField?: string;
  }
) => {
  const { value, setValue, fieldname, parseObject } = props;
  const [contracts, setContracts] = React.useState<Parse.Object[]>([]);

  React.useEffect(() => {}, []);

  return (
    <DatePicker
      style={{ width: "100%" }}
      mode="date"
      format={dateFormat}
      onChange={(e) => {
        setValue(e.toDate());
      }}
    />
  );
};
