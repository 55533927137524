import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { $parse } from "@opendash/plugin-parse";
import { observer } from "mobx-react-lite";
import { AdminToolbar } from "@opendash/ui";
import { AdminLayout } from "@opendash/core";
import { Options } from "../Plugin";
import { Space, Col, Row, Statistic, Button, Spin } from "antd";
import { LicenseTable } from "./Pieces/LicenseTable";
import { LicenseModal } from "./Pieces/LicenseModal";
import { LicenseEditModal } from "./Pieces/LicenseEditModal";

export const CustomerLicenseComponent = observer((props: Partial<Options>) => {
  const [modalVisible, setModalVisibility] = React.useState<boolean>(false);
  const [editModalVisible, setEditModalVisibility] =
    React.useState<boolean>(false);
  const [editModalData, setEditModalData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [licenseData, setLicenseData] = React.useState<any>([]);
  const [documentData, setDocumentData] = React.useState<any>([]);
  const [productData, setProductData] = React.useState<any>([]);
  const [licenseProductData, setLicenseProductData] = React.useState<any>([]);
  const [customerData, setCustomerData] = React.useState<any>([]);
  const [sumHosting, setSumHosting] = React.useState<number>(0);
  const [sumLicens, setSumLicens] = React.useState<number>(0);
  const [sumService, setSumService] = React.useState<number>(0);
  const [sumSum, setSumSum] = React.useState<number>(0);

  React.useEffect(() => {
    initLicenseData();
    initDocumentData();
    initProductData();
    initLicenseProductData();
    initCustomerData();
    setLoading(false);
  }, []);

  React.useEffect(() => {
    initLicenseData();
  }, [documentData, productData, licenseProductData]);

  React.useEffect(() => {
    initDocumentData();
    initLicenseProductData();
  }, [editModalVisible]);

  const initLicenseData = async () => {
    try {
      const query = new Parse.Query("OD3_Customer_License").limit(99999999);
      query.include("Kunde");
      const result = await query.find();
      setLicenseData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-Lizenz-Daten:", error);
    }
  };

  const initDocumentData = async () => {
    try {
      const query = new Parse.Query("OD3_Customer_Documents").limit(99999999);
      query.include("License");
      const result = await query.find();
      setDocumentData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-Dokument-Daten:", error);
    }
  };

  const initProductData = async () => {
    try {
      const query = new Parse.Query("OD3_Products").limit(99999999);
      const result = await query.find();
      setProductData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-Produkt-Daten:", error);
    }
  };

  const initLicenseProductData = async () => {
    try {
      const query = new Parse.Query("OD3_Customer_Products").limit(99999999);
      query.include("License");
      query.include("Product");
      const result = await query.find();
      setLicenseProductData(result);
      calculateSums(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-Produkt-Daten:", error);
    }
  };

  const initCustomerData = async () => {
    try {
      const query = new Parse.Query("OD3_Customer").limit(99999999);
      const result = await query.find();
      setCustomerData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-Kunden-Daten:", error);
    }
  };

  const calculateAnnualSum = (element: any) => {
    const Price = element.get("Price");
    const Interval = element.get("Interval");
    const Discount = element.get("Discount");

    switch (Interval) {
      case "Jährlich":
        return Price - (Price * Discount) / 100;
      case "Monatlich":
        return Price * 12 - (Price * Discount) / 100;
      case "Quartalsweise":
        return Price * 4 - (Price * Discount) / 100;
      case "Halbjährlich":
        return Price * 2 - (Price * Discount) / 100;
      default:
        return 0;
    }
  };

  const calculateSums = (data: any) => {
    let variableSumHosting = 0;
    let variableSumLicens = 0;
    let variableSumService = 0;
    let variableSumSum = 0;
    data.forEach((element: any) => {
      if (element.get("Product").get("Typ") === "Softwarelizenz") {
        variableSumLicens += calculateAnnualSum(element);
      } else if (element.get("Product").get("Typ") === "Service und Wartung") {
        variableSumService += calculateAnnualSum(element);
      } else if (element.get("Product").get("Typ") === "Hosting") {
        variableSumHosting += calculateAnnualSum(element);
      }
      variableSumSum += calculateAnnualSum(element);
    });
    setSumHosting(variableSumHosting);
    setSumLicens(variableSumLicens);
    setSumService(variableSumService);
    setSumSum(variableSumSum);
  };

  const editEntry = (data: any) => {
    setEditModalData(data);
    setEditModalVisibility(true);
  };

  const editLicense = (data: any) => {
    const objectID = editModalData.key;
    if (data.products && data.products.length > 0) {
      data.products.forEach((element: string) => {
        const customerProjectsObject = new Parse.Object(
          "OD3_Customer_Products"
        );

        customerProjectsObject.set("License", {
          __type: "Pointer",
          className: "OD3_Customer_License",
          objectId: objectID,
        });

        customerProjectsObject.set("Product", {
          __type: "Pointer",
          className: "OD3_Products",
          objectId: element,
        });

        let objectOfProductEntry = data.productsData[element];

        customerProjectsObject.set("Price", objectOfProductEntry.price);
        customerProjectsObject.set("Discount", objectOfProductEntry.discount);
        customerProjectsObject.set("Comment", objectOfProductEntry.comment);
        customerProjectsObject.set("Interval", objectOfProductEntry.interval);

        const aclEntry = new Parse.ACL();
        const currentUserACLEntry = Parse.User.current();
        if (currentUserACLEntry) {
          aclEntry.setReadAccess(currentUserACLEntry, true);
          aclEntry.setWriteAccess(currentUserACLEntry, true);
        }
        aclEntry.setRoleReadAccess(
          "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
          true
        );
        aclEntry.setRoleWriteAccess(
          "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
          true
        );
        customerProjectsObject.setACL(aclEntry);

        customerProjectsObject.save().then(() => {
          initLicenseData();
          initDocumentData();
          initLicenseProductData();
        });
      });
    }

    if (data.uploads && data.uploads.length > 0) {
      data.uploads.forEach((element: any) => {
        const customerDocumentsObject = new Parse.Object(
          "OD3_Customer_Documents"
        );

        customerDocumentsObject.set("License", {
          __type: "Pointer",
          className: "OD3_Customer_License",
          objectId: objectID,
        });

        const file = new Parse.File(element.file.file.name, element.file.file);

        customerDocumentsObject.set("Label", element.label);
        customerDocumentsObject.set("Description", element.description);
        customerDocumentsObject.set("File", file);

        const aclEntry = new Parse.ACL();
        const currentUserACLEntry = Parse.User.current();
        if (currentUserACLEntry) {
          aclEntry.setReadAccess(currentUserACLEntry, true);
          aclEntry.setWriteAccess(currentUserACLEntry, true);
        }
        aclEntry.setRoleReadAccess(
          "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
          true
        );
        aclEntry.setRoleWriteAccess(
          "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
          true
        );
        customerDocumentsObject.setACL(aclEntry);

        customerDocumentsObject.save().then(() => {
          initLicenseData();
          initDocumentData();
          initLicenseProductData();
        });
      });
    }
  };

  const addLicense = (data: any) => {
    const licenseObject = new Parse.Object("OD3_Customer_License");

    licenseObject.set("Kunde", {
      __type: "Pointer",
      className: "OD3_Customer",
      objectId: data.customerId,
    });

    const acl = new Parse.ACL();
    const currentUserACL = Parse.User.current();
    if (currentUserACL) {
      acl.setReadAccess(currentUserACL, true);
      acl.setWriteAccess(currentUserACL, true);
    }
    acl.setRoleReadAccess(
      "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
      true
    );
    acl.setRoleWriteAccess(
      "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
      true
    );
    licenseObject.setACL(acl);

    licenseObject
      .save()
      .then((object) => {
        data.products.forEach((element: string) => {
          const customerProjectsObject = new Parse.Object(
            "OD3_Customer_Products"
          );

          customerProjectsObject.set("License", {
            __type: "Pointer",
            className: "OD3_Customer_License",
            objectId: object.id,
          });

          customerProjectsObject.set("Product", {
            __type: "Pointer",
            className: "OD3_Products",
            objectId: element,
          });

          let objectOfProductEntry = data.productsData[element];

          customerProjectsObject.set("Price", objectOfProductEntry.price);
          customerProjectsObject.set("Discount", objectOfProductEntry.discount);
          customerProjectsObject.set("Comment", objectOfProductEntry.comment);
          customerProjectsObject.set("Interval", objectOfProductEntry.interval);

          const aclEntry = new Parse.ACL();
          const currentUserACLEntry = Parse.User.current();
          if (currentUserACLEntry) {
            aclEntry.setReadAccess(currentUserACLEntry, true);
            aclEntry.setWriteAccess(currentUserACLEntry, true);
          }
          aclEntry.setRoleReadAccess(
            "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
            true
          );
          aclEntry.setRoleWriteAccess(
            "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
            true
          );
          customerProjectsObject.setACL(aclEntry);

          customerProjectsObject.save().then(() => {
            initLicenseData();
            initDocumentData();
            initLicenseProductData();
          });
        });

        data.uploads.forEach((element: any) => {
          const customerDocumentsObject = new Parse.Object(
            "OD3_Customer_Documents"
          );

          customerDocumentsObject.set("License", {
            __type: "Pointer",
            className: "OD3_Customer_License",
            objectId: object.id,
          });

          const file = new Parse.File(
            element.file.file.name,
            element.file.file
          );

          customerDocumentsObject.set("Label", element.label);
          customerDocumentsObject.set("Description", element.description);
          customerDocumentsObject.set("File", file);

          const aclEntry = new Parse.ACL();
          const currentUserACLEntry = Parse.User.current();
          if (currentUserACLEntry) {
            aclEntry.setReadAccess(currentUserACLEntry, true);
            aclEntry.setWriteAccess(currentUserACLEntry, true);
          }
          aclEntry.setRoleReadAccess(
            "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
            true
          );
          aclEntry.setRoleWriteAccess(
            "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
            true
          );
          customerDocumentsObject.setACL(aclEntry);

          customerDocumentsObject.save().then(() => {
            initLicenseData();
            initDocumentData();
            initLicenseProductData();
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });

    return true;
  };

  const deleteLicense = async (form: any) => {
    try {
      // 1. Hol das License-Objekt
      const LicenseClass = Parse.Object.extend("OD3_Customer_License");
      const licenseToDelete = await new LicenseClass()
        .set("objectId", form.key)
        .fetch();

      if (!licenseToDelete) {
        throw new Error("Lizenz nicht gefunden.");
      }

      // 2. Dokumente löschen
      const DocumentsClass = Parse.Object.extend("OD3_Customer_Documents");
      const documentsQuery = new Parse.Query(DocumentsClass);
      documentsQuery.equalTo("License", licenseToDelete);

      const documentsToDelete = await documentsQuery.find();

      await Parse.Object.destroyAll(documentsToDelete);

      // 3. Produkte löschen
      const ProductsClass = Parse.Object.extend("OD3_Customer_Products");
      const productsQuery = new Parse.Query(ProductsClass);
      productsQuery.equalTo("License", licenseToDelete);

      const productsToDelete = await productsQuery.find();

      await Parse.Object.destroyAll(productsToDelete);

      // 4. Lizenz löschen
      await licenseToDelete.destroy().then(() => {
        initLicenseData();
        initDocumentData();
        initLicenseProductData();
      });
    } catch (error) {
      console.error(
        "Fehler beim Löschen der Lizenz oder der zugehörigen Objekte:",
        error
      );
    }
  };

  return (
    <AdminLayout>
      <AdminToolbar
        title="Kundenlizenzen und -verträge"
        description="Aktuelle Verträge mit Kunden und Partnern (Statistik auf Jahresbasis)"
        search={""}
        actions={[
          <Button
            key="1"
            onClick={() => {
              setModalVisibility(true);
            }}
          >
            Neue Lizenz
          </Button>,
        ]}
      />

      {loading && (
        <div
          className="od-page-main"
          style={{
            width: "100%",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      )}
      {!loading && (
        <div
          className="od-page-main"
          style={{
            width: "100%",
          }}
        >
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Row gutter={24} style={{ textAlign: "center" }}>
              <Col xs={24} sm={6}>
                <Statistic
                  decimalSeparator=","
                  groupSeparator="."
                  style={{ textAlign: "center" }}
                  valueStyle={{
                    textAlign: "center",
                  }}
                  title="Softwarelizenzen"
                  value={sumLicens}
                  precision={2}
                  suffix="€"
                />
              </Col>
              <Col xs={24} sm={6}>
                <Statistic
                  decimalSeparator=","
                  groupSeparator="."
                  style={{ textAlign: "center" }}
                  valueStyle={{ textAlign: "center" }}
                  title={"Service und Wartung"}
                  value={sumService}
                  precision={2}
                  suffix="€"
                />
              </Col>
              <Col xs={24} sm={6}>
                <Statistic
                  style={{
                    textAlign: "center",
                  }}
                  valueStyle={{
                    textAlign: "center",
                  }}
                  title="Hosting"
                  value={sumHosting}
                  precision={2}
                  suffix="€"
                  decimalSeparator=","
                  groupSeparator="."
                />
              </Col>
              <Col xs={24} sm={6}>
                <Statistic
                  style={{
                    textAlign: "center",
                  }}
                  valueStyle={{
                    textAlign: "center",
                    color: "#87d068",
                  }}
                  title="Gesamt"
                  value={sumSum}
                  precision={2}
                  suffix="€"
                  decimalSeparator=","
                  groupSeparator="."
                />
              </Col>
            </Row>

            <div style={{ paddingTop: "40px", paddingBottom: "20px" }}>
              <LicenseTable
                data={licenseData}
                documentData={documentData}
                productData={productData}
                licenseProductData={licenseProductData}
                deleteForm={deleteLicense}
                editEntry={editEntry}
              />
            </div>
          </Space>
        </div>
      )}
      <LicenseModal
        modal={modalVisible}
        setModalVisibility={setModalVisibility}
        customerData={customerData}
        productData={productData}
        addLicense={addLicense}
      />
      <LicenseEditModal
        modal={editModalVisible}
        setModalVisibility={setEditModalVisibility}
        editLicense={editLicense}
        productData={productData}
        editData={editModalData}
      />
    </AdminLayout>
  );
});
