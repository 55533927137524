import React from "react";
import dayjs from "dayjs";

interface Event {
  endDate: Date;
  name: string;
}

interface TimelineProps {
  start: Date;
  end: Date;
  today: Date;
  events: Event[];
}

export const MultiTimeline: React.FC<TimelineProps> = ({
  start,
  end,
  today,
  events,
}) => {
  React.useEffect(() => {
    initTimeLine();
  }, [start, end, today]);

  const initTimeLine = () => {
    const startDate: any = start;
    const endDate: any = end;

    const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
    const daysPassed = Math.ceil(
      ((today as any) - startDate) / (1000 * 60 * 60 * 24)
    );
  };

  const calculateEventPosition = (eventDate: Date) => {
    const startDate: any = start;
    const endDate: any = end;
    const eventDays = Math.ceil(
      (eventDate.getTime() - startDate) / (1000 * 60 * 60 * 24)
    );
    const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

    if (eventDays < 0 || (eventDays / totalDays) * 100 < 1) {
      return "0%";
    }
    return `${(eventDays / totalDays) * 100}%`;
  };

  const calculateEventDiff = (eventDate: Date) => {
    const startDate: any = start;
    const diffYears = startDate.getFullYear() - eventDate.getFullYear();
    const diffMonths = startDate.getMonth() - eventDate.getMonth();

    // Berechne die Anzahl der Monate
    const totalMonths = diffYears * 12 + diffMonths;
    return totalMonths * -1;
  };

  let topPosition = ["15%", "27%", "39%"];

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "400px",
        overflow: "hidden",
      }}
    >
      {/* Startdatum */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "0%",
          transform: "translateY(-50%)",
          width: "5px",
          height: "25px",
          backgroundColor: "#aaa",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "70%",
          left: "0%",
          transform: "translateX(0%)",
          textAlign: "left",
        }}
      >
        <br />
        <span>{dayjs(start).format("DD.MM.YYYY")}</span>
      </div>

      {/* Enddatum */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "100%",
          transform: "translateX(-100%) translateY(-50%)",
          width: "5px",
          height: "25px",
          backgroundColor: "#aaa",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "70%",
          left: "100%",
          transform: "translateX(-100%)",
          textAlign: "right",
        }}
      >
        <br />
        <span>{dayjs(end).format("DD.MM.YYYY")}</span>
      </div>

      {/* Zeitstrahl */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "0",
          transform: "translateY(-50%)",
          width: "100%",
          height: "3px",
          backgroundColor: "#aaa",
        }}
      ></div>

      {/* Events */}
      {events.map((event, index) => {
        const eventPosition = calculateEventPosition(event.endDate);
        const eventDiff = calculateEventDiff(event.endDate);

        return (
          <div key={index}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: eventPosition,
                transform: "translate(-50%, -50%)",
                width: "4px",
                height: "20px",
                backgroundColor: "#aaa",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                top: topPosition[index % 3],
                left: eventPosition,
                transform:
                  eventPosition === "0%" ? "" : "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              <span>
                {event.name} <br />
                <div
                  style={{
                    fontSize: "12px",
                    color:
                      eventDiff > 18
                        ? "#31ad4a"
                        : eventDiff < 12
                        ? "#eb4034"
                        : "#bfb237",
                  }}
                >
                  (
                  {eventPosition == "0%" ? "Abgelaufen" : eventDiff + " Monate"}
                  )
                </div>
              </span>
            </div>
          </div>
        );
      })}

      <div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: -2,
            transform: "translate(-50%, -50%)",
            width: "4px",
            height: "30px",
            backgroundColor: "#2db7f5",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: "70%",
            right: -30,
            transform: "translate(-50%, -50%)",
            textAlign: "right",
          }}
        >
          <span>
            <b>+3 Jahre</b>
          </span>
        </div>
      </div>

      {/* Aktueller Tag */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: 2,
          transform: "translate(-50%, -50%)",
          width: "4px",
          height: "30px",
          backgroundColor: "#2db7f5",
        }}
      ></div>

      {/* Heutiges Datum */}
      <div
        style={{
          position: "absolute",
          top: "70%",
          left: 20,
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <span>
          <b>Heute</b>
        </span>
      </div>
    </div>
  );
};
