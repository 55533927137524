import React from "react";
import dayjs from "dayjs";
import { DataTypePersonal } from "../Types/DataTypePersonal";
import { Table } from "antd";
import type { TableProps } from "antd";

interface PersonalProps {
  dataSource: DataTypePersonal[];
  currentQuarter: String[];
}

export const ProjectPersonal: React.FC<PersonalProps> = ({
  dataSource,
  currentQuarter,
}) => {
  let columns: TableProps<DataTypePersonal>["columns"] = [
    {
      title: "",
      dataIndex: "name",
      key: "name" + Math.random(),
      render: (text) => <b>{text}</b>,
    },
    {
      title: "Jahresstunden",
      align: "center",
      dataIndex: "yearhours",
      key: "yearhours",
    },
    {
      title: "Jahreskosten",
      align: "center",
      dataIndex: "yearcosts",
      key: "yearcosts",
    },
    {
      title: "Monat1",
      align: "center",
      dataIndex: "month1",
      key: "month1",
    },
    {
      title: "Monat2",
      align: "center",
      dataIndex: "month2",
      key: "month2",
    },
    {
      title: "Monat3",
      align: "center",
      dataIndex: "month3",
      key: "month3",
    },
    {
      title: "Gesamtstunden",
      align: "center",
      dataIndex: "sum",
      key: "sum",
    },
    {
      title: "Kostensatz",
      align: "center",
      dataIndex: "costRate",
      key: "costRate",
    },
    {
      title: "Quartalskosten",
      align: "center",
      dataIndex: "costsInQuarter",
      key: "costsInQuarter",
    },
    {
      title: "Quartalskosten mit Overhead",
      align: "center",
      dataIndex: "costsInQuarterWithOverhead",
      key: "costsInQuarterWithOverhead",
    },
  ];

  const [tableColumns, setTableColumns] =
    React.useState<TableProps<DataTypePersonal>["columns"]>(columns);

  React.useEffect(() => {
    if (currentQuarter) {
      columns[3].title =
        currentQuarter.length > 2
          ? dayjs(currentQuarter[0] as any, "MM.YYYY").format("MMMM YYYY")
          : "Monat 1";
      columns[4].title =
        currentQuarter.length > 1
          ? dayjs(currentQuarter[1] as any, "MM.YYYY").format("MMMM YYYY")
          : "Monat 2";
      columns[5].title =
        currentQuarter.length > 2
          ? dayjs(currentQuarter[2] as any, "MM.YYYY").format("MMMM YYYY")
          : "Monat 3";
    }
    setTableColumns(columns);
  }, [currentQuarter]);

  return (
    <Table
      size="small"
      columns={tableColumns}
      dataSource={dataSource}
      pagination={false}
    />
  );
};
