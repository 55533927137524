import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { ColumnProps } from "antd/lib/table";

export const HolidayComponent = createComponent(function HolidayComponent() {
  return (
    <AdminLayout>
      <ParseSetView
        className="OD3_TimeTrackingBlocked"
        view={
          {
            type: "table",
            customCols: [
              {
                title: "Datum",
                key: "Datums",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    <div>{dayjs(row?.get("Date")).format("DD.MM.YYYY")}</div>
                  );
                },
              } as ColumnProps<any>,
            ],
            fields: ["Reason"],
          } as TableViewType
        }
      />
    </AdminLayout>
  );
});
