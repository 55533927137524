import React from "react";
import dayjs from "dayjs";
import { DataTypeTrip } from "../Types/DataTypeTrip";
import { Table } from "antd";
import type { TableProps } from "antd";

interface TripProps {
  dataSource: DataTypeTrip[];
}

export const ProjectTrip: React.FC<TripProps> = ({ dataSource }) => {
  let columns: TableProps<DataTypeTrip>["columns"] = [
    {
      title: "Start",
      align: "center",
      dataIndex: "start",
      key: "start",
      render: (text) => <div>{dayjs(text).format("DD.MM.YYYY")}</div>,
    },
    {
      title: "Ende",
      align: "center",
      dataIndex: "end",
      key: "end",
      render: (text) => <div>{dayjs(text).format("DD.MM.YYYY")}</div>,
    },
    {
      title: "Mitarbeiter",
      align: "center",
      dataIndex: "employee",
      key: "employee",
    },
    {
      title: "Beschreibung",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Kosten",
      align: "center",
      dataIndex: "costs",
      key: "costs",
      render: (text) => <div>{text} €</div>,
    },
    {
      title: "Belege",
      align: "center",
      dataIndex: "documents",
      key: "documents",
      render: (text) => (
        <div>
          <a href={text} target="_blank" rel="noopener noreferrer">
            Download
          </a>
        </div>
      ),
    },
  ];

  const [tableColumns, setTableColumns] =
    React.useState<TableProps<DataTypeTrip>["columns"]>(columns);

  React.useEffect(() => {
    setTableColumns(columns);
  }, []);

  return (
    <Table
      size="small"
      columns={tableColumns}
      dataSource={dataSource}
      pagination={false}
    />
  );
};
