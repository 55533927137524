import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { $parse } from "@opendash/plugin-parse";
import { observer } from "mobx-react-lite";
import { AdminToolbar } from "@opendash/ui";
import { Options } from "../Plugin";
import { Space, Col, Row, Statistic, Button, Spin, Select, Alert } from "antd";
import { VacationTable } from "./Pieces/VacationTable";
import { VacationModal } from "./Pieces/VacationModal";

export const VacationComponent = observer((props: Partial<Options>) => {
  const [modalVisible, setModalVisibility] = React.useState<boolean>(false);
  const [parseDataForUser, setParseDataForUser] =
    React.useState<any>(undefined);
  const [parseDataForThisYear, setParseDataForThisYear] =
    React.useState<any>(undefined);
  const [timeTrackingDataForUser, setTimeTrackingDataForUser] =
    React.useState<any>([]);
  const [usedVacationDays, setUsedVacationDays] = React.useState<number>(0);
  const [freeDays, setFreeDays] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [userData, setUserData] = React.useState<any>([]);
  const [userMeta, setUserMeta] = React.useState<any>(undefined);
  const [totalVacationDays, setTotalVacationDays] = React.useState<number>(0);
  const [currentUser, setCurrentUser] = React.useState<any>(
    Parse.User.current()
  );

  React.useEffect(() => {
    initUserData();
    initUserMeta();
    getFreeDays();
    getTimeTrackingDataForUser();
    getParseDataForUser();
  }, []);

  React.useEffect(() => {
    if (parseDataForUser) {
      calcUsedVacation();
    }
  }, [parseDataForUser]);

  React.useEffect(() => {
    initUserData();
    initUserMeta();
    getFreeDays();
    getTimeTrackingDataForUser();
    getParseDataForUser();
  }, [currentUser]);

  const initUserData = async () => {
    try {
      const query = new Parse.Query("_User").limit(99999999);
      query.equalTo("ignoreInSelection", false);
      query.equalTo("VacationWithoutApplication", false);
      const result = await query.find();
      setUserData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-User-Daten:", error);
    }
  };

  const initUserMeta = () => {
    const query = new Parse.Query("OD3_Contract").limit(99999999);
    query.equalTo("User", currentUser);
    query.find().then((results: any) => {
      const filteredObjects = results.find((obj) => {
        const startDate = obj.get("Start");
        const endDate = obj.get("End");
        const today = new Date();
        return (
          (today >= startDate && today <= endDate) ||
          today.toDateString() === startDate.toDateString() ||
          today.toDateString() === endDate.toDateString()
        );
      });
      setUserMeta(filteredObjects);
    });
  };

  const initVacationDays = () => {
    const query = new Parse.Query("OD3_Contract").limit(99999999);
    query.equalTo("User", currentUser);
    query.find().then((results: any) => {
      const filteredObjects = results.filter((obj: any) => {
        const startDate = obj.get("Start");
        const endDate = obj.get("End");
        const today = new Date();
        const yearStart = new Date(today.getFullYear(), 0, 1);
        const yearStartWithOffset = new Date(
          yearStart.getTime() - 6 * 60 * 60 * 1000
        );
        const yearEnd = new Date(today.getFullYear(), 11, 31, 23, 59, 59);
        const yearEndWithOffsett = new Date(
          yearEnd.getTime() + 6 * 60 * 60 * 1000
        );

        return (
          yearStartWithOffset <= startDate && yearEndWithOffsett >= endDate
        );
      });
      let totalDays = 0;
      filteredObjects.forEach((element: any) => {
        totalDays += element.get("VacationDays");
      });

      let totalDaysAllContracts = 0;
      results.forEach((element: any) => {
        totalDaysAllContracts += element.get("VacationDays");
      });

      let totalUsedVacationDays = 0;
      if (parseDataForUser && parseDataForUser.length > 0) {
        parseDataForUser.forEach((element: any) => {
          if (element.get("Status") != 1) return;
          const currentYear = new Date().getFullYear();
          if (element.get("End").getFullYear() != currentYear)
            totalUsedVacationDays += element.get("Days");
        });
      }

      let totalLeftVacationDays = totalDaysAllContracts - totalUsedVacationDays;
      if (totalLeftVacationDays > 60) {
        totalLeftVacationDays = 60;
      }

      setTotalVacationDays(totalLeftVacationDays);
      setLoading(false);
    });
  };

  const addVacationApplication = (
    startDate: Date,
    endDate: Date,
    type: string,
    startEndDate1: dayjs.Dayjs,
    startEndDate2: dayjs.Dayjs
  ) => {
    if (!checkIfDaysAreEmpty(startDate, endDate)) {
      return false;
    } else {
      const vacationObject = new Parse.Object("OD3_TimeVacation");
      vacationObject.set(
        "Start",
        new Date(startDate.setTime(startDate.getTime() + 6 * 60 * 60 * 1000))
      );
      vacationObject.set(
        "End",
        new Date(endDate.setTime(endDate.getTime() + 6 * 60 * 60 * 1000))
      );
      vacationObject.set("Type", type);
      vacationObject.set("Status", 0);
      vacationObject.set("User", currentUser);
      vacationObject.set("Days", anzahlWerktagen(startEndDate1, startEndDate2));

      const acl = new Parse.ACL();
      const currentUserACL = currentUser;
      if (currentUserACL) {
        acl.setReadAccess(currentUserACL, true);
        acl.setWriteAccess(currentUserACL, true);
      }
      acl.setRoleReadAccess(
        "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
        true
      );
      acl.setRoleWriteAccess(
        "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
        true
      );
      vacationObject.setACL(acl);

      vacationObject
        .save()
        .then(() => {
          getParseDataForUser();
        })
        .catch((error) => {
          console.error(error);
        });
    }
    return true;
  };

  const checkIfDaysAreEmpty = (start: Date, end: Date) => {
    let foundObject = timeTrackingDataForUser.filter(
      (obj: any) =>
        dayjs(obj.get("Date"))
          .startOf("day")
          .add(12, "hour")
          .isAfter(dayjs(start).startOf("day")) &&
        dayjs(obj.get("Date"))
          .startOf("day")
          .add(12, "hour")
          .isBefore(dayjs(end).endOf("day"))
    );
    return foundObject.length === 0;
  };

  const getFreeDays = async () => {
    try {
      const query = new Parse.Query("OD3_TimeTrackingBlocked").limit(99999999);
      const result = await query.find();
      setFreeDays(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der freien Tage:", error);
    }
  };

  const anzahlWerktagen = (startDatum: dayjs.Dayjs, endDatum: dayjs.Dayjs) => {
    let werktage = 0;
    let currentDate = dayjs(startDatum);

    while (
      currentDate.isBefore(endDatum) ||
      currentDate.isSame(endDatum, "day")
    ) {
      if (currentDate.day() > 0 && currentDate.day() < 6) {
        const foundObject = freeDays.find(
          (obj: any) =>
            dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
            currentDate.format("DD.MM.YYYY")
        );
        if (!foundObject) {
          werktage++;
        }
      }
      currentDate = currentDate.add(1, "day"); // Zum nächsten Tag gehen
    }

    return werktage;
  };

  const deleteForm = async (form: any) => {
    try {
      const VacationClass = Parse.Object.extend("OD3_TimeVacation");
      let toDelete = await VacationClass.createWithoutData(form.key).fetch();
      await toDelete.destroy().then(() => {
        getParseDataForUser();
      });
    } catch (error) {
      console.error("Fehler beim Löschen des Eintrags:", error);
    }
  };

  const getTimeTrackingDataForUser = async () => {
    try {
      const query = new Parse.Query("OD3_TimeTracking").limit(99999999);
      query.descending("createdAt");

      const result = await query.find();
      let foundObject = result.filter(
        (obj: any) => obj.get("User").id === currentUser?.id
      );
      if (foundObject && foundObject.length > 0) {
        setTimeTrackingDataForUser(foundObject);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der TimeTracking-Daten:", error);
    }
  };

  const getParseDataForUser = async () => {
    try {
      const query = new Parse.Query("OD3_TimeVacation").limit(99999999);
      query.descending("End");
      const result = await query.find();

      const queryNew = new Parse.Query("OD3_TimeVacation").limit(99999999);
      queryNew.descending("End");
      const startOfYear = new Date(new Date().getFullYear(), 0, 1);
      queryNew.greaterThanOrEqualTo("End", startOfYear);
      const resultNew = await queryNew.find();

      let foundObject = result.filter(
        (obj: any) => obj.get("User").id === currentUser?.id
      );
      let foundObjectNew = resultNew.filter(
        (obj: any) => obj.get("User").id === currentUser?.id
      );

      if (foundObject && foundObject.length > 0) {
        setParseDataForUser(foundObject);
        setParseDataForThisYear(foundObjectNew);
        calcUsedVacation();
      } else {
        setParseDataForUser([]);
        setParseDataForThisYear([]);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-Daten:", error);
    }
  };

  const calcUsedVacation = () => {
    if (parseDataForThisYear && parseDataForThisYear.length > 0) {
      let usedVacationDays = 0;
      parseDataForThisYear.forEach((element: any) => {
        if (element.get("Status") != 1) return;
        usedVacationDays += element.get("Days");
      });
      setUsedVacationDays(usedVacationDays);
    } else {
      setUsedVacationDays(0);
    }
    initVacationDays();
  };

  return (
    <div style={{ margin: "24px", width: "auto" }}>
      <AdminToolbar
        title="Urlaubsverwaltung"
        description="Antrag auf Urlaub und Übersicht über den Urlaubsstand."
        search={""}
        actions={[
          <Button
            key="1"
            onClick={() => {
              setModalVisibility(true);
            }}
          >
            Neuer Antrag
          </Button>,
          $parse.user.current()?.get("tenantAdmin") ? (
            <Select
              key="2"
              defaultValue={currentUser?.get("name")}
              style={{ width: 120 }}
              onChange={(e: any) => {
                setCurrentUser(userData[e]);
              }}
            >
              {userData.map((user: any, index: number) => (
                <Select.Option key={user.id} value={index}>
                  {user.get("name")}
                </Select.Option>
              ))}
            </Select>
          ) : null,
        ]}
      />

      {loading && (
        <div
          className="od-page-main"
          style={{
            width: "100%",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      )}
      {!loading && (
        <div
          className="od-page-main"
          style={{
            width: "100%",
          }}
        >
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            {userMeta && (
              <Row gutter={24} style={{ textAlign: "center" }}>
                <Col xs={24} sm={8}>
                  <Statistic
                    decimalSeparator=","
                    groupSeparator="."
                    style={{ textAlign: "center" }}
                    valueStyle={{
                      textAlign: "center",
                      color:
                        usedVacationDays > totalVacationDays
                          ? "#f50"
                          : "#87d068",
                    }}
                    title={"Genommene Urlaubstage " + dayjs().year()}
                    value={usedVacationDays}
                    precision={1}
                  />
                </Col>
                <Col xs={24} sm={8}>
                  <Statistic
                    decimalSeparator=","
                    groupSeparator="."
                    style={{ textAlign: "center" }}
                    valueStyle={{ textAlign: "center" }}
                    title={"Urlaubstage " + dayjs().year()}
                    value={totalVacationDays}
                    precision={1}
                  />
                </Col>
                <Col xs={24} sm={8}>
                  <Statistic
                    decimalSeparator=","
                    groupSeparator="."
                    style={{
                      textAlign: "center",
                    }}
                    valueStyle={{
                      textAlign: "center",
                      color:
                        usedVacationDays > totalVacationDays
                          ? "#f50"
                          : "#87d068",
                    }}
                    title={"Restliche Urlaubstage " + dayjs().year()}
                    value={totalVacationDays - usedVacationDays}
                    precision={1}
                  />
                </Col>
              </Row>
            )}
            {!userMeta && (
              <Alert
                style={{ textAlign: "center" }}
                message={
                  "Es ist kein Vertrag mehr Vorhanden. Es können aber noch Urlaube nachgereicht werden!"
                }
                type="error"
              />
            )}
            <div style={{ paddingTop: "40px", paddingBottom: "20px" }}>
              <VacationTable
                data={parseDataForUser ? parseDataForUser : []}
                deleteForm={deleteForm}
              />
            </div>
          </Space>
        </div>
      )}
      <VacationModal
        modal={modalVisible}
        setModalVisibility={setModalVisibility}
        addVacationApplication={addVacationApplication}
        anzahlWerktagen={anzahlWerktagen}
      />
    </div>
  );
});
