import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { $parse } from "@opendash/plugin-parse";
import { TimeLine } from "./Pieces/TimeLine";
import { observer } from "mobx-react-lite";
import { AdminToolbar } from "@opendash/ui";
import { Options } from "../Plugin";
import { Col, Row, Spin, Select } from "antd";
import { HeaderSelect } from "./Pieces/HeaderSelect";
import { Charts } from "./Pieces/Charts";
import { CustomerResult } from "./Pieces/CustomerResult";

export const StatisticComponent = observer((props: Partial<Options>) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [userData, setUserData] = React.useState<any>([]);
  const [selectedMonth, setSelectedMonth] = React.useState<number>(
    dayjs().month()
  );
  const [selectedYear, setSelectedYear] = React.useState<number>(
    dayjs().year()
  );
  const [currentUser, setCurrentUser] = React.useState<any>(
    Parse.User.current()
  );

  React.useEffect(() => {
    initUserData();
  }, []);

  React.useEffect(() => {
    initUserData();
  }, [currentUser, selectedMonth, selectedYear]);

  const initUserData = async () => {
    try {
      const query = new Parse.Query("_User").limit(99999999);
      query.equalTo("ignoreInSelection", false);
      query.equalTo("VacationWithoutApplication", false);
      const result = await query.find();
      setUserData(result);
      setLoading(false);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-User-Daten:", error);
    }
  };

  return (
    <div style={{ margin: "24px", width: "auto" }}>
      <AdminToolbar
        title="Statistik"
        description="Details und Informationen."
        search={""}
        actions={[
          $parse.user.current()?.get("tenantAdmin") ? (
            <Select
              key="2"
              defaultValue={currentUser?.get("name")}
              style={{ width: 120 }}
              onChange={(e: any) => {
                setCurrentUser(userData[e]);
              }}
            >
              {userData.map((user: any, index: number) => (
                <Select.Option key={user.id} value={index}>
                  {user.get("name")}
                </Select.Option>
              ))}
            </Select>
          ) : null,
        ]}
      />

      {loading && (
        <div
          className="od-page-main"
          style={{
            width: "100%",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      )}
      {!loading && (
        <div
          className="od-page-main"
          style={{
            width: "100%",
          }}
        >
          <HeaderSelect
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
          />
          <Row gutter={16}>
            <Col xxl={8} xl={12} lg={12} sm={24} xs={24}>
              <TimeLine
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                currentUser={currentUser}
              />
            </Col>
            <Col xxl={8} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Charts
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                currentUser={currentUser}
              />
            </Col>
            <Col xxl={8} xl={24} lg={24} md={24} sm={24} xs={24}>
              <CustomerResult
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                currentUser={currentUser}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
});
