import React from "react";
import {
  Modal,
  Button,
  Select,
  InputNumber,
  Form,
  message,
  Radio,
  Space,
  Row,
  Col,
  Input,
  Tag,
  Popconfirm,
  Divider,
  Upload,
} from "antd";
import { Icon } from "@opendash/icons";

const { Option } = Select;
const { Dragger } = Upload;

interface LicenseEditModalProps {
  modal: boolean;
  setModalVisibility: any;
  editData: any;
  productData: any;
  editLicense: any;
}

export const LicenseEditModal: React.FC<LicenseEditModalProps> = ({
  modal,
  setModalVisibility,
  editData,
  productData,
  editLicense,
}) => {
  const [form] = Form.useForm();
  const [filteresProductData, setFilteresProductData] = React.useState<
    string[]
  >([]);
  const [selectedProducts, setSelectedProducts] = React.useState<string[]>([]);
  const [uploads, setUploads] = React.useState<any[]>([]);
  const [parseProducts, setParseProducts] = React.useState<any[]>([]);
  const [parseUploads, setParseUploads] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (editData) {
      setParseProducts(editData.products);
      setParseUploads(editData.documents);
      let productsData: any = [];
      editData.products.forEach((product: any) => {
        productsData.push(product.get("Product").id);
      });
      form.setFieldValue("customerId", editData.kunde);
      const filtered = productData.filter(
        (item: { id: string }) => !productsData.includes(item.id)
      );
      setFilteresProductData(filtered);
    } else {
      form.resetFields();
    }
  }, [form, editData]);

  const handleAddUpload = () => {
    setUploads([
      ...uploads,
      { key: Date.now(), file: null, label: "", description: "" },
    ]);
  };

  const handleRemoveUpload = (key: number) => {
    setUploads(uploads.filter((upload) => upload.key !== key));
  };

  const handleProductChange = (value: string[]) => {
    setSelectedProducts(value);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        editLicense(values);
        setModalVisibility(false);
        form.resetFields();
        setFilteresProductData([]);
        setSelectedProducts([]);
        setUploads([]);
      })
      .catch((error) => {
        console.error(error);
        message.error("Bitte füllen Sie alle erforderlichen Felder aus.");
      });
  };

  return (
    <Modal
      title="Vertrag / Lizenz Bearbeiten"
      open={modal}
      onOk={handleOk}
      okText="Speichern"
      onCancel={() => {
        form.resetFields();
        setModalVisibility(false);
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Kunde" name="customerId">
          <Select placeholder="Kunden auswählen" disabled>
            <Option value={editData ? editData.kunde : ""}>
              {editData ? editData.kunde : ""}
            </Option>
          </Select>
        </Form.Item>
        {parseProducts?.length > 0 && (
          <>
            Produkte
            {parseProducts?.map((productId) => (
              <div style={{ paddingBottom: "3px" }}>
                <Tag
                  key={productId.id}
                  color={productId.get("Product").get("Color")}
                  style={{ width: "100%" }}
                >
                  {productId.get("Product").get("Label")}
                  {" | "}
                  {productId.get("Price")}
                  {" € | "}
                  {productId.get("Discount")}
                  {"% Rabatt | "}
                  {productId.get("Interval")}
                  {" | "}
                  {productId.get("Comment")?.length > 25
                    ? `${productId.get("Comment").slice(0, 25)}...`
                    : productId.get("Comment")}
                  <div
                    style={{
                      float: "right",
                      border: "1px dashed white",

                      padding: "0px 4px",
                      cursor: "pointer",
                    }}
                  >
                    <Popconfirm
                      title="Produkt löschen?"
                      onConfirm={(e) => {
                        setParseProducts((prevSelectedProducts) =>
                          prevSelectedProducts.filter(
                            (productIds) => productIds !== productId
                          )
                        );
                        productId.destroy();
                      }}
                      onCancel={(e) => {}}
                      okText="Löschen"
                      cancelText="Abbrechen"
                    >
                      X
                    </Popconfirm>
                  </div>
                </Tag>
              </div>
            ))}
          </>
        )}

        {parseUploads?.length > 0 && (
          <>
            {parseProducts?.length > 0 && (
              <>
                <br />
                <br />
              </>
            )}
            Dokumente
            {parseUploads?.map((productId) => (
              <div style={{ paddingBottom: "3px" }}>
                <Tag
                  key={productId.id}
                  color="#676767"
                  style={{ width: "100%" }}
                >
                  {productId.get("Label")}
                  {" | "}
                  {productId.get("Description")?.length > 35
                    ? `${productId.get("Description").slice(0, 35)}...`
                    : productId.get("Description")}
                  {" | "}
                  {productId.get("File").name().split("_")[1]}
                  <div
                    style={{
                      float: "right",
                      border: "1px dashed white",

                      padding: "0px 4px",
                      cursor: "pointer",
                    }}
                  >
                    <Popconfirm
                      title="Datei löschen?"
                      onConfirm={(e) => {
                        setParseUploads((prevSelectedUploads) =>
                          prevSelectedUploads.filter(
                            (productIds) => productIds !== productId
                          )
                        );
                        productId.destroy();
                      }}
                      onCancel={(e) => {}}
                      okText="Löschen"
                      cancelText="Abbrechen"
                    >
                      X
                    </Popconfirm>
                  </div>
                </Tag>
              </div>
            ))}
          </>
        )}

        <Divider />
        <Form.Item
          label="Neue Produkte hinzufügen"
          name="products"
          rules={[
            {
              required: false,
              message: "Bitte wählen Sie mindestens ein Produkt aus.",
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Produkte auswählen"
            onChange={handleProductChange}
          >
            {filteresProductData.map((product: any) => (
              <Option key={product.id} value={product.id}>
                {product.get("Label")}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {selectedProducts.map((productId) => {
          const product = filteresProductData.find(
            (p: any) => p.id === productId
          );

          return (
            <div
              key={productId}
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                padding: "5px",
                marginBottom: "12px",
              }}
            >
              <strong>{product?.get("Label")}</strong>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Preis (€)"
                    name={["productsData", productId, "price"]}
                    style={{ width: "100%", marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "Bitte geben Sie einen Preis ein.",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      placeholder="Preis"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Rabatt (%)"
                    name={["productsData", productId, "discount"]}
                    style={{ width: "100%", marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "Bitte geben Sie einen Rabatt ein.",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      placeholder="Rabatt"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ height: "10px", width: "100%" }}></div>

              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Intervall"
                    name={["productsData", productId, "interval"]}
                    style={{ width: "100%", marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "Bitte wählen Sie ein Intervall aus.",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="Jährlich">Jährlich</Radio>
                      <Radio value="Halbjährlich">Halbjährlich</Radio>
                      <Radio value="Quartalsweise">Quartalsweise</Radio>
                      <Radio value="Monatlich">Monatlich</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Kommentar"
                    name={["productsData", productId, "comment"]}
                    style={{ width: "100%", marginBottom: 0 }}
                  >
                    <Input.TextArea
                      rows={1}
                      placeholder="Kommentar"
                      style={{ resize: "none" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          );
        })}
        <div style={{ marginBottom: "12px" }}>
          Neue Dateiuploads hinzufügen
          {uploads.map((upload, index) => (
            <div
              key={index}
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                padding: "8px",
                marginBottom: "12px",
              }}
            >
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%", marginTop: "8px" }}
              >
                {/* Datei, Label und Beschreibung in einer Zeile */}

                {/* Datei */}
                <Form.Item
                  name={["uploads", index, "file"]}
                  rules={[
                    {
                      required: true,
                      message: "Bitte wählen Sie eine Datei aus.",
                    },
                  ]}
                  style={{ flex: 2, marginBottom: 0 }}
                >
                  <Dragger
                    beforeUpload={() => false}
                    maxCount={1}
                    showUploadList={{ showPreviewIcon: false }}
                    style={{ width: "100%" }}
                  >
                    <Button icon={<Icon icon="fa:upload" />} />
                  </Dragger>
                </Form.Item>
                <Space style={{ display: "flex", gap: "8px", width: "100%" }}>
                  <Form.Item
                    name={["uploads", index, "label"]} // Eindeutiger Schlüssel für "label"
                    rules={[
                      {
                        required: true,
                        message: "Bitte geben Sie ein Label ein.",
                      },
                    ]}
                    style={{ flex: 3, marginBottom: 0 }}
                  >
                    <Input placeholder="Label" />
                  </Form.Item>

                  <Form.Item
                    name={["uploads", index, "description"]} // Eindeutiger Schlüssel für "description"
                    style={{ flex: 5, marginBottom: 0 }}
                  >
                    <Input placeholder="Beschreibung" />
                  </Form.Item>

                  {/* Entfernen-Button */}
                  <Button
                    type="text"
                    danger
                    onClick={() => handleRemoveUpload(upload.key)}
                    icon={<Icon icon="fa:trash" />}
                  />
                </Space>
              </Space>
            </div>
          ))}
          {/* Button zum Hinzufügen */}
          <Button
            type="dashed"
            onClick={handleAddUpload}
            icon={<Icon icon="fa:plus" />}
            style={{ width: "100%" }}
          >
            {uploads.length > 0
              ? "Weiteren Upload hinzufügen"
              : "Upload hinzufügen"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
