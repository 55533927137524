import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { ColumnProps } from "antd/lib/table";

export const PayrollComponent = createComponent(function PayrollComponent() {
  return (
    <AdminLayout>
      <ParseSetView
        className="OD3_LohnBuchungen"
        view={
          {
            type: "table",
            customCols: [
              {
                title: "Datum",
                key: "Datum",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    <div>{dayjs(row?.get("Datum")).format("MM.YYYY")}</div>
                  );
                },
              } as ColumnProps<any>,
              {
                title: "Steuerbrutto",
                key: "Steuerbrutto",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    <div>{row?.get("SteuerBrutto").toFixed(2) + " €"}</div>
                  );
                },
              } as ColumnProps<any>,

              {
                title: "KV-Beitrag AG",
                key: "KVBeitragAG",
                dataIndex: 0,
                render: (v, row) => {
                  return <div>{row?.get("KVBeitragAG").toFixed(2) + " €"}</div>;
                },
              } as ColumnProps<any>,

              {
                title: "RV-Beitrag AG",
                key: "RVBeitragAG",
                dataIndex: 0,
                render: (v, row) => {
                  return <div>{row?.get("RVBeitragAG").toFixed(2) + " €"}</div>;
                },
              } as ColumnProps<any>,

              {
                title: "AV-Beitrag AG",
                key: "AVBeitragAG",
                dataIndex: 0,
                render: (v, row) => {
                  return <div>{row?.get("AVBeitragAG").toFixed(2) + " €"}</div>;
                },
              } as ColumnProps<any>,

              {
                title: "PV-Beitrag AG",
                key: "PVBeitragAG",
                dataIndex: 0,
                render: (v, row) => {
                  return <div>{row?.get("PVBeitragAG").toFixed(2) + " €"}</div>;
                },
              } as ColumnProps<any>,

              {
                title: "Umlage 1",
                key: "Umlage1",
                dataIndex: 0,
                render: (v, row) => {
                  return <div>{row?.get("Umlage1").toFixed(2) + " €"}</div>;
                },
              } as ColumnProps<any>,

              {
                title: "Umlage 2",
                key: "Umlage2",
                dataIndex: 0,
                render: (v, row) => {
                  return <div>{row?.get("Umlage2").toFixed(2) + " €"}</div>;
                },
              } as ColumnProps<any>,

              {
                title: "Umlage Insolv.",
                key: "UmlageInsolv",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    <div>{row?.get("UmlageInsolv").toFixed(2) + " €"}</div>
                  );
                },
              } as ColumnProps<any>,

              {
                title: "Auszahlungsbetrag",
                key: "Auszahlungsbetrag",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    <div>{row?.get("Auszahlungsbetrag").toFixed(2) + " €"}</div>
                  );
                },
              } as ColumnProps<any>,
            ],
            fields: ["Mitarbeiter"],
          } as TableViewType
        }
      />
      <br />
      <br />
      <ParseSetView
        className="OD3_LohnJournale"
        view={
          {
            type: "table",
            fields: ["Document"],
          } as TableViewType
        }
      />
    </AdminLayout>
  );
});
