import React from "react";
import { observer } from "mobx-react-lite";
import { Options } from "../Plugin";
import { AdminToolbar } from "@opendash/ui";
import { $parse } from "@opendash/plugin-parse";
import Parse from "parse";
import { EntryModal } from "./Pieces/EntryModal";
import { EntryModalCustomer } from "./Pieces/EntryModalCustomer";
import {
  Row,
  Select,
  Col,
  Button,
  Tooltip,
  Spin,
  message,
  Statistic,
} from "antd";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { CalendarView } from "./Pieces/CalendarView";
import { AlertView } from "./Pieces/AlertView";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.locale("de");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Berlin");

const informationText = `
Gemäß dem Arbeitszeitgesetz gelten folgende Regeln:
Die tägliche Arbeitszeit darf 10 Stunden nicht überschreiten.
Zwischen zwei Arbeitstagen muss eine ununterbrochene Ruhezeit von mindestens 11 Stunden liegen.
Nach 6 Stunden Arbeit ist eine Pause von mindestens 30 Minuten vorgeschrieben.
Nach 8 Stunden Arbeit ist eine Pause von mindestens 45 Minuten vorgeschrieben.
Die maximale wöchentliche Arbeitszeit beträgt 48 Stunden, einschließlich Überstunden.
`;

export const TimeComponent = observer((props: Partial<Options>) => {
  const [currentUser, setCurrentUser] = React.useState<any>(
    Parse.User.current()
  );
  const [adminMode, setAdminMode] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [daysEntered, setDaysEntered] = React.useState<number>(0);
  const [flexTime, setFlexTime] = React.useState<number>(0);
  const [flexTimeDate, setFlexTimeDate] = React.useState<Date>(new Date());
  const [leaveDays, setLeaveChecked] = React.useState<number>(0);
  const [totalCustomerWorked, setTotalCustomerWorked] =
    React.useState<number>(0);
  const [totalProjectWorked, setTotalProjectWorked] = React.useState<number>(0);
  const [maxProjectWorked, setMaxProjectWorked] = React.useState<number>(0);
  const [totalWorked, setTotalWorked] = React.useState<number>(0);
  const [shouldWorked, setShouldWorked] = React.useState<number>(0);
  const [parseDataCustomer, setParseDataCustomer] = React.useState<any>([]);
  const [parseData, setParseData] = React.useState<any>([]);
  const [userData, setUserData] = React.useState<any>([]);
  const [userMeta, setUserMeta] = React.useState<any>(undefined);
  const [projectMeta, setProjectMeta] = React.useState<any>(undefined);
  const [freeDays, setFreeDays] = React.useState<any>([]);
  const [value, setValue] = React.useState<Dayjs>(() => dayjs());
  const [selectedMonth, setSelectedMonth] = React.useState<number>(
    dayjs().month()
  );
  const [selectedYear, setSelectedYear] = React.useState<number>(
    dayjs().year()
  );
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [hoursWorked, setHoursWorked] = React.useState<number | undefined>(8);

  const [contractWorkingHours, setContractWorkingHours] = React.useState<
    number | undefined
  >(8);

  const [comment, setComment] = React.useState<string>("");
  const [percentage, setPercentage] = React.useState<number>(100);
  const [percentageDay, setPercentageDay] = React.useState<number>(100);
  const [vacationChecked, setVacationChecked] = React.useState<boolean>(false);
  const [sickLeaveChecked, setSickLeaveChecked] =
    React.useState<boolean>(false);
  const onSelect: (newValue: Dayjs) => void = (newValue) => {
    setValue(newValue);
  };

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  React.useEffect(() => {
    initUserMeta();
    initUserData();
    getParseData();
    getFreeDays();
    calcTotalWorked();
    calcShouldWorked();
  }, []);

  React.useEffect(() => {
    initUserMeta();
    initUserData();
    getParseData();
    getFreeDays();
    calcTotalWorked();
    calcShouldWorked();
    calcTotalWorked();
    calcShouldWorked();
    calcMaxProjectWorkHours();
  }, [currentUser]);

  React.useEffect(() => {
    calcShouldWorked();
    calcTotalWorked();
    calcMaxProjectWorkHours();
    calculateFlexTime();
  }, [userMeta, projectMeta, hoursWorked, contractWorkingHours, currentUser]);

  React.useEffect(() => {
    initUserMeta();
  }, [selectedMonth, selectedYear, parseData]);

  React.useEffect(() => {
    calcShouldWorked();
    calcMaxProjectWorkHours();
  }, [selectedMonth, selectedYear, freeDays, leaveDays]);

  React.useEffect(() => {
    calculateFlexTime();
  }, [freeDays, parseData.currentUser]);

  React.useEffect(() => {
    getParseData();
    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 450);
    return () => clearTimeout(timeout);
  }, [selectedMonth, selectedYear, currentUser, adminMode]);

  const initUserMeta = () => {
    initProjectMeta();
    const query = new Parse.Query("OD3_Contract").limit(99999999);
    query.equalTo("User", currentUser);
    query.find().then((results: any) => {
      const filteredObjects = results.find((obj: any) => {
        const startDate = obj.get("Start");
        const endDate = obj.get("End");
        const today = new Date(selectedYear, selectedMonth, 15);
        return (
          (today >= startDate && today <= endDate) ||
          today.toDateString() === startDate.toDateString() ||
          today.toDateString() === endDate.toDateString()
        );
      });
      setUserMeta(filteredObjects);
      setHoursWorked(filteredObjects?.get("HoursADay"));
      setContractWorkingHours(filteredObjects?.get("HoursADay"));
      let getProjectID = projectMeta?.find((project: Parse.Object) => {
        return project?.get("Vertrag").id === filteredObjects?.id;
      });
      setPercentage(getProjectID?.get("Projektanteil"));
      setPercentageDay(getProjectID?.get("Projektanteil"));
    });
  };

  const initProjectMeta = async () => {
    if (props.researchprojectPlugin) {
      //ProjectMeta
      const queryProjectMeta = new Parse.Query("OD3_ProjectContract").limit(
        99999999
      );
      queryProjectMeta.include("Vertrag");
      queryProjectMeta.include("Projekt");
      let resultsProject = await queryProjectMeta.find();
      setProjectMeta(resultsProject);
    } else {
      setProjectMeta([]);
    }
  };

  const initUserData = async () => {
    try {
      const query = new Parse.Query("_User").limit(99999999);
      query.equalTo("ignoreInSelection", false);
      const result = await query.find();

      //Add Admin to the list
      const adminObject: Parse.Object = new Parse.Object("_User");
      adminObject.id = "admin";
      adminObject.set("name", "Admin");

      result.unshift(adminObject);

      setUserData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-User-Daten:", error);
    }
  };

  const getFreeDays = async () => {
    try {
      const query = new Parse.Query("OD3_TimeTrackingBlocked").limit(99999999);
      const result = await query.find();
      setFreeDays(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der freien Tage:", error);
    }
  };

  const getParseData = async () => {
    try {
      const query = new Parse.Query("OD3_TimeTracking").limit(99999999);
      if (!adminMode) {
        query.equalTo("User", currentUser);
      }

      const startDate = new Date(
        Date.UTC(selectedYear, selectedMonth, 1, 0, 0, 0)
      );
      const endDate = new Date(
        Date.UTC(selectedYear, selectedMonth + 1, 0, 23, 59, 59, 999)
      ); // letzter Tag des Monats

      query.greaterThanOrEqualTo("Date", startDate); // Datumsfeld in Parse
      query.lessThanOrEqualTo("Date", endDate);

      const result = await query.find();
      setParseData(result);
      getParseDataCustomer();
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-Daten:", error);
    }
  };

  const getParseDataCustomer = async () => {
    try {
      const query = new Parse.Query("OD3_Customer_TimeTrack").limit(99999999);
      query.include("Customer");
      query.include("TimeTracking");
      const result = await query.find();
      setParseDataCustomer(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-Daten:", error);
    }
  };

  const calculateFlexTime = async () => {
    try {
      const query = new Parse.Query("OD3_TimeTracking").limit(99999999);
      if (!adminMode) {
        query.equalTo("User", currentUser);

        const startDate = new Date(
          Date.UTC(new Date().getFullYear(), 0, 1, 0, 0, 0)
        );
        const endDate = new Date(
          Date.UTC(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            21,
            59,
            59,
            999
          )
        );

        query.greaterThanOrEqualTo("Date", startDate);
        query.lessThanOrEqualTo("Date", endDate);
        query.ascending("Date");

        const result = await query.find();
        let lastDate = endDate;
        if (result && result.length > 0) {
          lastDate = result[result.length - 1].get("Date");
        }
        const workingDaysThisYear = getWorkdaysBetweenDates(
          startDate,
          lastDate
        );

        if (hoursWorked) {
          let flexTimeHoursWorked = 0;
          result.forEach((element: any) => {
            if (element.get("Sick")) {
              flexTimeHoursWorked += hoursWorked;
            } else {
              flexTimeHoursWorked += element.get("WorkingHours");
            }
          });
          console.log("-------------------");
          console.log("FlexTime:", flexTimeHoursWorked);
          console.log("WorkingDaysThisYear:", workingDaysThisYear);
          console.log("HoursWorked:", hoursWorked);

          setFlexTime(
            parseFloat(
              (
                parseFloat(flexTimeHoursWorked.toFixed(2)) -
                parseFloat((workingDaysThisYear * hoursWorked).toFixed(2))
              ).toFixed(2)
            )
          );
          setFlexTimeDate(lastDate);
        }
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-Daten:", error);
    }
  };

  const deleteCurrentEntry = async (entry: Parse.Object) => {
    try {
      await entry.destroy();
      await getParseData();

      await calcTotalWorked();
      await calcShouldWorked();
    } catch (error) {
      console.error("Fehler beim Löschen des Eintrags:", error);
    }
  };

  const calcTotalWorked = () => {
    let leaveDays = 0;
    let projectHours = 0;
    let customerHours = 0;
    let calcedWorkHour = 0;
    let foundObject = parseData.filter(
      (obj: any) =>
        dayjs(obj.get("Date")).format("MM.YYYY") ===
        dayjs(
          `${selectedYear}-${(selectedMonth + 1).toString().padStart(2, "0")}`,
          "YYYY-MM"
        ).format("MM.YYYY")
    );
    if (foundObject && foundObject.length > 0) {
      let onlyUserItems = foundObject.filter(
        (obj: any) => obj.get("User").id === currentUser?.id
      );
      if (onlyUserItems && onlyUserItems.length > 0) {
        setDaysEntered(onlyUserItems.length);
        onlyUserItems.forEach((element: any) => {
          let customerItems = parseDataCustomer.filter(
            (obj: any) => obj.get("TimeTracking").id === element.id
          );
          if (element.get("Sick") || element.get("Vacation")) {
            leaveDays++;
            calcedWorkHour += contractWorkingHours || 8;
          } else {
            calcedWorkHour += element.get("WorkingHours");
            if (element.get("Project") > 100) {
              const researchProjects = element.get("Research");
              for (let researchObject of researchProjects) {
                projectHours += parseFloat(researchObject.worktime) / 60;
              }
            } else {
              projectHours +=
                (element.get("WorkingHours") / 100) * element.get("Project");
            }
            if (customerItems && customerItems.length > 0) {
              const customerProjects = customerItems;
              for (let customerObject of customerProjects) {
                if (
                  customerObject.get("Customer").get("Name") != "open.INC GmbH"
                ) {
                  customerHours +=
                    parseFloat(customerObject.get("Worktime")) / 60;
                }
              }
            }
          }
        });
      }
    }
    setLeaveChecked(leaveDays);
    setTotalCustomerWorked(Math.round(customerHours * 100) / 100);
    setTotalProjectWorked(Math.round(projectHours * 100) / 100);
    setTotalWorked(Math.round(calcedWorkHour * 100) / 100);
  };

  const calcMaxProjectWorkHours = () => {
    let days = anzahlWerktagen(selectedMonth + 1, selectedYear);
    let hoursADay = contractWorkingHours || 8;
    setMaxProjectWorked(
      Math.round((((days - leaveDays) * hoursADay * percentage) / 100) * 100) /
        100
    );
  };

  const calcShouldWorked = () => {
    if (freeDays.length > 0) {
      let days = anzahlWerktagen(selectedMonth + 1, selectedYear);
      let hoursADay = contractWorkingHours || 8;
      setShouldWorked(Math.round(days * hoursADay * 100) / 100);
    }
  };

  const anzahlWerktagen = (monat: number, jahr: number) => {
    let werktage = 0;
    const ersteTagDesMonats = dayjs(
      `${jahr}-${monat.toString().padStart(2, "0")}-01`,
      "YYYY-MM-DD"
    );
    const letzterTagDesMonats = ersteTagDesMonats
      .add(1, "month")
      .subtract(1, "day"); // Letzter Tag des Monats
    for (let tag = 1; tag <= letzterTagDesMonats.date(); tag++) {
      const datum = dayjs(
        `${jahr}-${monat.toString().padStart(2, "0")}-${tag
          .toString()
          .padStart(2, "0")}`,
        "YYYY-MM-DD"
      );
      if (datum.day() > 0 && datum.day() < 6) {
        // 0 steht für Sonntag und 6 für Samstag

        //Delete Feiertage
        let foundObject = freeDays.find(
          (obj: {
            get: (
              arg0: string
            ) => string | number | Dayjs | Date | null | undefined;
          }) =>
            dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
            datum.format("DD.MM.YYYY")
        );

        if (foundObject) {
        } else {
          werktage++;
        }
      }
    }
    return werktage;
  };

  const getWorkdaysBetweenDates = (startDate: Date, endDate: Date) => {
    let count = 0;
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      let dayjsDate = dayjs(currentDate);
      const dayOfWeek = currentDate.getDay(); // Wochentag (0 = Sonntag, 6 = Samstag)
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        let foundObject = freeDays.find(
          (obj: {
            get: (
              arg0: string
            ) => string | number | Dayjs | Date | null | undefined;
          }) =>
            dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
            dayjsDate.format("DD.MM.YYYY")
        );

        if (foundObject) {
        } else {
          count++;
        }
      }
      currentDate.setDate(currentDate.getDate() + 1); // Einen Tag weiter
    }

    return count;
  };

  const getYearOptions = (): JSX.Element[] => {
    const currentYear: number = dayjs().year();
    const options: JSX.Element[] = [];
    for (let i = currentYear - 1; i < currentYear + 2; i += 1) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>
      );
    }
    return options;
  };

  const addEntry = (
    date: Date,
    hours: any,
    comment: string,
    sick: boolean,
    vacation: boolean,
    project: number
  ) => {
    const timeObject = new Parse.Object("OD3_TimeTracking");
    let hoursClean = hours;
    try {
      hoursClean = parseFloat(hours.replace(/,/g, ".")) || hours;
    } catch (error) {
      // Keine Kommazahl
    }
    // Eigenschaften des Objekts setzen
    timeObject.set("Date", date);
    timeObject.set("WorkingHours", hoursClean);
    timeObject.set("Comment", comment);
    timeObject.set("Sick", sick);
    timeObject.set("Vacation", vacation);
    timeObject.set("Project", project);
    timeObject.set("User", currentUser);

    const acl = new Parse.ACL();
    const currentUserACL = currentUser;
    if (currentUserACL) {
      acl.setReadAccess(currentUserACL, true);
      acl.setWriteAccess(currentUserACL, true);
    }
    acl.setRoleReadAccess(
      "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
      true
    );
    acl.setRoleWriteAccess(
      "od-tenant-admin-" + $parse.user.current()?.get("tenant")?.id,
      true
    );

    timeObject.setACL(acl);
    timeObject.save().then(() => {
      getParseData();
      setHoursWorked(contractWorkingHours);
      calcTotalWorked();
      calcShouldWorked();
      setComment("");
      setSickLeaveChecked(false);
      setVacationChecked(false);
      setPercentageDay(percentage);
    });
  };

  const onPanelChange: (newValue: Dayjs) => void = (newValue) => {
    setValue(newValue);
    setSelectedMonth(newValue.month());
    setSelectedYear(newValue.year());
  };

  const months: string[] = [];
  for (let i = 0; i < 12; i++) {
    months.push(dayjs().month(i).format("MMMM"));
  }

  const headerRender = ({
    value,
    onChange,
  }: {
    value: Dayjs;
    onChange: (newDate: Dayjs) => void;
  }) => {
    const month: number = value.month();
    const year: number = value.year();

    return (
      <div
        style={{
          padding: "15px",
          width: "100%",
        }}
      >
        <Row gutter={24}>
          {!isMobile && (
            <Col xs={24} sm={24} md={24} lg={19}>
              <Row gutter={24}>
                <Col xs={{ span: 24 }} sm={8} md={8} lg={6}>
                  <Tooltip
                    placement="bottom"
                    title={
                      !adminMode ? (
                        <>
                          Dein Gleitzeitkonto für{" "}
                          <strong>{new Date().getFullYear()}</strong> beträgt{" "}
                          <b>
                            {parseInt(flexTime) > 0 ? "+" : ""}
                            {flexTime} Std.
                          </b>{" "}
                          {parseInt(flexTime) < 0
                            ? "Bitte zeitnah nacharbeiten."
                            : parseInt(flexTime) > 0
                            ? "Bitte zeitnah ausgleichen."
                            : ""}{" "}
                          (Berücksichtigt bis{" "}
                          <strong>
                            {`${flexTimeDate
                              .getDate()
                              .toString()
                              .padStart(2, "0")}.${(flexTimeDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0")}.${flexTimeDate.getFullYear()}`}
                          </strong>
                          ).
                        </>
                      ) : null
                    }
                  >
                    <Statistic
                      decimalSeparator=","
                      groupSeparator="."
                      valueStyle={{
                        textAlign: "center",
                        color: adminMode
                          ? "black"
                          : flexTime > 20 && !userMeta?.get("Overtime")
                          ? "red"
                          : flexTime < -10 && !userMeta?.get("Overtime")
                          ? "red"
                          : "black",
                      }}
                      style={{ textAlign: "center" }}
                      title="Ist-Stunden"
                      value={adminMode ? "-" : totalWorked.toFixed(2)}
                    />
                  </Tooltip>
                </Col>
                <Col xs={24} sm={8} md={8} lg={6}>
                  <Tooltip placement="bottom" title="">
                    <Statistic
                      decimalSeparator=","
                      groupSeparator="."
                      valueStyle={{
                        textAlign: "center",
                        color: "black",
                      }}
                      style={{ textAlign: "center" }}
                      title="Soll-Stunden"
                      value={adminMode ? "-" : shouldWorked.toFixed(2)}
                      precision={2}
                    />
                  </Tooltip>
                </Col>

                {props.researchprojectPlugin && props.customerPlugin && (
                  <Col xs={{ span: 24 }} sm={8} md={8} lg={7}>
                    <Tooltip
                      placement="bottom"
                      title={
                        <>
                          <div>
                            Kundenstunden beinhalten keine internen (open.INC)
                            Stunden.
                          </div>
                          {totalProjectWorked > maxProjectWorked && (
                            <div style={{ marginTop: "8px" }}>
                              Die Projektstunden für diesen Monat sind
                              überschritten, bitte zeitnah ausgleichen!
                            </div>
                          )}
                        </>
                      }
                    >
                      <Statistic
                        decimalSeparator=","
                        groupSeparator="."
                        valueStyle={{
                          textAlign: "center",
                          color: adminMode
                            ? "black"
                            : totalProjectWorked > maxProjectWorked
                            ? "red"
                            : totalProjectWorked === maxProjectWorked
                            ? "#7fbb47"
                            : "black",
                        }}
                        style={{ textAlign: "center" }}
                        title="Projekt- | Kundenstunden"
                        value={
                          adminMode
                            ? "-"
                            : totalProjectWorked.toFixed(2).replace(".", ",") +
                              " | " +
                              totalCustomerWorked.toFixed(2).replace(".", ",")
                        }
                        precision={2}
                      />
                    </Tooltip>
                  </Col>
                )}
              </Row>
            </Col>
          )}
          <Col
            xs={{ span: 24 }}
            sm={24}
            md={24}
            lg={5}
            style={{
              margin: "0px",
              padding: "0px",
            }}
          >
            {isMobile && (
              <Row
                gutter={24}
                style={{
                  textAlign: "center",
                  width: "100%",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                <Col
                  xs={{ span: 24 }}
                  sm={24}
                  md={24}
                  lg={12}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  <Select
                    size="large"
                    style={{ width: "100%", margin: "0px", padding: "0px" }}
                    className="my-year-select"
                    onChange={(newYear: string) => {
                      const now: Dayjs = value
                        .clone()
                        .year(parseInt(newYear, 10));
                      onChange(now);
                      setSelectedYear(parseInt(newYear, 10));
                    }}
                    value={String(year)}
                  >
                    {getYearOptions()}
                  </Select>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={24}
                  md={24}
                  lg={12}
                  style={{
                    textAlign: "center",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  <div style={{ width: "100%", height: "10px" }}></div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={24}
                  md={24}
                  lg={12}
                  style={{
                    textAlign: "center",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  <Select
                    size="large"
                    style={{ width: "100%", margin: "0px", padding: "0px" }}
                    value={String(months[month])}
                    onChange={(newMonth: string) => {
                      const now: Dayjs = value
                        .clone()
                        .month(parseInt(newMonth, 10));
                      onChange(now);
                      setSelectedMonth(parseInt(newMonth, 10));
                    }}
                  >
                    {getMonthOptions()}
                  </Select>
                </Col>
              </Row>
            )}
            {!isMobile && (
              <Row gutter={24} style={{ textAlign: "center" }}>
                <Col xs={{ span: 24 }} sm={24} md={24} lg={12}>
                  <Select
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    className="my-year-select"
                    onChange={(newYear: string) => {
                      const now: Dayjs = value
                        .clone()
                        .year(parseInt(newYear, 10));
                      onChange(now);
                      setSelectedYear(parseInt(newYear, 10));
                    }}
                    value={String(year)}
                  >
                    {getYearOptions()}
                  </Select>
                </Col>
                <Col xs={{ span: 24 }} sm={24} md={24} lg={12}>
                  <Select
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    value={String(months[month])}
                    onChange={(newMonth: string) => {
                      const now: Dayjs = value
                        .clone()
                        .month(parseInt(newMonth, 10));
                      onChange(now);
                      setSelectedMonth(parseInt(newMonth, 10));
                    }}
                  >
                    {getMonthOptions()}
                  </Select>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  const getListData = (value: Dayjs): { type: string; content: string }[] => {
    const listData: { type: string; content: string; items: any[] }[] = [];
    let filteredUserItems = [];
    const foundObjects = parseData.filter(
      (obj: {
        get: (
          arg0: string
        ) => string | number | Dayjs | Date | null | undefined;
      }) => dayjs(obj.get("Date")).isSame(value, "day")
    );

    if (foundObjects && foundObjects.length > 0) {
      let onlyUserItems = foundObjects.filter(
        (obj: {
          get: (arg0: string) => {
            (): any;
            new (): any;
            id: string | undefined;
          };
        }) => obj.get("User").id === currentUser?.id
      );
      if (onlyUserItems) {
        filteredUserItems = onlyUserItems;
      }
    }

    if (adminMode) {
      filteredUserItems = foundObjects;
    }

    filteredUserItems.forEach((element: any) => {
      const zeroWorkDay = element.get("WorkingHours") == 0 ? true : false;
      const initials = element.get("User").get("Initial");
      const workingHoursOfDay = element.get("WorkingHours") + " Std.";

      let getProjectID = projectMeta?.find((project: Parse.Object) => {
        return (
          project?.get("Vertrag")?.id ===
          element.get("Research")?.[0]?.projectID
        );
      });

      const projectName = props.researchprojectPlugin
        ? element.get("Research")?.[0]?.project
        : "Intern";

      let projectPercentageOfDay = props.researchprojectPlugin
        ? "(" +
          (!adminMode ? projectName : "Projektanteil") +
          ": " +
          element.get("Project") +
          "%)"
        : "";

      const projectNameTag = props.researchprojectPlugin
        ? projectName
        : "Projekt";

      let newFormatItems: any = [];
      if (element.get("Project") > 100) {
        let researchProjects = element.get("Research");
        for (let researchObject of researchProjects) {
          if (researchObject) {
            let projectHours = parseFloat(researchObject.worktime);
            const hours = Math.floor(projectHours / 60);
            const minutes = projectHours % 60;
            const formattedWorktime = `${hours}:${minutes
              .toString()
              .padStart(2, "0")} Std.`;
            let researchOfDay = projectNameTag + " " + formattedWorktime;
            newFormatItems.push({
              type: "project",
              content: `${initials} | ${researchOfDay}`,
              items: newFormatItems,
            });
          }
        }

        let customerProjects = parseDataCustomer.filter(
          (obj: any) => obj.get("TimeTracking").id === element.id
        );
        for (let customerObject of customerProjects) {
          if (customerObject) {
            let projectHours = parseFloat(customerObject.get("Worktime"));
            const hours = Math.floor(projectHours / 60);
            const minutes = projectHours % 60;
            const formattedWorktime = `${hours}:${minutes
              .toString()
              .padStart(2, "0")} Std.`;

            let customerName =
              customerObject.get("Customer").get("Name").length > 15
                ? customerObject.get("Customer").get("Name").slice(0, 12) +
                  "..."
                : customerObject.get("Customer").get("Name");
            let customerOfDay = customerName + " " + formattedWorktime;

            newFormatItems.push({
              type: "customer",
              content: `${initials} | ${customerOfDay}`,
              items: newFormatItems,
            });
          }
        }
      }

      if (element.get("Sick")) {
        listData.push({
          type: "error",
          content: `${initials}: Krankheit`,
          items: newFormatItems,
        });
      } else if (element.get("Vacation")) {
        listData.push({
          type: "warning",
          content: `${initials}: Urlaub`,
          items: newFormatItems,
        });
      } else if (zeroWorkDay) {
        listData.push({
          type: "nothing",
          content: `${initials} | ${workingHoursOfDay}`,
          items: newFormatItems,
        });
      } else {
        listData.push({
          type: "success",
          content: `${initials} | ${workingHoursOfDay} ${projectPercentageOfDay}`,
          items: newFormatItems,
        });
      }
    });

    return listData;
  };

  const getMonthData = (value: Dayjs): number | undefined => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  const getMonthOptions = (): JSX.Element[] => {
    const options: JSX.Element[] = [];
    months.forEach((month, index) => {
      options.push(
        <Select.Option key={index} value={index} className="year-item">
          {month}
        </Select.Option>
      );
    });
    return options;
  };

  const handleModalOk = () => {
    if (validateInput()) {
      let workingHours = hoursWorked;
      let percentHours = percentage;
      if (sickLeaveChecked) {
        workingHours = 0;
        percentHours = 0;
      } else if (vacationChecked) {
        workingHours = 0;
        percentHours = 0;
      }

      addEntry(
        value.toDate(),
        workingHours || 0,
        comment,
        sickLeaveChecked,
        vacationChecked,
        percentHours
      );
      setModalVisible(false);
    }
  };

  const handleModalOkCustomer = () => {
    getParseData();
    calcTotalWorked();
    calcShouldWorked();
    setModalVisible(false);
    setPercentageDay(percentage);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setPercentageDay(percentage);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handlePercentageChange = (value: number) => {
    setPercentageDay(value);
  };

  const handleVacationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVacationChecked(e.target.checked);
  };

  const handleSickLeaveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSickLeaveChecked(e.target.checked);
  };

  const getProjectName = React.useCallback(
    (date: Date): string => {
      if (userMeta) {
        const filteredProjects = projectMeta?.filter(
          (project: Parse.Object) => {
            return project?.get("Vertrag")?.id === userMeta?.id;
          }
        );

        const matchingProject = filteredProjects?.find(
          (project: Parse.Object) => {
            const start = project?.get("Start");
            const end = project?.get("End");
            return start <= date && date <= end;
          }
        );

        return matchingProject?.get("Projekt")?.get("Name") || "Intern";
      }
      return "Intern";
    },
    [userMeta, projectMeta] // Die Funktion wird nur neu erstellt, wenn sich diese ändern
  );

  const getProjectID = (date: Date): string => {
    if (userMeta) {
      const filteredProjects = projectMeta?.filter((project: Parse.Object) => {
        return project?.get("Vertrag")?.id === userMeta?.id;
      });

      const matchingProject = filteredProjects?.find(
        (project: Parse.Object) => {
          const start = project?.get("Start");
          const end = project?.get("End");
          return start <= date && date <= end;
        }
      );
      return matchingProject?.get("Projekt")?.id;
    }
    return "Intern";
  };

  const getModalTitle = (): string => {
    if (userMeta) {
      let getProjectID = projectMeta?.find((project: Parse.Object) => {
        return project?.get("Vertrag")?.id === userMeta?.id;
      });
      return userMeta
        ? `${value.format("DD.MMMM.YYYY")}` +
            (props.customerPlugin
              ? ""
              : " | Projekt: " +
                (props.researchprojectPlugin
                  ? getProjectID?.get("Projekt").get("Name")
                  : "Intern"))
        : "";
    }
    return "";
  };

  const getModalEntry = (): [boolean | Parse.Object | null, Parse.Object[]] => {
    let foundObject = parseData.filter(
      (obj: {
        get: (
          arg0: string
        ) => string | number | Date | Dayjs | null | undefined;
      }) =>
        dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
        value.format("DD.MM.YYYY")
    );

    if (foundObject && foundObject.length > 0) {
      let allUserItems = foundObject;
      let onlyUserItems = foundObject.find(
        (obj: any) => obj.get("User").id === currentUser?.id
      );
      if (onlyUserItems) {
        const filteredArray = allUserItems.filter(
          (item: any) => item !== onlyUserItems
        );
        return [onlyUserItems, adminMode ? filteredArray : []]; //HIER
      } else {
        return [false, adminMode ? allUserItems : []]; //HIER
      }
    } else {
      return [false, []];
    }
  };

  const checkDeleteStatus = (element: Parse.Object): boolean => {
    if (
      element.get("Vacation") &&
      !element.get("User").get("VacationWithoutApplication")
    ) {
      return false;
    }
    return (
      element.get("updatedAt") >
        new Date(new Date().getTime() - 2 * 60 * 60 * 1000) ||
      currentUser?.get("tenantAdmin")
    );
  };

  const validateInput = (): boolean => {
    if (!/^(\d+([,.]\d{1,2})?|\d+)$/.test(`${hoursWorked || 0}`)) {
      message.error(
        "Ungültige Arbeitszeit. Bitte geben Sie die Arbeitsstunden im Format 'Stunden' ein (z.B. 8 oder 8.50)"
      );
      return false;
    }

    if (vacationChecked && sickLeaveChecked) {
      message.error(
        "Sie können nicht gleichzeitig Urlaub und Krankheit auswählen."
      );
      return false;
    }

    return true;
  };

  const getObjectForDate = (array: any, targetDate: any) => {
    const filteredObjects = array.filter((obj: any) => {
      return (
        obj.get("Date").getDate() === targetDate.getDate() &&
        obj.get("Date").getMonth() === targetDate.getMonth() &&
        obj.get("Date").getFullYear() === targetDate.getFullYear()
      );
    });

    return filteredObjects;
  };

  return (
    <div style={{ margin: "24px", width: "auto" }}>
      <AdminToolbar
        title="Zeiterfassung"
        description="Zeiterfassung für Mitarbeitende"
        search={""}
        actions={
          $parse.user.current()?.get("tenantAdmin")
            ? [
                <Button
                  key="1"
                  onClick={(e) => {
                    let data = [
                      {
                        0: "Stundennachweise",
                        1: currentUser.get("name"),
                        2: "Jahr: " + selectedYear,
                        3:
                          "Monat: " +
                          (selectedMonth + 1).toString().padStart(2, "0"),
                      },
                      {},
                      {
                        0: "",
                        1: 1,
                        2: 2,
                        3: 3,
                        4: 4,
                        5: 5,
                        6: 6,
                        7: 7,
                        8: 8,
                        9: 9,
                        10: 10,
                        11: 11,
                        12: 12,
                        13: 13,
                        14: 14,
                        15: 15,
                        16: 16,
                        17: 17,
                        18: 18,
                        19: 19,
                        20: 20,
                        21: 21,
                        22: 22,
                        23: 23,
                        24: 24,
                        25: 25,
                        26: 26,
                        27: 27,
                        28: 28,
                        29: 29,
                        30: 30,
                        31: 31,
                      },
                    ];
                    const projectWork = {};
                    const otherWork = {};
                    const leaveWork = {};
                    const daysInMonth = new Date(
                      selectedYear,
                      selectedMonth + 1,
                      0
                    ).getDate();

                    let filteredData = [];
                    let foundObject = parseData.filter(
                      (obj: {
                        get: (
                          arg0: string
                        ) => string | number | Date | Dayjs | null | undefined;
                      }) =>
                        dayjs(obj.get("Date")).format("MM.YYYY") ===
                        dayjs(
                          `${selectedYear}-${(selectedMonth + 1)
                            .toString()
                            .padStart(2, "0")}`,
                          "YYYY-MM"
                        ).format("MM.YYYY")
                    );
                    if (foundObject && foundObject.length > 0) {
                      let onlyUserItems = foundObject.filter(
                        (obj: {
                          get: (arg0: string) => {
                            (): any;
                            new (): any;
                            id: string | undefined;
                          };
                        }) => obj.get("User").id === currentUser?.id
                      );
                      if (onlyUserItems && onlyUserItems.length > 0) {
                        filteredData = onlyUserItems;
                      }
                    }

                    leaveWork[0] = "Fehlzeiten";
                    projectWork[0] = "Projekt Stunden";
                    if (props.researchprojectPlugin) {
                      otherWork[0] = "Sonstige Stunden";
                    } else {
                      otherWork[0] = "Arbeitstunden";
                    }

                    for (let day = 1; day <= daysInMonth; day++) {
                      const dateKey = day + "";
                      let objectForDay = getObjectForDate(
                        filteredData,
                        new Date(selectedYear, selectedMonth, day)
                      );

                      if (objectForDay.length > 0) {
                        if (
                          objectForDay[0].get("Sick") ||
                          objectForDay[0].get("Vacation")
                        ) {
                          leaveWork[dateKey] = contractWorkingHours;
                          projectWork[dateKey] = 0;
                          otherWork[dateKey] = 0;
                        } else {
                          leaveWork[dateKey] = 0;

                          if (props.researchprojectPlugin) {
                            if (objectForDay[0].get("Project") > 100) {
                              const researchProjects =
                                objectForDay[0].get("Research");
                              for (let researchObject of researchProjects) {
                                projectWork[dateKey] = (
                                  parseFloat(researchObject.worktime) / 60
                                ).toLocaleString("de-DE", {
                                  minimumFractionDigits: 1,
                                });
                              }
                            } else {
                              projectWork[dateKey] = (
                                (objectForDay[0].get("Project") *
                                  objectForDay[0].get("WorkingHours")) /
                                100
                              ).toLocaleString("de-DE", {
                                minimumFractionDigits: 1,
                              });
                            }
                            if (objectForDay[0].get("Project") > 100) {
                              const researchProjects =
                                objectForDay[0].get("Research");
                              for (let researchObject of researchProjects) {
                                otherWork[dateKey] = Math.round(
                                  parseFloat(researchObject.worktime) / 60
                                ).toLocaleString("de-DE", {
                                  minimumFractionDigits: 1,
                                });
                              }
                            } else {
                              otherWork[dateKey] = (
                                Math.round(
                                  (objectForDay[0].get("WorkingHours") -
                                    (objectForDay[0].get("Project") *
                                      objectForDay[0].get("WorkingHours")) /
                                      100) *
                                    100
                                ) / 100
                              ).toLocaleString("de-DE", {
                                minimumFractionDigits: 1,
                              });
                            }
                          } else {
                            projectWork[dateKey] = (0).toLocaleString("de-DE", {
                              minimumFractionDigits: 1,
                            });

                            otherWork[dateKey] = Math.round(
                              objectForDay[0].get("WorkingHours")
                            ).toLocaleString("de-DE", {
                              minimumFractionDigits: 1,
                            });
                          }
                        }
                      } else {
                        leaveWork[dateKey] = 0;
                        projectWork[dateKey] = 0;
                        otherWork[dateKey] = 0;
                      }
                    }
                    if (props.researchprojectPlugin) {
                      data.push(projectWork);
                    }
                    data.push(otherWork);
                    data.push({});
                    data.push(leaveWork);
                    // Erstelle eine CSV-Datei
                    const csvContent =
                      "data:text/csv;charset=utf-8," +
                      data
                        .map((row) => Object.values(row).join(";"))
                        .join("\n");

                    // Erzeuge einen Download-Link
                    const encodedUri = encodeURI(csvContent);
                    const link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute(
                      "download",
                      currentUser.get("Initial") +
                        "_Zeiten_" +
                        (selectedMonth + 1).toString().padStart(2, "0") +
                        "_" +
                        selectedYear +
                        ".csv"
                    );

                    // Füge den a-Tag zum Dokument hinzu und simuliere den Klick
                    document.body.appendChild(link);
                    link.click();

                    // Entferne den a-Tag
                    document.body.removeChild(link);
                  }}
                >
                  Export
                </Button>,
                <Select
                  key="2"
                  defaultValue={currentUser?.get("name")}
                  style={{ width: 120 }}
                  onChange={(e) => {
                    if (e === 0) {
                      setAdminMode(true);
                      setCurrentUser(Parse.User.current());
                    } else {
                      setAdminMode(false);
                      setCurrentUser(userData[e]);
                    }
                  }}
                >
                  {userData.map((user, index) => (
                    <Select.Option key={user.id} value={index}>
                      {user.get("name")}
                    </Select.Option>
                  ))}
                </Select>,
              ]
            : []
        }
      />
      {loading && (
        <div
          className="od-page-main"
          style={{
            width: "100%",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      )}
      {!loading && (
        <div className="od-page-main">
          <AlertView
            contract={userMeta ? true : false}
            isMobile={isMobile}
            isOvertime={userMeta?.get("Overtime")}
            totalWorked={totalWorked}
            shouldWorked={shouldWorked}
            totalProjectWorked={totalProjectWorked}
            totalCustomerWorked={totalCustomerWorked}
            maxProjectWorked={maxProjectWorked}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            daysEntered={daysEntered}
            anzahlWerktagen={anzahlWerktagen}
          />

          <CalendarView
            projectMeta={projectMeta}
            contract={userMeta ? true : false}
            isMobile={isMobile}
            value={value}
            headerRender={headerRender}
            onSelect={onSelect}
            onPanelChange={onPanelChange}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            getMonthData={getMonthData}
            getListData={getListData}
            freeDays={freeDays}
            setModalVisible={setModalVisible}
          />
          {!props.researchprojectPlugin && (
            <EntryModal
              props={props}
              currentUser={currentUser}
              modalTitle={getModalTitle()}
              modalVisible={modalVisible}
              vacationChecked={vacationChecked}
              handleVacationChange={handleVacationChange}
              hoursWorked={hoursWorked}
              handlePercentageChange={handlePercentageChange}
              percentage={percentageDay}
              handleCommentChange={handleCommentChange}
              comment={comment}
              sickLeaveChecked={sickLeaveChecked}
              handleSickLeaveChange={handleSickLeaveChange}
              informationText={informationText}
              handleModalOk={handleModalOk}
              handleModalCancel={handleModalCancel}
              getOwnList={getModalEntry()[0]}
              checkDeleteStatus={checkDeleteStatus}
              deleteCurrentEntry={deleteCurrentEntry}
              setModalVisible={setModalVisible}
              getAllList={getModalEntry()[1]}
              setHoursWorked={setHoursWorked}
            />
          )}
          {props.researchprojectPlugin && (
            <EntryModalCustomer
              props={props}
              userMeta={userMeta}
              project={(date: Date) => getProjectName(date)}
              projectID={getProjectID}
              projectMeta={projectMeta}
              parseDataCustomer={parseDataCustomer}
              dateClicked={value.toDate()}
              currentUser={currentUser}
              modalTitle={getModalTitle()}
              modalVisible={modalVisible}
              hoursWorked={hoursWorked}
              handleModalOk={handleModalOkCustomer}
              handleModalCancel={handleModalCancel}
              getOwnList={getModalEntry()[0]}
              checkDeleteStatus={checkDeleteStatus}
              deleteCurrentEntry={deleteCurrentEntry}
              setModalVisible={setModalVisible}
              getAllList={getModalEntry()[1]}
              setHoursWorked={setHoursWorked}
            />
          )}
        </div>
      )}
    </div>
  );
});
