import Parse from "parse";
import { AppFactory, AppPluginInterface } from "@opendash/core";
import { TimeComponent } from "./components/TimeComponent";
import { HolidayComponent } from "./components/AdminPages/HolidayComponent";
import { VacationComponent } from "./components/VacationComponent";
import { VacationCheckComponent } from "./components/AdminPages/VacationCheckComponent";
import { $parse } from "@opendash/plugin-parse";
import { ContractComponent } from "./components/AdminPages/ContractComponent";
import { PayrollComponent } from "./components/AdminPages/PayrollComponent";
import { StatisticComponent } from "./components/StatisticComponent";

export interface Options {
  researchprojectPlugin: boolean;
  customerPlugin: boolean;
  }

export class TimeTrackingPlugin implements AppPluginInterface {
    name: string = "TimeTrackingPlugin";

    private options: Partial<Options> = {};

  constructor(options?: Partial<Options>) {
    this.options = options || {};
  }
    
    async onFactory(factory: AppFactory) {
        factory.registerRoute({
          path: "/timetracking/time",
          props: this.options,
          component: async () => ({ default: TimeComponent }),
          permission: "timetracking:view",
        });

        factory.registerRoute({
          path: "/timetracking/statistic",
          props: this.options,
          component: async () => ({ default: StatisticComponent }),
          permission: "statistic:view",
        });

        factory.registerRoute({
          path: "/timetracking/vacation",
          props: this.options,
          component: async () => ({ default: VacationComponent }),
          permission: "vacation:view",
        });

        factory.registerRoute({
          path: "/admin/timetracking/holiday",
          props: this.options,
          component: async () => ({ default: HolidayComponent }),
          permission: "vacation:admin",
        });

        factory.registerRoute({
          path: "/admin/timetracking/vacation",
          props: this.options,
          component: async () => ({ default: VacationCheckComponent }),
          permission: "vacation:admin",
        });
    
        factory.registerRoute({
          path: "/admin/parse/contract",
          props: this.options,
          component: async () => ({ default: ContractComponent }),
          permission: "stuff:admin",
        });

        factory.registerRoute({
          path: "/admin/parse/payroll",
          props: this.options,
          component: async () => ({ default: PayrollComponent }),
          permission: "stuff:admin",
        });

        // Navigation:
        factory.registerStaticNavigationItem({
          id: "timetracking/time",
          place: "frontpage",
          group: "timetracking",
          order: 11,
          color: "#676767",
          label: "Zeiterfassung",
          icon: "fa:clock",
          link: "/timetracking/time",
          routeCondition: "**",
          activeCondition: "/timetracking",
        });

        factory.registerStaticNavigationItem({
          id: "timetracking/statistic",
          place: "frontpage",
          group: "statistic",
          order: 13,
          color: "#676767",
          label: "Auswertung",
          icon: "fa:chart-pie",
          link: "/timetracking/statistic",
          routeCondition: "**",
          activeCondition: "/timetracking",
          permission: "statistic:view",
        });

        factory.registerStaticNavigationItem({
          id: "timetracking/vacation",
          place: "frontpage",
          group: "vacation",
          order: 12,
          color: "#676767",
          label: "Urlaubsverwaltung",
          icon: "fa:suitcase",
          link: "/timetracking/vacation",
          routeCondition: "**",
          activeCondition: "/timetracking",
          permission: "vacation:view",
        });

        factory.registerStaticNavigationGroup({
          label: "Zeiterfassung",
          order: 30,
          id: "admin/timetracking",
        });

        factory.registerStaticNavigationItem({
          id: "admin/vacationtracking",
          group: "admin/timetracking",
          place: "sidebar",
          order: 10,
          label: "Urlaubsverwaltung",
          icon: "fa:suitcase",
          link: "/admin/timetracking/vacation",
          routeCondition: "/admin/**",
          activeCondition: "/admin/timetracking/vacation",
          permission: "vacation:admin",
        });
        factory.registerStaticNavigationItem({
          id: "admin/timetrackingItem",
          group: "admin/timetracking",
          place: "sidebar",
          order: 20,
          label: "Feiertage",
          icon: "fa:umbrella-beach",
          link: "/admin/timetracking/holiday",
          routeCondition: "/admin/**",
          activeCondition: "/admin/timetracking/holiday",
          permission: "vacation:admin",
        });

        factory.registerStaticNavigationItem({
          id: "admin/contractadmin",
          group: "admin/parse",
          place: "sidebar",
          order: 90,
          label: "Verträge",
          icon: "fa:file-contract",
          link: "/admin/parse/contract",
          routeCondition: "/admin/**",
          activeCondition: "/admin/parse/contract",
          permission: "stuff:admin",
        });

        factory.registerStaticNavigationItem({
          id: "admin/payrolladmin",
          group: "admin/parse",
          place: "sidebar",
          order: 91,
          label: "Lohnkosten",
          icon: "fa:file-invoice",
          link: "/admin/parse/payroll",
          routeCondition: "/admin/**",
          activeCondition: "/admin/parse/payroll",
          permission: "stuff:admin",
        });

        $parse.ui.setClassConfig({
          className: "OD3_TimeTrackingBlocked",
          customFilter:(query: Parse.Query) => {
                                query.descending("Date");
                              },
          disableACLEditing: true,
          titleFields: ["Reason"],
          displayFields: ["Date", "Reason"],
          createFields: ["Date", "Reason"],
          editFields: ["Date", "Reason"],
          defaultACL: () => {
            const acl: { [key: string]: { read: boolean, write: boolean } } = {};
            const tenantId = $parse.user.current()?.get("tenant")?.id;
            if (tenantId) {
              acl["role:od-tenant-user-" + tenantId] = {
                read: true,
                write: false,
              };
              acl["role:od-tenant-admin-" + tenantId] = {
                read: true,
                write: true,
              };
            }
            return acl;
          },
        });


        $parse.ui.setClassConfig({
          className: "OD3_LohnJournale",
          customFilter:(query: Parse.Query) => {
            query.descending("createdAt");
          },
          disableACLEditing: true,
          titleFields: ["Document"],
          displayFields: ["Document"],
          createFields: ["Document"],
          editFields: ["Document"],
          defaultACL: () => {
            const acl: { [key: string]: { read: boolean, write: boolean } } = {};
            const tenantId = $parse.user.current()?.get("tenant")?.id;
            if (tenantId) {
              acl["role:od-tenant-admin-" + tenantId] = {
                read: true,
                write: true,
              };
            }
            return acl;
          },
        });

        $parse.ui.setClassConfig({
          className: "OD3_LohnBuchungen",
          customFilter:(query: Parse.Query) => {
            query.descending("Datum");
          },
          disableACLEditing: true,
          titleFields: ["Datum", "Mitarbeiter"],
          displayFields: ["Datum", "Personalnummer", "Mitarbeiter", "SteuerBrutto", "KVBeitragAG", "RVBeitragAG", "AVBeitragAG", "PVBeitragAG", "Umlage1", "Umlage2", "UmlageInsolv", "Auszahlungsbetrag"],
          createFields: ["Datum", "Personalnummer", "Mitarbeiter", "SteuerBrutto", "KVBeitragAG", "RVBeitragAG", "AVBeitragAG", "PVBeitragAG", "Umlage1", "Umlage2", "UmlageInsolv", "Auszahlungsbetrag"],
          editFields: ["Datum", "Personalnummer", "Mitarbeiter", "SteuerBrutto", "KVBeitragAG", "RVBeitragAG", "AVBeitragAG", "PVBeitragAG", "Umlage1", "Umlage2", "UmlageInsolv", "Auszahlungsbetrag"],
          defaultACL: () => {
            const acl: { [key: string]: { read: boolean, write: boolean } } = {};
            const tenantId = $parse.user.current()?.get("tenant")?.id;
            if (tenantId) {
              acl["role:od-tenant-admin-" + tenantId] = {
                read: true,
                write: true,
              };
            }
            return acl;
          },
        });



        $parse.ui.setClassConfig({
          className: "OD3_Contract",
          customFilter:(query: Parse.Query) => {
                                query.descending("Start");
                              },
          disableACLEditing: true,
          titleFields: ["User.name"],
          displayFields: ["Start", "End", "HoursADay", "VacationDays", "User", "Status"],
          createFields: ["Start", "End", "HoursADay", "VacationDays", "Overtime", "User", "Status"],
          editFields: ["Start", "End", "HoursADay", "VacationDays", "Overtime", "User", "Status"],
          defaultACL: () => {
            const acl: { [key: string]: { read: boolean, write: boolean } } = {};
            const tenantId = $parse.user.current()?.get("tenant")?.id;
            if (tenantId) {
              acl["role:od-tenant-admin-" + tenantId] = {
                read: true,
                write: true,
              };
            }
            return acl;
          },
           afterCreate: async (classname: string, objectId: string) => {
              const query = new Parse.Query(classname);
              query.equalTo("objectId", objectId);
              query.include("User");
              const obj = await query.get(objectId);
              const user = obj.get("User");
              const acl = obj.getACL() || new Parse.ACL();
              acl.setReadAccess(user, true);  
              acl.setWriteAccess(user, false);
              obj.setACL(acl);
              await obj.save();
          }
        });

        // Translations:
        factory.registerTranslationResolver(
          "en",
          "timetracking",
          async () => await import("./translations/en.json")
        );
    
        factory.registerTranslationResolver(
          "de",
          "timetracking",
          async () => await import("./translations/de.json")
        );
      }
    }