import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { ColumnProps } from "antd/lib/table";

export const ContractComponent = createComponent(function ContractComponent() {
  return (
    <AdminLayout>
      <ParseSetView
        className="OD3_Contract"
        view={
          {
            type: "table",
            customCols: [
              {
                title: "Vertragsbeginn",
                key: "Datums",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    <div>{dayjs(row?.get("Start")).format("DD.MM.YYYY")}</div>
                  );
                },
              } as ColumnProps<any>,
              {
                title: "Vertragsende",
                key: "Datums2",
                dataIndex: 0,
                render: (v, row) => {
                  return (
                    <div>{dayjs(row?.get("End")).format("DD.MM.YYYY")}</div>
                  );
                },
              } as ColumnProps<any>,
            ],
            fields: [
              "User",
              "HoursADay",
              "VacationDays",
              "ProjectPercent",
              "Status",
              "Salary",
            ],
          } as TableViewType
        }
      />
    </AdminLayout>
  );
});
