import React from "react";
import dayjs from "dayjs";
import { DataTypeInvest } from "../Types/DataTypeInvest";
import { Table } from "antd";
import type { TableProps } from "antd";

interface InvestProps {
  dataSource: DataTypeInvest[];
  invest: boolean;
}

export const ProjectInvest: React.FC<InvestProps> = ({
  dataSource,
  invest,
}) => {
  let columns: TableProps<DataTypeInvest>["columns"] = [
    {
      title: "Bezeichnung",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Beschreibung",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Nettokosten",
      align: "center",
      dataIndex: "netto",
      key: "netto",
    },
    {
      title: "Rechnungsdatum",
      align: "center",
      dataIndex: "invoicedate",
      key: "invoicedate",
      render: (text) => <div>{dayjs(text).format("DD.MM.YYYY")}</div>,
    },
  ];

  if (invest) {
    columns.push(
      {
        title: "Laufzeit",
        align: "center",
        dataIndex: "duration",
        key: "duration",
        render: (text) => <div>{text} Monate</div>,
      },

      {
        title: "Abschreibungskosten",
        align: "center",
        dataIndex: "monthcosts",
        key: "monthcosts",
        render: (text) => <div>{text} €/Monat</div>,
      },
      {
        title: "Rechnung",
        align: "center",
        dataIndex: "invoice",
        key: "invoice",
        render: (text) => (
          <div>
            <a href={text} target="_blank" rel="noopener noreferrer">
              Download
            </a>
          </div>
        ),
      }
    );
  } else {
    columns.push({
      title: "Rechnung",
      align: "center",
      dataIndex: "invoice",
      key: "invoice",
      render: (text) => (
        <div>
          <a href={text} target="_blank" rel="noopener noreferrer">
            Download
          </a>
        </div>
      ),
    });
  }

  const [tableColumns, setTableColumns] =
    React.useState<TableProps<DataTypeInvest>["columns"]>(columns);

  React.useEffect(() => {
    setTableColumns(columns);
  }, []);

  return (
    <Table
      size="small"
      columns={tableColumns}
      dataSource={dataSource}
      pagination={false}
    />
  );
};
