import React from "react";
import Parse from "parse";
import dayjs from "dayjs";
import { Select } from "antd";
import { CustomParseFieldProps } from "@opendash/plugin-parse";

export const SelectProject = (
  props: CustomParseFieldProps & {
    nameField?: string;
    descriptionField?: string;
  }
) => {
  const { value, setValue, fieldname, parseObject } = props;
  const [projects, setProjects] = React.useState<Parse.Object[]>([]);

  React.useEffect(() => {
    initProjects();
  }, []);

  const initProjects = async () => {
    const query = new Parse.Query("OD3_Projects").limit(99999999);
    let results = await query.find();
    setProjects(results);
  };

  return (
    <Select
      value={value?.id}
      onChange={(e) => {
        const target = projects.find((obj) => obj.id === e);
        setValue(target);
      }}
    >
      {projects.map((project) => (
        <Select.Option key={project.id} value={project.id}>
          {project.get("Name")}
        </Select.Option>
      ))}
    </Select>
  );
};
