import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { Table, Tag, Button, Popconfirm } from "antd";
import { Icon } from "@opendash/icons";
import type { TableColumnsType } from "antd";

interface VacationTableProps {
  data: object[];
  deleteForm: any;
}

export const VacationTable: React.FC<VacationTableProps> = ({
  data,
  deleteForm,
}) => {
  const [tableData, setTableData] = React.useState<any>([]);

  React.useEffect(() => {
    formatTableData();
  }, [data]);

  const formatTableData = () => {
    let tableData: any[] = [];
    data.forEach((element: any) => {
      let data = {
        key: element.id,
        name: element.get("Type"),
        start: element.get("Start"),
        end: element.get("End"),
        days: element.get("Days"),
        status: element.get("Status"),
      };
      tableData.push(data);
    });
    setTableData(tableData);
  };

  interface DataType {
    key: React.Key;
    name: string;
    start: Date;
    end: Date;
    days: number;
    status: number;
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: "Typ",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Start",
      dataIndex: "start",
      align: "center",
      render: (_: any, record: any) => {
        return <>{dayjs(record.start).format("DD.MM.YYYY")}</>;
      },
    },
    {
      title: "Ende",
      dataIndex: "end",
      align: "center",
      render: (_: any, record: any) => {
        return <>{dayjs(record.end).format("DD.MM.YYYY")}</>;
      },
    },
    {
      title: "Tage",
      dataIndex: "days",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (_: any, record: any) => {
        return (
          <Tag
            color={
              record.status === 0
                ? "#2db7f5"
                : record.status === 1
                ? "#87d068"
                : "#f50"
            }
          >
            {record.status === 0
              ? "Beantragt"
              : record.status === 1
              ? "Genehmigt"
              : "Abgelehnt"}
          </Tag>
        );
      },
    },
    {
      title: "",
      dataIndex: "delete",
      align: "center",
      render: (_: any, record: any) => {
        return (
          <Popconfirm
            title="Antrag löschen?"
            disabled={record.status != 0}
            onConfirm={(e) => {
              deleteForm(record);
            }}
            onCancel={(e) => {}}
            okText="Löschen"
            cancelText="Abbrechen"
          >
            <Button
              icon={<Icon icon="fa:trash" key="delete" />}
              danger
              disabled={record.status != 0}
            />
          </Popconfirm>
        );
      },
    },
  ];

  return <Table columns={columns} dataSource={tableData} size="small" />;
};
