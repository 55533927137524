import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { Drawer, Statistic, Row, Col } from "antd";
import { ParseSetView } from "@opendash/plugin-parse";
import { ColumnProps } from "antd/lib/table";

import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { Icon } from "@opendash/icons";

interface InvoiceDrawerProps {
  drawer: boolean;
  setDrawerVisibility: any;
}

export const InvoiceDrawer: React.FC<InvoiceDrawerProps> = ({
  drawer,
  setDrawerVisibility,
}) => {
  const [previewEuro, setPreviewEuro] = React.useState(0);
  const [openEuro, setOpenEuro] = React.useState(0);
  const [paidEuro, setPaidEuro] = React.useState(0);

  const onClose = () => {
    setDrawerVisibility(false);
  };

  //Hide Edit geht noch nicht
  //Sortieren nach Datum
  //Filter sevDeskInvoice == True
  //Kunde noch rein!

  React.useEffect(() => {
    try {
      const query = new Parse.Query("OD3_Customer_Invoice").limit(99999999);
      query.find().then((results) => {
        let preview = 0;
        let open = 0;
        let paid = 0;
        results.forEach((result) => {
          console.log(result);
          if (result.get("Status") === "100") {
            preview = preview + parseFloat(result.get("sumNet"));
          } else if (result.get("Status") === "200") {
            open = open + parseFloat(result.get("sumNet"));
          } else if (result.get("Status") === "1000") {
            paid = paid + parseFloat(result.get("sumNet"));
          }
        });
        setPreviewEuro(parseFloat(preview.toFixed(2)));
        setOpenEuro(parseFloat(open.toFixed(2)));
        setPaidEuro(parseFloat(paid.toFixed(2)));
      });
    } catch (error) {
      console.error("Fehler beim Abrufen der Parse-Customer-Daten:", error);
    }
  }, [drawer]);

  return (
    <Drawer
      title="Rechnungen seit 01.01.2025"
      placement="bottom"
      size="large"
      closable={false}
      onClose={onClose}
      open={drawer}
      key="bottom"
    >
      <>
        <Row style={{ textAlign: "center" }}>
          <Col md={24} lg={8}>
            <Statistic
              decimalSeparator=","
              groupSeparator="."
              title="Entwurf"
              value={previewEuro}
              suffix="€"
            />
          </Col>
          <Col md={24} lg={8}>
            <Statistic
              decimalSeparator=","
              groupSeparator="."
              title="Offen"
              value={openEuro}
              suffix="€"
            />
          </Col>
          <Col md={24} lg={8}>
            <Statistic
              decimalSeparator=","
              groupSeparator="."
              title="Bezahlt"
              value={paidEuro}
              suffix="€"
            />
          </Col>
        </Row>
        <ParseSetView
          className="OD3_Customer_Invoice"
          hideCreate={true}
          hideEdit={true}
          hideActionBar={true}
          view={
            {
              type: "table",
              customCols: [
                {
                  title: "Status",
                  key: "Status",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div
                        style={{
                          color:
                            row?.get("Status") === "100"
                              ? "#888"
                              : row?.get("Status") === "200"
                              ? "#4385c2"
                              : row?.get("Status") === "1000"
                              ? "#7fbb47"
                              : "#000",
                        }}
                      >
                        {row?.get("Status") === "100"
                          ? "Entwurf"
                          : row?.get("Status") === "200"
                          ? "Offen"
                          : row?.get("Status") === "1000"
                          ? "Bezahlt"
                          : "Unbekannt"}
                      </div>
                    );
                  },
                } as ColumnProps<any>,
                {
                  title: "Kunde",
                  key: "Customer",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>
                        {row?.get("Customer").get("Name").length > 45
                          ? `${row
                              ?.get("Customer")
                              .get("Name")
                              .substring(0, 45)}...`
                          : row?.get("Customer").get("Name")}
                      </div>
                    );
                  },
                } as ColumnProps<any>,
                {
                  title: "Rechnungsdatum",
                  key: "invoiceDate",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>
                        {dayjs(row?.get("invoiceDate")).format("DD.MM.YYYY")}
                      </div>
                    );
                  },
                } as ColumnProps<any>,
                {
                  title: "Rechnungsnummer",
                  key: "invoiceNumber",
                  dataIndex: 0,
                  render: (v, row) => {
                    return <div>{row?.get("invoiceNumber")}</div>;
                  },
                } as ColumnProps<any>,

                {
                  title: "Nettobetrag",
                  key: "sumNet",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>
                        {parseFloat(row?.get("sumNet"))
                          ? parseFloat(row?.get("sumNet"))
                              .toFixed(2)
                              .replace(".", ",")
                          : "0,00"}{" "}
                        €
                      </div>
                    );
                  },
                } as ColumnProps<any>,

                {
                  title: "Rechnungsdatei",
                  key: "Invoice",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <>
                        {row.get("PDF") && (
                          <div style={{ cursor: "pointer" }}>
                            <Icon
                              style={{ fontSize: "20px" }}
                              icon="fa:file-pdf"
                              onClick={() => {
                                window.open(row.get("PDF")._url, "_blank");
                              }}
                            ></Icon>
                          </div>
                        )}
                      </>
                    );
                  },
                } as ColumnProps<any>,
              ],
            } as TableViewType
          }
        />
      </>
    </Drawer>
  );
};
