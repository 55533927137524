import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { Calendar, Badge, Tag, message } from "antd";
import type { BadgeProps, CalendarProps } from "antd";
import type { Dayjs } from "dayjs";

interface CalendarViewProps {
  contract: boolean;
  isMobile: boolean;
  value: any;
  headerRender: any;
  onSelect: any;
  onPanelChange: any;
  selectedMonth: any;
  selectedYear: any;
  getMonthData: any;
  getListData: any;
  freeDays: any;
  setModalVisible: any;
}

export const CalendarView: React.FC<CalendarViewProps> = ({
  contract,
  isMobile,
  value,
  headerRender,
  onSelect,
  onPanelChange,
  selectedMonth,
  selectedYear,
  getMonthData,
  getListData,
  freeDays,
  setModalVisible,
}) => {
  const [vacData, setVacData] = React.useState<any>([]);

  React.useEffect(() => {
    getVacDataOfUser();
  }, []);

  const getVacDataOfUser = () => {
    const query = new Parse.Query("OD3_TimeVacation").limit(99999999);
    query.equalTo("User", Parse.User.current());
    query.equalTo("Status", 0);
    query.find().then((results) => {
      setVacData(results);
    });
  };

  const monthCellRender = (value: Dayjs): React.ReactNode => {
    const currentMonth: number = dayjs().month();
    const num: number | undefined = getMonthData(value);

    if (value.month() === currentMonth) {
      return num ? (
        <div className="notes-month">
          <section>{num}</section>
          <span>Backlog number</span>
        </div>
      ) : null;
    } else {
      return null;
    }
  };

  const dateCellRender = (value: Dayjs): React.ReactNode => {
    const listData: { type: string; content: string; items: any[] }[] =
      getListData(value);

    return (
      <div style={{}}>
        {listData.map((item) => {
          if (item.items.length > 0) {
            return item.items.map((subItem, index) => (
              <Tag
                style={{ fontSize: "11px", margin: "1px" }}
                color={subItem.type === "project" ? "#7fbb47" : "#4385c2"}
                key={subItem.content}
              >
                {subItem.content}
              </Tag>
            ));
          } else {
            // Andernfalls ein einzelnes <Tag> erstellen
            return (
              <Tag
                style={{ fontSize: "11px", margin: "1px" }}
                color={
                  item.type === "error"
                    ? "red"
                    : item.type === "warning"
                    ? "orange"
                    : "lightgray"
                }
                key={item.content}
              >
                {item.content}
              </Tag>
            );
          }
        })}
      </div>
    );
  };

  const fullCellRender = (value: Dayjs): React.ReactNode => {
    const currentMonth: number = selectedMonth;
    const currentYear: number = selectedYear;

    const isWeekend: boolean = value.day() === 0 || value.day() === 6;
    let foundObject = freeDays.find(
      (obj: any) =>
        dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
        value.format("DD.MM.YYYY")
    );

    const isBlockedDay: boolean = foundObject;

    const isInApplication = vacData.filter((obj: any) => {
      return (
        dayjs(value)
          .startOf("day")
          .add(12, "hour")
          .isAfter(dayjs(obj.get("Start")).startOf("day")) &&
        dayjs(value)
          .startOf("day")
          .add(12, "hour")
          .isBefore(dayjs(obj.get("End")).endOf("day"))
      );
    });

    if (value.month() === currentMonth && value.year() === currentYear) {
      return (
        <div
          className="ant-picker-cell-inner ant-picker-calendar-date"
          style={{
            backgroundColor:
              dateCellRenderMobile(value) == 1
                ? ""
                : dateCellRenderMobile(value) == 2
                ? "#fafafa"
                : "",
            margin: "1px",
          }}
          onClick={(e) => {
            if (!isWeekend && !isBlockedDay && isInApplication.length === 0) {
              if (contract) {
                setModalVisible(true);
              } else {
                message.error(
                  "Es ist nicht möglich ohne einen Vertrag Stunden einzutragen!"
                );
              }
            } else if (isWeekend) {
              message.error(
                "Es ist nicht möglich Arbeitszeiten an einem Wochenende einzutragen!"
              );
            } else if (isInApplication.length > 0) {
              message.error(
                "Sie haben für diesen Zeitraum Urlaub beantragt, daher kann nicht gearbeitet werden!"
              );
            } else {
              message.error(
                "Es ist nicht möglich Arbeitszeiten an " +
                  foundObject.get("Reason") +
                  " einzutragen!"
              );
            }
          }}
        >
          <div
            className="ant-picker-calendar-date-value"
            style={{
              color:
                dateCellRenderMobile(value) == 1
                  ? ""
                  : dateCellRenderMobile(value) == 2
                  ? "red"
                  : "",
            }}
          >
            {value.date()}
          </div>
          <div
            className="ant-picker-calendar-date-content"
            style={{ height: "120px" }}
          >
            <div className="events">{dateCellRender(value)}</div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const dateCellRenderMobile = (value: Dayjs): React.ReactNode => {
    const listData: { type: string; content: string }[] = getListData(value);
    const isWeekend: boolean = value.day() === 0 || value.day() === 6;
    let foundObject = freeDays.find(
      (obj: {
        get: (
          arg0: string
        ) => string | number | Dayjs | Date | null | undefined;
      }) =>
        dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
        value.format("DD.MM.YYYY")
    );

    const isBlockedDay: boolean = foundObject;

    if (listData.length > 0) {
      return 1;
    } else if (isWeekend || isBlockedDay) {
      return 2;
    } else {
      return 3;
    }
  };

  const fullCellRenderMobile = (value: Dayjs): React.ReactNode => {
    const currentMonth: number = selectedMonth;
    const currentYear: number = selectedYear;

    const isWeekend: boolean = value.day() === 0 || value.day() === 6;
    let foundObject = freeDays.find(
      (obj: {
        get: (
          arg0: string
        ) => string | number | Dayjs | Date | null | undefined;
      }) =>
        dayjs(obj.get("Date")).format("DD.MM.YYYY") ===
        value.format("DD.MM.YYYY")
    );

    const isBlockedDay: boolean = foundObject;

    const isInApplication = vacData.filter((obj: any) => {
      return (
        dayjs(value)
          .startOf("day")
          .add(12, "hour")
          .isAfter(dayjs(obj.get("Start")).startOf("day")) &&
        dayjs(value)
          .startOf("day")
          .add(12, "hour")
          .isBefore(dayjs(obj.get("End")).endOf("day"))
      );
    });

    if (value.month() === currentMonth && value.year() === currentYear) {
      return (
        <div
          className="ant-picker-cell-inner ant-picker-calendar-date"
          onClick={(e) => {
            if (!isWeekend && !isBlockedDay && isInApplication.length === 0) {
              setModalVisible(true);
            } else if (isWeekend) {
              message.error(
                "Es ist nicht möglich Arbeitszeiten an einem Wochenende einzutragen!"
              );
            } else if (isInApplication.length > 0) {
              message.error(
                "Sie haben für diesen Zeitraum Urlaub beantragt, daher kann nicht gearbeitet werden!"
              );
            } else {
              message.error(
                "Es ist nicht möglich Arbeitszeiten an " +
                  foundObject.get("Reason") +
                  " einzutragen!"
              );
            }
          }}
        >
          <div
            className="ant-picker-calendar-date-value"
            style={{
              color:
                dateCellRenderMobile(value) == 1
                  ? "#7fbb47"
                  : dateCellRenderMobile(value) == 2
                  ? "red"
                  : "",
            }}
          >
            {value.date()}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const cellRender: CalendarProps<Dayjs>["cellRender"] = (
    current,
    info
  ): React.ReactNode => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  if (isMobile) {
    return (
      <Calendar
        fullscreen={false}
        value={value}
        headerRender={headerRender}
        fullCellRender={fullCellRenderMobile}
        onSelect={onSelect}
        onPanelChange={onPanelChange}
        validRange={[
          dayjs().month(selectedMonth).year(selectedYear).startOf("month"),
          dayjs(selectedMonth)
            .month(selectedMonth)
            .year(selectedYear)
            .endOf("month"),
        ]}
      />
    );
  } else {
    return (
      <Calendar
        headerRender={headerRender}
        cellRender={cellRender}
        value={value}
        onSelect={onSelect}
        onPanelChange={onPanelChange}
        fullCellRender={fullCellRender}
        validRange={[
          dayjs().month(selectedMonth).year(selectedYear).startOf("month"),
          dayjs(selectedMonth)
            .month(selectedMonth)
            .year(selectedYear)
            .endOf("month"),
        ]}
      />
    );
  }
};
