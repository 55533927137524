import "antd/dist/reset.css";

import "./parse.config";
import "./highcharts.config";

import Parse from "parse";
import { init, StorageAdapterLS } from "@opendash/core";
import { registerIconPack } from "@opendash/icons";
import { $parse, ParsePlugin } from "@opendash/plugin-parse";
import { TimeTrackingPlugin } from "./components/timetrack/src";
import { ResearchProjectPlugin } from "./components/researchprojects/src";
import { CustomerPlugin } from "./components/customer/src";
init("opendash", async (factory) => {
  // Icons
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // Translations:

  factory.registerLanguage("en", "English");
  factory.registerLanguage("de", "Deutsch", "en", true);
  // ant design translations

  factory.registerAntDesignTranslation(
    "en",
    () => import("antd/lib/locale/en_US")
  );

  factory.registerAntDesignTranslation(
    "de",
    () => import("antd/lib/locale/de_DE")
  );

  // widget translations
  factory.registerTranslationResolver(
    "en",
    "app",
    async () => await import("./translations/app/en.json")
  );

  //Translation
  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    async () => await import("./translations/de.json")
  );

  factory.registerTranslationResolver(
    "en",
    "parse-custom",
    async () => await import("./translations/en.json")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());
  await factory.use(
    new ParsePlugin({
      authLdapActive: false,
      disableNavigationItems: true,
    })
  );
  await factory.use(new TimeTrackingPlugin({researchprojectPlugin: true, customerPlugin: true}));
  await factory.use(new ResearchProjectPlugin({}));
  await factory.use(new CustomerPlugin({}));

  factory.registerStaticNavigationGroup({
    label: "parse-admin:admin.label",
    order: 10,
    id: "admin/parse",
  });
  factory.registerStaticNavigationItem({
    id: "admin/parse/item",
    group: "admin/parse",
    place: "frontpage",
    order: 100,
    label: "opendash:admin.label",
    icon: "fa:cogs",
    color: "#676767",
    link: "/admin/parse/_User",
    routeCondition: "**",
    activeCondition: "/",
    permission: "admin:area",
  });

  factory.registerStaticNavigationItem({
    id: "admin/parse/users",
    group: "admin/parse",
    place: "sidebar",
    order: 20,
    label: "parse-custom:classes._User.label_plural",
    icon: "fa:users",
    link: "/admin/parse/_User",
    routeCondition: "/admin/**",
    activeCondition: "/admin/parse/_User",
    permission: "parse-admin",
  });

  factory.registerStaticNavigationItem({
    id: "admin/parse/roles",
    group: "admin/parse",
    place: "sidebar",
    order: 20,
    label: "parse-custom:classes._Role.label_plural",
    icon: "fa:building",
    link: "/admin/parse/_Role",
    routeCondition: "/admin/**",
    activeCondition: "/admin/parse/_Role",
    permission: "parse-admin",
  });

  factory.registerStaticNavigationItem({
    id: "admin/parse/permission",
    group: "admin/parse",
    place: "sidebar",
    order: 30,
    label: "parse-custom:classes.OD3_Permission.label",
    icon: "fa:lock",
    link: "/admin/parse/OD3_Permission",
    routeCondition: "/admin/**",
    activeCondition: "/admin/parse/OD3_Permission",
    permission: "parse-admin",
  });

  $parse.ui.setClassConfig({
    className: "_User",
    titleFields: ["name"],
    displayFields: ["Personalnummer", "name", "username", "email"],
    createFields: ["Personalnummer", "name", "username", "email", "Initial","password", "tenantAdmin", "VacationWithoutApplication", "ignoreInSelection", "projectreporting"],
    editFields: ["Personalnummer", "name", "username", "email", "Initial","password", "tenantAdmin", "VacationWithoutApplication", "ignoreInSelection", "projectreporting"],
    defaultACL: () => {
      const acl: { [key: string]: { read: boolean, write: boolean } } = {};
      const tenantId = $parse.user.current()?.get("tenant")?.id;
      if (tenantId) {
        acl["role:od-tenant-admin-" + tenantId] = {
          read: true,
          write: true,
        };
      }
      return acl;
    },
    beforeCreate: () => {
      const x = new Parse.Object("_User");
      x.set("tenant", $parse.user.current()?.get("tenant"))
      return x;
    },
  });

  $parse.ui.setClassConfig({
    className: "_Role",
    titleFields: ["label"],
    displayFields: ["label", "name", "users", "createdAt", "updatedAt"],
    createFields: ["label", "name"],
    editFields: ["label", "name", "users"],
    defaultACL: () => {
      if (!$parse.user.id()) {
        return {};
      }
      return {
        // @ts-ignore
        [$parse.user.id()]: {
          read: true,
          write: true,
        },
      };
    },
  });
  $parse.ui.setDefaultView("_Role", {
    type: "table",
  });

  // Widgets
  addcss(`
  .ant-modal-close-x {margin-top:16}
  .ant-steps-item-icon span {line-height:32px!important}
  .ant-steps-item-icon svg {margin-top:7}
  .ant-drawer-body .data-item-boolean {margin-top:12} 
  .ant-drawer-body .data-item-percentage {margin-top:10}
  .leaflet-top {z-index:998!important} 

  `);
}).then((app) => {
  console.log("init open.DASH");
});
//@ts-expect-error
function addcss(css) {
  const head = document.getElementsByTagName("head")[0];
  const s = document.createElement("style");
  s.setAttribute("type", "text/css");
  s.appendChild(document.createTextNode(css));
  head.appendChild(s);
}
