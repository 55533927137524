import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { TableViewType } from "@opendash/plugin-parse/dist/types";

export const ProductAdminComponent = createComponent(
  function ProductAdminComponent() {
    return (
      <AdminLayout>
        <ParseSetView
          className="OD3_Products"
          view={
            {
              type: "table",
              fields: ["Label", "Description", "Color"],
            } as TableViewType
          }
        />
      </AdminLayout>
    );
  }
);
