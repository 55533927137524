import React from "react";
import dayjs from "dayjs";
import Parse from "parse";
import { color, Options } from "highcharts";
import { HighchartsChart } from "@opendash/plugin-highcharts";
import { Modal, DatePicker, Alert, Space, message } from "antd";

interface ChartsProps {
  selectedYear: number;
  selectedMonth: number;
  currentUser: Parse.User;
}

export const Charts: React.FC<ChartsProps> = ({
  selectedYear,
  selectedMonth,
  currentUser,
}) => {
  const [state, setState] = React.useState({
    activeIndex: 0,
  });
  const [vacationDays, setVacationDays] = React.useState<number>(0);
  const [sickDays, setSickDays] = React.useState<number>(0);
  const [workDays, setWorkDays] = React.useState<number>(0);
  const [homeDays, setHomeDays] = React.useState<number>(0);
  const [officeDays, setOfficeDays] = React.useState<number>(0);
  const [projectHours, setProjectHours] = React.useState<number>(0);
  const [customerHours, setCustomerHours] = React.useState<number>(0);
  const [internalHours, setInternalHours] = React.useState<number>(0);
  const [otherHours, setOtherHours] = React.useState<number>(0);
  const [customerTrackingData, setCustomerTrackingData] = React.useState<any>(
    []
  );

  React.useEffect(() => {
    getCustomerTimeTrackingDataForUser();
  }, [selectedYear, selectedMonth, currentUser]);

  const getCustomerTimeTrackingDataForUser = async () => {
    try {
      const query = new Parse.Query("OD3_Customer_TimeTrack").limit(99999999);
      query.include("Customer");
      query.include("TimeTracking");
      const result = await query.find();
      getChartItems(result);
      setCustomerTrackingData(result);
    } catch (error) {
      console.error("Fehler beim Abrufen der TimeTracking-Daten:", error);
    }
  };

  const getChartItems = async (customerData: any[]) => {
    try {
      const query = new Parse.Query("OD3_TimeTracking").limit(99999999);
      query.descending("Date");

      const startDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
        .startOf("month")
        .toDate();
      const endDate = dayjs(`${selectedYear}-${selectedMonth + 1}-01`)
        .endOf("month")
        .toDate();

      query.greaterThanOrEqualTo("Date", startDate);
      query.lessThan("Date", endDate);
      const result = await query.find();
      let foundObject: any = result.filter(
        (obj: any) => obj.get("User").id === currentUser?.id
      );

      let vacationDays = 0;
      let sickDays = 0;
      let workDays = 0;

      let homeDays = 0;
      let officeDays = 0;

      let projectHours = 0;
      let customerHours = 0;
      let internalHours = 0;
      let otherHours = 0;

      if (foundObject && foundObject.length > 0) {
        foundObject.forEach((element: any) => {
          let customerItems = customerData.filter(
            (obj: any) => obj.get("TimeTracking").id === element.id
          );

          if (element.get("Vacation")) {
            console.log(element);
            vacationDays++;
          } else if (element.get("Sick")) {
            sickDays++;
          } else {
            workDays++;
          }

          if (element.get("HomeOffice")) {
            homeDays++;
          } else {
            officeDays++;
          }

          otherHours += element.get("WorkingHours");
          const customer = customerItems;
          const research = element.get("Research");

          customer.forEach((customerElement: any) => {
            if (
              customerElement.get("Customer").get("Name") === "open.INC GmbH"
            ) {
              internalHours += parseFloat(customerElement.get("Worktime"));
            } else {
              customerHours += parseFloat(customerElement.get("Worktime"));
            }
          });

          research.forEach((researchElement: any) => {
            projectHours += parseFloat(researchElement.worktime);
          });
        });
      }
      setVacationDays(vacationDays);
      setSickDays(sickDays);
      setWorkDays(workDays);
      setHomeDays(homeDays);
      setOfficeDays(officeDays);
      setProjectHours(projectHours / 60);
      setCustomerHours(customerHours / 60);
      setInternalHours(internalHours / 60);
      setOtherHours(
        otherHours - projectHours / 60 - customerHours / 60 - internalHours / 60
      );
    } catch (error) {
      console.error("Fehler beim Abrufen der TimeTracking-Daten:", error);
    }
  };

  const seriesDataHours = React.useMemo(() => {
    return [
      {
        name: "Forschungsprojekt",
        y: projectHours,
        color: "#7fbb47",
        _unit: "Std.",
      },
      {
        name: "Kundenprojekt",
        y: customerHours,
        color: "#4385c2",
        _unit: "Std.",
      },
      { name: "Intern", y: internalHours, color: "#676767", _unit: "Std." },
      { name: "Sonstige", y: otherHours, color: "#d3d52e", _unit: "Std." },
    ];
  }, [projectHours, customerHours, internalHours, internalHours]);

  const optionsHours = React.useMemo<Options>(() => {
    const result: Options = {
      title: null,
      subtitle: null,

      chart: {
        type: "pie",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },

      tooltip: {
        formatter: function () {
          let returnString = "";
          let nameString = "";
          let valueString = "";
          let totalString = "";
          let percentString = "";
          nameString = this.key + "";
          valueString =
            '<p style="font-size: 13px">' +
            "Wert: " +
            ": <b>" +
            this.y.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          totalString =
            '<p style="font-size: 13px">' +
            "Total: " +
            ": <b>" +
            this.total.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          percentString =
            '<p style="font-size: 13px">' +
            "Anteil: " +
            ": <b>" +
            ((this.y / this.total) * 100).toFixed(2) +
            " %</b></p>";

          returnString =
            '<p style="font-size: 13px"><b>' + nameString + "</b></p><br/>";
          returnString += valueString;
          returnString += totalString;
          returnString += percentString;
          return returnString;
        },
        shadow: false,
        borderWidth: 1,
        outside: true,
        borderColor: "rgba(100,100,100,0.3)",
        backgroundColor: "rgba(255,255,255,0.95)",
      },

      plotOptions: {
        pie: {
          size: "60%",
          dataLabels: {
            enabled: true,
            distance: 18,
            crop: true,
            padding: 0,
            overflow: "justify",
            formatter: function () {
              return (
                '<p style="font-size: 12px;z-index:-1"> ' +
                this.point.name.split("(")[0] +
                ": " +
                this.y?.toFixed(1) +
                " " +
                //@ts-ignore
                this.point._unit +
                "</p>"
              );
            },
          },

          showInLegend: true,

          startAngle: -90,
          endAngle: 360 - 90,
          center: ["50%", "50%"],
        },
      },

      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        // @ts-ignore
        {
          data: seriesDataHours,
          innerSize: "80%",
          turboThreshold: 0,
        },
      ],
    };

    return result;
  }, [seriesDataHours]);

  const seriesDataOffice = React.useMemo(() => {
    return [
      { name: "Home Office", y: homeDays, color: "#7fbb47", _unit: "Tage" },
      {
        name: "Office Day",
        y: officeDays,
        color: "#4385c2",
        _unit: "Tage",
      },
    ];
  }, [officeDays, homeDays]);

  const optionsOffice = React.useMemo<Options>(() => {
    const result: Options = {
      title: null,
      subtitle: null,

      chart: {
        type: "pie",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },

      tooltip: {
        formatter: function () {
          let returnString = "";
          let nameString = "";
          let valueString = "";
          let totalString = "";
          let percentString = "";
          nameString = this.key + "";
          valueString =
            '<p style="font-size: 13px">' +
            "Wert: " +
            ": <b>" +
            this.y.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          totalString =
            '<p style="font-size: 13px">' +
            "Total: " +
            ": <b>" +
            this.total.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          percentString =
            '<p style="font-size: 13px">' +
            "Anteil: " +
            ": <b>" +
            ((this.y / this.total) * 100).toFixed(2) +
            " %</b></p>";

          returnString =
            '<p style="font-size: 13px"><b>' + nameString + "</b></p><br/>";
          returnString += valueString;
          returnString += totalString;
          returnString += percentString;
          return returnString;
        },
        shadow: false,
        borderWidth: 1,
        outside: true,
        borderColor: "rgba(100,100,100,0.3)",
        backgroundColor: "rgba(255,255,255,0.95)",
      },

      plotOptions: {
        pie: {
          size: "60%",
          dataLabels: {
            enabled: true,
            distance: 18,
            crop: true,
            padding: 0,
            overflow: "justify",
            formatter: function () {
              return (
                '<p style="font-size: 12px;z-index:-1"> ' +
                this.point.name.split("(")[0] +
                ": " +
                this.y +
                " " +
                //@ts-ignore
                this.point._unit +
                "</p>"
              );
            },
          },

          showInLegend: true,

          startAngle: -90,
          endAngle: 360 - 90,
          center: ["50%", "50%"],
        },
      },

      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        // @ts-ignore
        {
          data: seriesDataOffice,
          innerSize: "80%",
          turboThreshold: 0,
        },
      ],
    };

    return result;
  }, [seriesDataOffice]);

  const seriesDataDays = React.useMemo(() => {
    return [
      { name: "Krankheitstage", y: sickDays, color: "#c93838", _unit: "Tage" },
      {
        name: "Urlaubstage",
        y: vacationDays,
        color: "#d3d52e",
        _unit: "Tage",
      },
      { name: "Arbeitstage", y: workDays, color: "#4385c2", _unit: "Tage" },
    ];
  }, [sickDays, vacationDays, workDays]);

  const optionsDays = React.useMemo<Options>(() => {
    const result: Options = {
      title: null,
      subtitle: null,

      chart: {
        type: "pie",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },

      tooltip: {
        formatter: function () {
          let returnString = "";
          let nameString = "";
          let valueString = "";
          let totalString = "";
          let percentString = "";
          nameString = this.key + "";
          valueString =
            '<p style="font-size: 13px">' +
            "Wert: " +
            ": <b>" +
            this.y.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          totalString =
            '<p style="font-size: 13px">' +
            "Total: " +
            ": <b>" +
            this.total.toFixed(2) +
            " " +
            //@ts-ignore
            this.point._unit +
            "</b></p><br/>";
          percentString =
            '<p style="font-size: 13px">' +
            "Anteil: " +
            ": <b>" +
            ((this.y / this.total) * 100).toFixed(2) +
            " %</b></p>";

          returnString =
            '<p style="font-size: 13px"><b>' + nameString + "</b></p><br/>";
          returnString += valueString;
          returnString += totalString;
          returnString += percentString;
          return returnString;
        },
        shadow: false,
        borderWidth: 1,
        outside: true,
        borderColor: "rgba(100,100,100,0.3)",
        backgroundColor: "rgba(255,255,255,0.95)",
      },

      plotOptions: {
        pie: {
          size: "60%",
          dataLabels: {
            enabled: true,
            distance: 18,
            crop: true,
            padding: 0,
            overflow: "justify",
            formatter: function () {
              return (
                '<p style="font-size: 12px;z-index:-1"> ' +
                this.point.name.split("(")[0] +
                ": " +
                this.y +
                " " +
                //@ts-ignore
                this.point._unit +
                "</p>"
              );
            },
          },

          showInLegend: true,

          startAngle: -90,
          endAngle: 360 - 90,
          center: ["50%", "50%"],
        },
      },

      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        // @ts-ignore
        {
          data: seriesDataDays,
          innerSize: "80%",
          turboThreshold: 0,
        },
      ],
    };

    return result;
  }, [seriesDataDays]);

  return (
    <div style={{ marginTop: "-50px", zIndex: 1 }}>
      <HighchartsChart options={optionsDays} height={250} />
      <HighchartsChart options={optionsOffice} height={250} />
      <HighchartsChart options={optionsHours} height={250} />
    </div>
  );
};
