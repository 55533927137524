import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { ColumnProps } from "antd/lib/table";

export const ProjectContractComponent = createComponent(
  function ProjectContractComponent() {
    return (
      <AdminLayout>
        <ParseSetView
          className="OD3_ProjectContract"
          view={
            {
              customCols: [
                {
                  title: "Start",
                  key: "Start",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>{dayjs(row?.get("Start")).format("DD.MM.YYYY")}</div>
                    );
                  },
                } as ColumnProps<any>,
                {
                  title: "End",
                  key: "End",
                  dataIndex: 0,
                  render: (v, row) => {
                    return (
                      <div>{dayjs(row?.get("End")).format("DD.MM.YYYY")}</div>
                    );
                  },
                } as ColumnProps<any>,
              ],
              type: "table",
              fields: ["Vertrag", "Projekt"],
            } as TableViewType
          }
        />
      </AdminLayout>
    );
  }
);
