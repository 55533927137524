import React from "react";
import dayjs from "dayjs";
import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";
import { TableViewType } from "@opendash/plugin-parse/dist/types";
import { ColumnProps } from "antd/lib/table";

export const CustomerAdminComponent = createComponent(
  function CustomerAdminComponent() {
    return (
      <AdminLayout>
        <ParseSetView
          className="OD3_Customer"
          view={
            {
              type: "table",
            } as TableViewType
          }
        />
      </AdminLayout>
    );
  }
);
